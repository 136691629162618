@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Oxygen:wght@400;700&display=swap');
@import "../helpers/variables.scss";
@import "../helpers/mixins.scss";

body {
  width: 100%;
  height: 100%;
}

html {
  width: 100%;
  height: 100%;
}

* {
  @include typo(small, regular, 1);
  color: $fg__default;
}

#root {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  background: transparent;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track, ::-webkit-scrollbar-corner {
  background: #EDECE9 !important;
}

::-webkit-scrollbar-thumb {
  background: #D3D1CB !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #AEACA6 !important;
}