@import "../../../../scss/main.scss";

.unitBox__units-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $bg__surface;
    @include shadow(60);
    border-radius: 24px;
    padding: 24px;
    gap: 24px;

    .unitBox__units-stats-container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .unitBox__units-stat{
            display: flex;
            align-items: center;
            gap: 10px;
            @include typo(small, regular, 2);
        }
    }
}