@import "../../../../../../scss/main.scss";

.imageGuide__guide-icon-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .imageGuide__cover-image-guide,
    .imageGuide__move-image-guide,
    .imageGuide__trash-can-image-guide {
        display: flex;
        flex-direction: row;
        gap: 8px;

        .imageGuide__icon {
            display: flex;
            
            svg {
                margin-top: 4px;
                height: 14px;
                width: 14px;
            }
        }
        
        .imageGuide__cover-image-text,
        .imageGuide__move-image-text,
        .imageGuide__trash-can-image-text {
            display: flex;
            flex-direction: column;
            gap: 4px;

            h4 {
                @include typo(caption, bold, 1);
                margin: 0px;
                color: $accent__default
            }

            p {
                @include typo(x_small, regular, 2);
                color: fg__muted;
                margin: 0px;
            }
        }
    }
}



// .projectImages__cover-image-text > h4,
// .projectImages__move-image-text > h4,
// .projectImages__trash-can-image-text > h4 {
//     font-size: 14px;
//     color: rgba(61, 89, 236, 0.8);
//     margin: 0px;
// }

// .projectImages__cover-image-text > p,
// .projectImages__move-image-text > p,
// .projectImages__trash-can-image-text > p {
//     margin: 0;
//     font-size: 12px;
//     color: rgba(153, 153, 153, 0.8);
// }