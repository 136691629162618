@import "../../../../scss/main.scss";

.leads__modal-container {
    background: $bg__canvas;
    width: 640px;

    @include bd-radius(large);
    @include shadow(60);
}

.leads__modal-header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px 48px;
    border-bottom: 1px solid $bg__muted;
}

.leads__modal-header > div:nth-child(1) {
    @include typo(body, bold, 1);
}

.leads__modal-header > div:nth-child(2) {
    color: $fg__muted;
    @include typo(caption, regular, 1);
}

.leads__modal-contact-info {
    padding: 24px 48px;
    border-bottom: 1px solid $bg__muted;
}

.leads__modal-body {
    padding: 24px 48px 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.leads__modal-body > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.leads__modal-text {
    @include typo(small, regular, 2);
}

.leads__modal-text.--bold {
    @include typo(small, bold, 1);
}