@import "../../../scss/main.scss";

.filterTable__container {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .filterTable__main-filters {
        display: flex;
        gap: 24px;

        .filterTable__filter-floor-text {
            display: flex;
            width: max-content;
            align-items: center;
            @include typo(button_medium, bold, 1);
        }

        .filterTable__filter-icon-and-text {
            display: flex;
            width: max-content;
            align-items: center;
            gap: 8px;

            div {
                @include typo(button_medium, bold, 1);
            }
        }

        button.--active > svg > path {
            fill: $bg__surface;
        }

        button.--active > .filterTable__filter-icon-and-text > svg > path {
            fill: $bg__surface;
        }

        button.--active > .filterTable__filter-floor-text,
        button.--active > .filterTable__filter-icon-and-text > div {
            color: $bg__surface;
        }

        button > .filterTable__number-of-filters {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            padding: 2px 6px;
            background: $bg__surface;
            border-radius: 100px;
            color: $accent__default;
            @include typo(button_medium, bold, 1);

        }

        .filterTable__filterTags-container {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
        }
    }
}

.filterTable__choose-floor-container {
    max-width: 280px;
    height: fit-content;
    overflow: auto;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: $bg__surface;
    border: 1px solid $accent__subtle;

    @include bd-radius(medium);
    @include shadow(80);

    .filterTable__choose-floor-header {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 16px 24px;

        .filterTable__choose-floor-text {
            text-transform: capitalize;
            @include typo(headline_5, bold, 1);
        }

        .filterTable__choose-floor-info {
            color: $fg__muted;
            @include typo(caption, regular, 2);
        }
    }

    .filterTable__choose-floor {
        background-color: $bg__subtle;
        padding: 12px 8px 12px 24px;
    }

}

.filterTable__secondary-filter-dropdown {
    width: 400px;
    height: fit-content;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 24px;

    .filterTable__secondary-filter-header-container {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .filterTable__secondary-filter-header-text {
            @include typo(lead, bold, 1);
        }

        .filterTable__secondary-filter-header-info {
            color: $fg__muted;
            @include typo(caption, bold, 2);
        }

    }
}

@media only screen and (max-height: 768px) {
    .filterTable__choose-floor-container {
        max-height: 350px;
    }

    .filterTable__secondary-filter-dropdown {
        max-height: 400px;
    }
}

// .filterTable__container {
//     width: fit-content;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
// }

// .filterTable__main-filters { 
//     display: flex;
//     gap: 24px;
// }

// .filterTable__secondary-filter-button { 
//     display: flex;
//     align-items: center;
//     gap: 16px;
//     padding: 0 24px;
//     border: 1px solid #BBBBBB;
//     border-radius: 12px;
//     font-weight: 500;
//     transition: 0.15s;
//     cursor: pointer;
// }

// .filterTable__secondary-filter-button:hover { 
//     background-color: #3D59EC;
//     border: 1px solid white;
// }

// .filterTable__secondary-filter-button:hover > svg > path { 
//     fill: white;
//     stroke: white;
// }

// .filterTable__secondary-filter-button:hover > div { 
//     color: white;
// }

// .filterTable__secondary-filter-button.--active { 
//     background-color: #3D59EC;
//     border: 1px solid white;
// }
// .filterTable__secondary-filter-button.--active > svg > path { 
//     fill: white;
//     stroke: white;
// }

// .filterTable__secondary-filter-button.--active > div { 
//     color: white;
// }

// .filterTable__units-available {
//     font-weight: 600;
//     font-size: 24px;
//     align-self: center;
// }

// .filterTable__units-available > span {
//     color: #3D59EC;
// }

// .filterTable__view-mode-container { 
//     display: flex;
//     gap: 36px
// }

// .filterTable__building-view,
// .filterTable__plan-view {
//     display: flex;
//     align-items: center;
//     gap: 12px;
//     background-color: #F4F4F4;
//     border-radius: 4px;
//     padding: 0 16px;
// }

// .filterTable__min-max-price-container,
// .filterTable__min-max-sqft-container { 
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: fit-content;
//     width: fit-content;
//     gap: 16px;
//     background:#F4F4F4;
//     padding: 4px 8px;
//     border-radius: 4px;
// }

// .filterTable__main-filters .form__input-group, 
// .filterTable__secondary-filter-dropdown .form__input-group {
//     margin-bottom: 0px;
// }

// .filterTable__main-filters > .form__input-group > label {
//     display: none;
// }

// .filterTable__min-max-price-container > .form__input-group > .form__input-number-container, 
// .filterTable__min-max-sqft-container > .form__input-group > .form__input-number-container { 
//     border: none;
// }

// .filterTable__container > .form__input-group > label,
// .filterTable__secondary-filter-container > .form__input-group > label,
// .filterTable__min-max-price-container > .form__input-group > label,
// .filterTable__min-max-sqft-container > .form__input-group > label { 
//     display: none;
// }

// .filterTable__min-max-price-container > .form__input-group > .form__input-number-container > input,
// .filterTable__min-max-price-container > .form__input-group > .form__input-number-container > .numberInput__suffix,
// .filterTable__min-max-sqft-container > .form__input-group > .form__input-number-container  > input { 
//     background:#F4F4F4;
//     width: 96px;
//     border: none;
//     font-size: 16px;
// }

// .filterTable__main-filters > .form__input-group select,
// .filterTable__secondary-filter-container > .form__input-group select {
//     font-size: 16px;
// }

// .filterTable__min-max-sqft-container > .form__input-group > .form__input-number-container  > input { 
//     text-align: left;
//     padding-left: 8px;
// }

// .filterTable__secondary-filter-dropdown { 
//     display: flex;
//     flex-direction: column;
//     position: relative;
// }

// .filterTable__secondart-filter-close { 
//     position: absolute;
//     top: 0;
//     right: 0;
//     cursor: pointer;
// }

// .filterTable__secondary-filter-title { 
//     font-weight: 500;
//     padding-left: 16px;

// }

// .filterTable__secondary-filter-divider { 
//     border-bottom: 1px solid black;
//     margin-bottom: 32px;
// }

// .filterTable__secondary-filter-container {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     height: 400px;
//     width: fit-content;
//     overflow: auto;
//     overflow-x: hidden;
//     grid-gap: 24px;
//     margin-bottom: 32px;
//     padding: 32px 16px;
// }

// .filterTable__clear-filter-container { 
//     display: flex;
//     justify-content: flex-end;
// }

// .filterTable__clear-filter-container > button { 
//     height: fit-content;
// }

// @media only screen and (max-width: 1482px) {

//     .filterTable__secondary-filter-container {
//         height: 250px;
//     }

//     .filterTable__main-filters > .form__input-group select,
//     .filterTable__secondary-filter-container > .form__input-group select {
//         font-size: 14px;
//     } 
    
    
//     .filterTable__min-max-price-container > .form__input-group > .form__input-number-container > input,
//     .filterTable__min-max-price-container > .form__input-group > .form__input-number-container > .numberInput__suffix,
//     .filterTable__min-max-sqft-container > .form__input-group > .form__input-number-container  > input {
//         font-size: 14px;
//     }

//     .filterTable__secondary-filter-button {
//         font-size: 14px;
//     }

//     .filterTable__secondary-filter-button > svg {
//         width: 18px;
//         height: 18px;
//     }
    
//     .filterTable__secondary-filter-container {
//         grid-template-columns: 1fr;
//     }
    
// }