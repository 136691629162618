.sales-office__container {
    width: 100%;
    height: 100%;
    /*background: 
        linear-gradient(0deg, rgb(197 152 119 / 50%) 0%, rgb(225 191 140 / 50%) 90%), 
        radial-gradient(ellipse at bottom left, rgb(0 0 0 / 50%) 0%, rgb(255 255 255 / 100%) 50%)
    ;*/
    background: linear-gradient(45deg, #ECE9E6 0%, #FCFBFB 100%);
    background: 
        linear-gradient(0deg, 
            rgb(255 255 255 / 3%) 0%, 
            rgb(255 255 255 / 3%) 30%, 
            rgb(255 255 255 / 100%) 40%, 
            rgb(255 255 255 / 3%) 50%, 
            rgb(255 255 255 / 3%) 100%), 
        radial-gradient(ellipse at bottom left, 
            #ECE9E6 0%, 
            #FCFBFB 100%)
}

.sales-office__split-container {
    display: flex;
    justify-content: space-evenly;
    padding-top: 64px;
    height: 100%;
}

.sales-office__video-chat-container {
    width: 400px
}

.sales-office__bg-image-container {
    position: relative;
    padding: 32px;
    margin-top: 24px;
    z-index: 2;
}

.sales-office__bg-image {
    width: 960px;
    filter: 
        drop-shadow(-6px 11px 24px rgb(0 0 0 / 20%))
        drop-shadow(-4px 4px 8px rgb(0 0 0 / 20%))
        brightness(1.05)
        contrast(0.95)
    ;
}

.sales-office__elements-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    user-select: none;
}

.sales-office__element-container {
    position: absolute;
    width: fit-content;
    cursor: pointer;
}

.sales-office__element-highlight {
    opacity: 0;
    transition: 0.1s linear;
    cursor: pointer;
}

/* .sales-office__element-highlight:hover { */
.sales-office__element-highlight {
    opacity: 1;
    transition: 0.2s linear;
}

.sales-office__element-container.--plans {
    top: 248px;
    left: 101px;
}
.sales-office__element-container.--images {
    top: 226px;
    left: 643px;
}
.sales-office__element-container.--map {
    top: 470px;
    right: 298px;
}
.sales-office__element-container.--documents {
    top: 62px;
    right: 470px;
}
.sales-office__element-container.--overview {
    top: 424px;
    left: 286px;
    z-index: 6;
}

@media only screen and (max-width: 1024px) {
    .sales-office__bg-image {
        width: 800px;
    }
    /* === Images === */
    .sales-office__element-container.--images {
        top: 179px;
        left: 547px;
    }
    .elementHighlight.--images > svg {
        width: 187px;
    }
    .diaporama__container.--images {
        top: 18.5px;
        left: -4px;
        width: 153px;
        height: 122px;
    }
    /* === Plans === */
    .sales-office__element-container.--plans {
        top: 197px;
        left: 94px;
    }
    .elementHighlight.--plans > svg {
        width: 189px;
    }
    .diaporama__container.--plans {
        top: 19px;
        left: -3px;
        width: 152px;
        height: 133px;
    }
    /* === Map === */
    .sales-office__element-container.--map {
        top: 390px;
        right: 254px;
    }
    .elementHighlight.--map > svg {
        width: 112px;
    }
    /* === Documents === */
    .sales-office__element-container.--documents {
        top: 21px;
        right: 398px;
    }
    .elementHighlight.--documents > svg {
        width: 209px;
    }
    /* === Overview === */
    .sales-office__element-container.--overview {
        top: 350px;
        left: 244px;
    }
    .elementHighlight.--overview > svg {
        width: 90px;
    }
    /* === Client NameTag === */
    .nameTag__container.--client {
        left: 316px;
        top: 452px;
    }
}
@media only screen and (max-height: 880px) {
    .sales-office__bg-image {
        width: 800px;
    }
    /* === Images === */
    .sales-office__element-container.--images {
        top: 179px;
        left: 547px;
    }
    .elementHighlight.--images > svg {
        width: 187px;
    }
    .diaporama__container.--images {
        top: 18.5px;
        left: -4px;
        width: 153px;
        height: 122px;
    }
    /* === Plans === */
    .sales-office__element-container.--plans {
        top: 197px;
        left: 94px;
    }
    .elementHighlight.--plans > svg {
        width: 189px;
    }
    .diaporama__container.--plans {
        top: 19px;
        left: -3px;
        width: 152px;
        height: 133px;
    }
    /* === Map === */
    .sales-office__element-container.--map {
        top: 390px;
        right: 255px;
    }
    .elementHighlight.--map > svg {
        width: 110px;
    }
    /* === Documents === */
    .sales-office__element-container.--documents {
        top: 21px;
        right: 398px;
    }
    .elementHighlight.--documents > svg {
        width: 209px;
    }
    /* === Overview === */
    .sales-office__element-container.--overview {
        top: 350px;
        left: 244px;
    }
    .elementHighlight.--overview > svg {
        width: 90px;
    }
    /* === Client NameTag === */
    .nameTag__container.--client {
        left: 316px;
        top: 452px;
    }
}

@media only screen and (max-width: 880px) {
    .sales-office__bg-image {
        width: 640px;
    }
}
@media only screen and (max-width: 600px) {
    .sales-office__bg-image {
        width: 320px;
    }
}

.sales-office__bottom-logo-container {
    height: 64px;
    width: 100vw;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}
.sales-office__bottom-logo-wrapper {
    position: relative;
}
.sales-office__bottom-logo {
    padding: 6px;
    display: block;
    height: 36px;
    background: white;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    border-radius: 48px;
}

.sales-office__visitors-number {
    position: fixed;
    bottom: 16px;
    left: 16px;
    padding: 8px 16px;
    background-color: #ffffff;
    border-radius: 80px;
    font-size: 14px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
}

.sales-office__visitors-number > span {
    font-weight: 700;
    color: #3d59ec;
}