.elementLabel__container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 16px);
    padding: 4px 16px;
    background: #ffffff;
    box-shadow: 0 2px 7px rgb(0 0 0 / 14%), 0 0px 14px 1px rgb(219 224 252 / 50%);
    border-radius: 16px;
    white-space: nowrap;
    user-select: none;
    opacity: 0;
    z-index: 8;
    transition: 0.3s ease-out;
}
.sales-office__element-container:hover > .elementLabel__container {
    transform: translate(-50%, 0);
    opacity: 1;
}
.elementLabel__text {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    color: #3D59EC;
}

.elementLabel__container.--plans{
    top: -32px;
    left: calc(50% - 32px);
}

.elementLabel__container.--images{
    top: -32px;
    left: calc(50% - 16px);
}

.elementLabel__container.--map{
    top: -32px;
}

.elementLabel__container.--documents{
    top: 0px;
}

.elementLabel__container.--overview{
    top: -24px;
}