.fileIcon__container {
    position: relative;
    width: 64px;
    height: 88px;
    margin-bottom: 16px;
    transition: 0.15s;
}

.fileIcon__container.--selected {
    transform: translateY(-2px);
}

.fileIcon__container.--big-icon {
    width: 128px;
    height: 172px;
    margin-bottom: 0;
}

.fileIcon__container.--big-icon .fileIcon__type {
    font-size: 24px;
    letter-spacing: 2px;
    bottom: 32px;
}

.fileIcon__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #E9E9E0;
    clip-path: polygon(75% 0, 100% 20%, 100% 100%, 0 100%, 0 0);
}

.fileIcon__bg::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 25%;
    height: 20%;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
    background: #D9D7CA;
}

.fileIcon__type {
    position: absolute;
    bottom: 16px;
    right: -8px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
    letter-spacing: 1px;
}

.fileIcon__type.--pdf {
    background-color: #cf4242;
}

.fileIcon__type.--xls,
.fileIcon__type.--xlsx {
    background-color: hsl(98, 59%, 54%);
}

.fileIcon__type.--doc,
.fileIcon__type.--docx,
.fileIcon__type.--word {
    background-color: hsl(239, 42%, 56%);
}

.fileIcon__type.--txt {
    background-color: hsl(240, 8%, 55%);
}

.fileIcon__type.--ppt,
.fileIcon__type.--pptx {
    background-color: hsl(24, 72%, 56%);
}

.fileIcon__type.--mp4 {
    background-color: hsl(263, 49%, 54%);
}

.fileIcon__type.--png {
    background-color: #E2B32C
}

.fileIcon__type.--jpeg,
.fileIcon__type.--jpg {
    background-color: #2EB099;
}