/****FOREGROUND COLORS****/

$fg__default: #333333;
$fg__muted: #616161;
$fg__subtle: #9E9E9E;
$fg__on-accent: #FFFFFF;
$fg__on-disabled: #757575;
$fg__disabled: #BDBDBD;

/****BACKGROUND COLORS****/

$bg__surface: #FFFFFF;
$bg__muted: #E0E0E0;
$bg__subtle: #F6F7FD;
$bg__canvas: #FCFCFC;

/****ACCENT COLORS****/

$accent__default: #3D53F5;
$accent__emphasis: #3142C4;
$accent__muted: #6475F7;
$accent__subtle: #ECEEFE;
$accent__disabled: #E0E0E0;

/****WARNING COLORS****/

$warning__fg: #B38327;
$warning__emphasis: #FFBB38;
$warning__muted: #FFE4AF;
$warning__subtle: #FFF8EB;
$warning__on-warning: #171717;

/****ERROR COLORS****/

$error__fg: #9C0F2E;
$error__emphasis: #DF1642;
$error__muted: #E95C7B;
$error__subtle: #FCE8EC;
$error__on-error: #FFFFFF;

/****INFO COLORS****/

$info__fg: #3142C4;
$info__emphasis: #3D53F5;
$info__muted: #8B98F9;
$info__subtle: #D8DDFD;
$info__on-info: #FFFFFF;

/****SUCCESS COLORS****/

$success__fg: #11763D;
$success__emphasis: #18A957;
$success__muted: #5DC389;
$success__subtle: #E8F6EE;
$success__on-success: #FFFFFF;

/****INPUTS COLORS****/

$input__bg: #FFFFFF;
$input__text: #424242;
$input__placeholder: #9E9E9E;
$input__border-default: #E0E0E0;
$input__border-disabled: #EEEEEE;
$input__fg-disabled: #E0E0E0;

/****BORDER COLORS****/

$border__default: #F6F7FD;

/****OVERLAY****/

$overlay__bg: #17171799;