.auditor-comments__container{
    display: inline-block;
    position: relative;
    padding-left: 8px;
}
.auditor-comments__container:hover{
    cursor: pointer;
}
.auditor-comments__rectangle{
    height: 19px;
    width: 26px;
    border-radius: 8px;
    background-color: #FFF2C6;
    position: absolute;
    bottom: 0px;
    transition: width 0.6s, height 0.6s;
    box-shadow: 1px 2px 1px rgb(230 182 25 / 50%);
}
.auditor-comment__content{
    visibility: hidden;
    display: inline-block;
    /* white-space: nowrap; */
    width: max-content;
    padding: 6px 8px;
    opacity: 0;
    transition: opacity 0.6s, transform 0.6s;
    transform: translateY(8px);
}
.auditor-comment__content.--shown{
    visibility: visible; 
    opacity: 1;
    transform: translateY(0);
}
.auditor-comment__content > p {
    margin: 0;
    font-size: 12px;
    margin: 4px 0;
    padding: 0 8px;
    display: inline;
    position: relative;
    background-color: #ffe69c;
}
.auditor-comment__content > p::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 2px;
    background: #cc9a06;
    left: 0;
}

.auditor-comment__content *{
    /* white-space: nowrap; */
    width: fit-content;
}

.auditor-comments__container img{
    position: absolute;
    left: 16px;
}

.auditor-comments__container span{
    height: 8px;
    width: 9px;
    background-color: red;
    position: absolute;
    top: -2px;
    right: -2px;
    border-radius: 50%;
}

.auditor-comment__title {
    font-size: 12px;
    padding: 4px 0 8px;
    color: #997404;
}