@import "./../../../scss/main.scss"; 

.dragDropTemp__container {
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: $bg__subtle;
    @include bd-radius(large);
    @include shadow(60);
    transition: 0.15s;

    .dragDropTemp__contents {
        width: 100%;
        height: 100%;
        display: flex;
        border: 1px dashed $bg__muted;
        box-sizing: border-box;
        padding: 24px 48px;

        .dragDropTemp__batch-grid-items {
            width: 100%;
            height: fit-content;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 24px 16px;

            .dragDropTemp__batch-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: fit-content;
                padding: 16px 16px 8px 16px;
                background-color: $bg__surface;
                gap: 8px;
                @include bd-radius(large);
                @include shadow(80);

                .dragDropTemp__batch-item-name {
                    @include typo(x_small, bold, 1)
                }
            }
        }

        .dragDropTemp__file-upload-container {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;

            .dragDropTemp__text {
                @include typo(small, bold, 1);

                &.--active {
                    color: $bg__surface;
                }
            }
        }
    }
    &.--active {
        background-color: $accent__default;
    }
}

@media only screen and (max-width: 1482px) {
    .dragDropTemp__container {

        .dragDropTemp__contents {

            .dragDropTemp__batch-grid-items {
                grid-template-columns: repeat(4, 1fr);
            }
        }

    }
}

@media only screen and (max-width: 1250px) {
    .dragDropTemp__container {

        .dragDropTemp__contents {

            .dragDropTemp__batch-grid-items {
                grid-template-columns: repeat(3, 1fr);
            }
        }

    }
}