.langSelector__container {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
}

.langSelector__divider::after {
    content: "";
    height: 100%;
    border-right: 1px solid #dedede;
}

.langSelector__item {
    cursor: pointer;
    transition: color 0.15s;
    height: 100%;
    width: 20px;
}

.langSelector__item.active {
    color: #3d59ec;
    font-weight: 700;
}