@import "../../../../../scss/main.scss";

.planViewCard__container {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    cursor: pointer;
    @include bd-radius(large);
    @include shadow(80);
    transition: 0.15s;

    .planViewCard__unit-plan {
        width: 100%;
        height: 250px;
        position: relative;
    }

    .planViewCard__unit-plan::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 250px;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 80.73%, #000000 100%);
        opacity: 0.05;
        z-index: 5;
    }
    
    .planViewCard__unit-info {
        display: flex;
        flex-direction: column;
        padding: 24px;
        gap: 8px;
        background: $bg__surface;

        .planViewCard__unit-number-price {
            display: flex;
            justify-content: space-between;

            .planViewCard__unit-number {
                @include typo(headline_5, bold, 1);
            }

            .planViewCard__unit-price {
                display: flex;
                align-items: center;
                @include typo(lead, bold, 1);

                p {
                    margin: 0px;
                    @include typo(button_medium, regular, 1);
                }
            }
        }
        .planViewCard__unit-essentials {
            color: $accent__default;
            @include typo(caption, bold, 2);
        }
    }
}

.planViewCard__container:hover {
    @include shadow(100);
    transform: translateY(-4px);
}