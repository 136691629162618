@import "../../../../../../../scss/main.scss";

.floorPlan__upload-info-container {
    display: flex;
    justify-content: space-between;

    .floorPlan__upload-text-container {
        display: flex;
        flex-direction: column;

        .floorPlan__upload-title {
            @include typo(body, bold, 1);
        }

        .floorPlan__upload-text{
            @include typo(caption, regular, 2);
        }
    }
}
.floorPlan__floor-plan-container {
    background-color: $bg__subtle;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
    padding: 24px;
    @include shadow(60);
    @include bd-radius(large);
    overflow: auto;
    
    .floorPlan__floor-plan-image {
        width: 85%;
        height: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
        
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}