@import "../../../scss/main.scss";

.unitsTable__container {
    width: 100%;
    overflow: hidden;
    box-shadow: 
        0 24px 32px rgb(0 0 0 / 4%),
        0 16px 24px rgb(0 0 0 / 4%),
        0 4px 8px rgb(0 0 0 / 4%),
        0 0px 1px rgb(0 0 0 / 4%);
    flex-grow: 1;
    background: #fcfcfc;
}

.unitsTable__scroll-container {
    overflow-x: auto;
    overflow-y: auto;
    height: 100%;
    width: 100%;
}

.unitsTable__table {
    border-spacing: 0px;
    margin: 0;
    width: 100%;
}

.unitsTable__headers-container {
    z-index: 6;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
}

.unitsTable__header-row {
    background-color: $bg__canvas;
    border-top: 1px solid $bg__muted;
    border-bottom: 1px solid $bg__muted;
    @include shadow(40);
    display: flex;
    width: fit-content;
    min-width: 100%;
    padding: 0 32px;
}

.unitsTable__header-cell {
    padding: 16px 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    // justify-self: center;
    justify-self: flex-start;
    align-self: center;
}

.unitsTable__header-cell > span {
    color: $fg__muted;
    user-select: none;
    text-transform: uppercase;
    @include typo(caption, regular, 1);

    div {
        color: $fg__muted;
        user-select: none;
        text-transform: uppercase;
        @include typo(caption, regular, 1);
    }
    
    strong {
        color: $fg__muted;
        text-transform: uppercase;
        @include typo(tiny, regular, 1);
    }
}

.unitsTable__body {
    height: 360px;
    width: 100%;
}

.unitsTable__after-units-container { 
    display: flex;
    justify-content: center;
    padding: 40px;
}

.unitsTable__add-unit-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    box-shadow: 0 4px 8px rgb(61 89 236 / 24%);
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #cccccc;
}

.unitsTable__add-unit {
    background: #ffffff;
    width: fit-content;
    cursor: pointer;
    transition: 0.15s;
    border: none;
    border-right: 1px solid #ccc;
    padding: 8px 16px 8px 16px;
}

.unitsTable__add-unit-arrow { 
    background: #ffffff;
    width: fit-content;
    cursor: pointer;
    transition: 0.15s;
    border: none;
    padding: 8px 12px;
    display: flex;
    height: 100%;
    align-items: center;
}

.unitsTable__add-unit-container > button:hover,
.unitsTable__add-unit-container > .unitsTable__add-unit-arrow:hover {
    background: #eeeeee;
}

.unitsTable__row {
    height: 64px;
    position: relative;
    cursor: pointer;
    width: fit-content;
    display: flex;
    min-width: 100% !important;
    padding: 0 32px;

    .unitsTable__cell:first-child span {
        font-weight: bold;
    }
}

.unitsTable__cell {
    margin: 0;
    padding: 16px 8px;
    position: relative;
    /* border-right: 2px solid #ffffff; */
}

.unitsTable__cell > div > span {
    @include typo(input_small, bold, 1);
    color: $fg__default;
}

// .unitsTable__header-cell.--sticky {
//     position: sticky !important;
//     top: auto;
//     left: 0;
//     z-index: 5; 
//     background: $bg__canvas;
// }

.unitsTable__cell.--sticky {
    position: sticky;
    top: auto;
    left: 0;
    background: $bg__canvas;
    z-index: 5; 
    box-shadow: 2px 0 4px rgb(0 0 0 / 4%);
}


// .unitsTable__resizer {
//     right: 0;
//     transform:translateX(50%);
//     background: #ffffff;
//     width: 20px;
//     border-left: 8px solid #3D59EC;
//     border-right: 8px solid #3D59EC;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     z-index: 1;
//     touch-action :none;
//     transition: 0.15s;
// }

.unitsTable__resizer:hover {
    border-left: 6px solid #3D59EC;
    border-right: 6px solid #3D59EC;
}

.unitsTable__resizer.--resizing {
    background: orange;
    border-left: 6px solid #3D59EC;
    border-right: 6px solid #3D59EC;
}

.unitsTable__row:nth-child(odd) {
    background-color: $bg__canvas;
}

.unitsTable__row:nth-child(even) {
    background-color: $bg__surface;
}

.unitsTable__row.--reserved, 
.unitsTable__row.--sold,
.unitsTable__row.--rented,
.unitsTable__row.--notAvailable {
    position: relative;
    cursor: not-allowed;
    opacity: 0.5;
}

.unitsTable__row.--reserved::after, 
.unitsTable__row.--sold::after,
.unitsTable__row.--rented::after,
.unitsTable__row.--notAvailable:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgb(0 0 0 / 6%)
}

.unitsTable__row.--available:hover {
    background-color: #f2f2f2;
}

.unitsTable__row.--reserved:hover,
.unitsTable__row.--sold:hover,
.unitsTable__row.--rented:hover,
.unitsTable__row.--notAvailable:hover {
    background-color: inherit;
}

.unitsTable__row.--clickable:not(.--reserved):hover,
.unitsTable__row.--clickable:not(.--sold):hover,
.unitsTable__row.--clickable:not(.--rented):hover,
.unitsTable__row.--clickable:not(.--notAvailable):hover {
    box-shadow: 0 2px 14px rgb(61 89 236 / 25%);
    z-index: 9;
}
.unitsTable__row.--clickable:not(.--reserved):hover::after,
.unitsTable__row.--clickable:not(.--sold):hover::after,
.unitsTable__row.--clickable:not(.--rented):hover::after,
.unitsTable__row.--clickable:not(.--notAvailable):after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: calc(100% - 4px);
    width: calc(100% - 4px);
    border: 2px solid #3D59EC;
    z-index: 4;
    border-radius: 4px;
    pointer-events: none;
}

.unitsTable__cell-plan {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 8px;
    margin: 0 auto;
    transition: 0.15s;
}

.unitsTable__cell-plan > svg > path {
    fill: $accent__default;
}

.unitsTable__cell-plan > img {
    width: 20px;
    height: 20px;
}

.unitsTable__no-units {
    padding-top: 64px;
    color: #999999;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.unitsTable__modal {
    position: fixed;
    top: 80px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 80px);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}

.unitsTable__modal-backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 0;
    cursor: pointer;
}

.unitsTable__modal-close {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
}

.unitsTable__modal-close > img {
    opacity: 0.7;
    width: 32px;
    transition: 0.15s;
}

.unitsTable__modal-close:hover > img {
    opacity: 1;
}

.unitsTable__modal-container {
    position: relative;
    width: calc(100% - 80px);
    height: calc(100% - 80px);
    background-color: #ffffff;
    z-index: 1;
    border-radius: 5px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.unitsTable__modal-name {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 40px;
}

.unitsTable__modal-name > span {
    color: #3D59EC;
}

.unitsTable__modal-image {
    width: 100%;
    flex-grow: 1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.columnHeader__sqft,
.columnHeader__price { 
    color: var(--white);
}

.columnHeader__sqft > strong,
.columnHeader__price > strong { 
    font-weight: 400;
    color: var(--white);
    font-size: 12px;
}

