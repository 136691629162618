@import "../../../scss/main.scss";

.formGroup__container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 48px;

    .formGroup__title-desc-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        max-width: 224px;

        .formGroup__title {
            @include typo(small, bold, 1);
        }
    }

    .formGroup__fields-container {
        display: flex;
        flex-direction: column;
        gap: 26px;

        .formGroup__fields-row {
            display: flex;
            align-items: center;
            gap: 24px;
        }
    }
}