@import "../../../scss/main.scss";

.toggleButton__switch { 
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
}

.toggleButton__switch > input { 
    opacity: 0;
    width: 0;
    height: 0;
}

.toggleButton__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $bg__muted;
    box-shadow: inset 0 0 3px rgba(0 0 0 / 5%);
    border-radius: 10px;
    transition: 0.15s;
}
  
.toggleButton__slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    top: 4px;
    box-shadow: 0 0 4px rgba(0 0 0 / 5%);
    background-color: $bg__canvas;
    transition: 0.15s;
    border-radius: 50%;
}

input:checked + .toggleButton__slider {
    background-color: $accent__default;
    box-shadow: inset 0 0 3px rgba(0 0 0 / 10%);
}
  
input:checked + .toggleButton__slider:before {
    transform: translateX(16px);
    box-shadow: 0 0 4px rgba(0 0 0 / 10%);
}
