@import "../../scss/main.scss";

.vsoDashboard__container {
    position: relative;
    width: 100%;
    display: flex;
    padding: 64px 0 0 0;
}

.vsoDashboard__content-container {
    flex-grow: 1;
    overflow-y: auto;
    @include shadow(100);
}