@import "../../../../scss/main.scss";

.stepProgressBar__container {
    display: flex;
    width: fit-content;
    height: fit-content;
    font-weight: bold;
    color: $accent__default;
    position: relative;
}

.stepProgressBar__container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include shadow(100);
    border-radius: 100%;
}

.stepProgressBar__container .CircularProgressbar > .CircularProgressbar-text {
    @include typo(lead, bold, 1);
    transform: translateY(2px);
    fill: $fg__default;
}


