@import "../../../scss/main.scss";

.divider {
    background-color: $bg__muted;

    &.horizontal {
        height: 1px;
    }

    &.vertical {
        width: 1px;
    }
}