@import "../../../../../scss/main.scss";

.uploadPlans__upload-plans-container {
    background: $bg__surface;
    width: fit-content;
    padding: 24px 56px;
    border-radius: 12px;

    @include shadow(80);
}

.uploadPlans__unit-plans-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 64px;
    background-color: rgba(61, 89, 236, 0.02);
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%233D59ECFF' strokeWidth='1' stroke-dasharray='10' stroke-dashoffset='13' strokeLinecap='square'/%3e%3c/svg%3e");
    border-radius: 10px;
    cursor: pointer;
}

.uploadPlans__project-bg-image > svg {
    width: 125px;
    height: 125px;
}

.uploadPlans__unit-plans-container.active {
    background-color: #143afa71;
}

#uploadPlans__unit-plans-input {
    display: none;
}

.uploadPlans__unit-plans-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.uploadPlans__unit-plans-text > h3 {
    color: rgba(102, 102, 102, 1);
    font-size: 20px;
    text-align: center;
}

.uploadPlans__unit-plans-text > h3 > strong {
    color: var(--blue);
    text-decoration: underline;
}

.uploadPlans__unit-plans-text > p {
    margin: 0px;
    color: rgba(102, 102, 102, 1);
    font-size: 16px;
    line-height: 2;
    text-align: center;
}

.uploadPlans__unit-plans-text > p > strong {
    color: var(--blue);
    font-weight: 800;
}

.uploadPlans__unit-plans-view-how {
    display: flex;
    flex-direction: column;
    border: 1px solid #e4e9ec;
    background:rgba(61, 89, 236, 0.02);
    margin-top: 24px;
    padding: 16px 24px;
    gap: 16px;
    overflow-x: auto;
}

.uploadPlans__unit-plans-view-how > svg {
    cursor: pointer;
    height: 48px;
}

.uploadPlans__unit-plans-view-how > svg > path {
    fill: var(--blue);
}

.uploadPlans__unit-plans-view-how > p {
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
    color: rgba(112, 112, 112, 1);
}

.uploadPlans__unit-plan-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 16px;
    padding: 16px 32px;
    background: var(--white);
    border: 1px solid rgba(61, 90, 236, 0.4);
    border-radius: 8px;
}
.uploadPlans__unit-plan-items > p {
    margin: 0;
    white-space: nowrap;
}