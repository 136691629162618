.dashboard-container,
.dashboard-container-vso {
    padding: 32px 32px 0 32px;
    flex-grow: 1;
    background: #f6f7f9;
    overflow-y: scroll;
    margin-top: 64px;
}
.dashboard-container {
    min-height: 100%;
}
.dashboard-container-vso{
    padding: 32px 32px 0 56px;
}

.dashboard-container-vso.--inactive { 
    padding: 32px 8% 0 8%;
}

.dashboard-container-vso > h2{
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 36px;
}

.dashboard__welcome-container {
    width: 100%;
    background: #ffffff;
    padding: 16px 24px;
    border-radius: 16px;
    margin-bottom: 48px;
}
.dashboard__welcome-container span {
    color: #FF7600;
}

.dashboard__split-container-vso {
    display: flex;
    flex-direction: column;
}

.dashboard__split-container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    column-gap: 32px;
    min-height: 100%;
}

.dashboard__sticky-info-box {
    position: sticky;
    padding: 24px 32px;
    background: #ffffff;
    border-radius: 16px;
    height: fit-content;
    top: 0px;
}
.dashboard__panel {
    padding: 24px 32px;
    background: #ffffff;
    border-radius: 16px;
}
.dashboard__form {
    margin-top: 32px;
}
.auditor__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.search-bar {
    display: flex;
    align-items: center;
}
.dashboard__welcome-image {
    height: 600px;
    border-radius: 16px;
}

.dashboard__options-container{
    display: flex;
    flex-direction: column;
    gap: 42px;
}

.dashboard__title-options{
    font-size: 36px;
    font-weight: 800;
    align-self: center;
}

.dashboard__choose-option-container{
    display: flex;
    flex-direction: row;
    gap: 10%;
    justify-content: center;
}

.dashboard__virtual-office-option,
.dashboard__market-test-option{
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 42px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid rgba(221, 221, 221, 1);
    text-decoration: none;
    transition: 0.15s;
}

.dashboard__virtual-office-option > .dashboard__vso-image,
.dashboard__market-test-option > img{
    height: 300px;
    width: 400px;
}
.dashboard__vso-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.dashboard__virtual-office-option > div,
.dashboard__market-test-option > div{
    font-size: 28px;
    font-weight: 800;
    align-self: center;
}

.dashboard__virtual-office-option:hover,
.dashboard__market-test-option:hover{
    border: 1px solid #FE9D0D;
}