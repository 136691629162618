@import "../../../scss/main.scss";

.map__google-maps-container {
    height: 100%;
    width: 100%;
    position: relative;
}

.map__listing-map-address-container {
    position: relative;
    bottom: 48px;
    left: 24px;
    padding: 8px;
    background: $bg__surface;
    @include bd-radius(small);
    @include typo(body, bold, 1);
    @include shadow(20);
    border: 1px solid $accent__default;
}