@import "../../../scss/main.scss";

.datePicker__container {
    position: relative;

    &.--unit-table {
        display: flex;
    }

    label {
        @include typo(small, bold, 2);

        span {
            @include typo(small, bold, 1);
            color: $accent__default;
        }
    }

    .datePicker__selector-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @include typo(input_small, regular, 1);
        padding: 8px 16px;
        border: 2px solid $input__border-default;
        background: $bg__surface;
        transition: 0.15s;
        outline: none;

        &.--valid {
            border-color: $success__muted;
        }
        &.--denied {
            border-color: $error__muted;
        }

        &.--active {
            border: 2px solid $accent__default
        }

        &:hover {
            background: $bg__canvas;
            cursor: pointer;
        }

        .datePicker__value-placeholder {
            @include typo(input_small, regular, 1);
            color: $input__text;
            
            &.--active {
                color: $input__placeholder;
            }
        }

        &.--unit-table {
            width: 142px;
            height: 36px;
            margin-bottom: 0px;
            padding: 0 12px;
            justify-content: center;

            img {
                display: none;
            }
        }
    }

    .datePicker__error {
        position: absolute;
        top: calc(100% + 0px);
        right: 0px;
        @include typo(caption, regular, 1);
        color: $error__muted;
    }
}

.datePicker__dropdown-container {
    display: none;

    &.--active {
        display: flex;
        position: relative;
    }

    .datePicker__month-season-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 12px;
        left: 0px;
        height: auto;
        border: 1px #FEA219 solid;
        border-radius: 12px;
        background-color: white;
        @include shadow(20);
        z-index: 6;

        &::before {
            content: "";
            position: absolute;
            top: -5px;
            left: 24px;
            transform: translateY(-50%);
            width: 0; 
            height: 0; 
            border-bottom: 8px solid #FF9401;
            border-right: 8px solid transparent; 
            border-left: 8px solid transparent;
        }

        &.--vso {
            border: 1px solid $accent__default;
            
            &::before {
                border-bottom: 8px solid $accent__default;
            }
        }

        .datePicker__date-selector {
            display: flex;
            flex-direction: row;
            padding: 16px;
            justify-content: space-between;
            gap: 16px;

            button {
                padding: 0px;
            }

            .datePicker__month,
            .datePicker__season,
            .datePicker__year {
                border: none;
                background: none;
                position: relative;
                font-size: 14px;

                &:hover {
                    cursor: pointer;
                }

                &::after,
                &::after,
                &::after { 
                    content: "";
                    width: 0;
                    background: #FE9D0D;
                    height: 3px;
                    transition: all .4s ease-in-out;
                    position: absolute;
                    bottom: -4px;
                    right: 0px;
                }

                &.--active::after {
                    width: 100%;
                    left: 0;
                }

                &.--vso::after {
                    background: $accent__default;
                }
            }

        }
        
        .datePicker__month-container,
        .datePicker__season-container,
        .datePicker__year-list-container {
            display: none;

            &.--vso {
                display: none;

                div:hover {
                    background: $bg__subtle;
                    color: $accent__default;
                    cursor: pointer;
                    border: 1px solid $accent__default;
                }
            }

            &.--active {
                display: grid;
                grid-template-columns: 1fr 1fr;
                border-radius: 0 0 12px 12px;
                background-color: $bg__canvas;
                gap: 16px;
                padding: 16px;
                min-width: 240px;
                max-height: 350px;
                height: fit-content;
                overflow-y: auto;
            }

            div {
                @include typo(button_small, regular, 1);
            }

            div:hover {
                background: #FFEBCF;
                color: #FF5500;
                cursor: pointer;
                border: 1px solid #FE9D0D;
            }

            &.--active > div {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid $input__border-default;
                border-radius: 4px;
                flex-basis: calc(30% - 9px);
                min-width: 96px;
                font-size: 14px;
                padding: 8px 16px;
                background-color: #FFFFFF;
                transition: 0.15s;
            }
        }

        .datePicker__year-list-container > div {
            min-width: 87px;
        }

        &.--unit-table {
            position: unset;
            width: 380px;
            height: auto;
            border: none;
    
            &::before {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 1482px) { 
    .datePicker__selector-container > img { 
        width: 12px;
    }

    .datePicker__date-selector { 
        padding: 12px 16px;
    }

    .datePicker__month.--vso::after,
    .datePicker__season.--vso::after,
    .datePicker__year.--vso::after {
        height: 2px;
        transition: all .4s ease-in-out;
    }
} 


