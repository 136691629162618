@import "../../../scss/main.scss";

.table__container {
    width: 100%;
    overflow: hidden;
    box-shadow: 
        0 24px 32px rgb(0 0 0 / 4%),
        0 16px 24px rgb(0 0 0 / 4%),
        0 4px 8px rgb(0 0 0 / 4%),
        0 0px 1px rgb(0 0 0 / 4%);
    flex-grow: 1;
    background: #fcfcfc;
}

.table__scroll-container {
    overflow-x: auto;
    overflow-y: auto;
    height: 100%;
    width: 100%;
}

.table__table {
    border-spacing: 0px;
    margin: 0;
    width: 100%;
}

.table__headers-container {
    z-index: 6;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
}

.table__header-row {
    background-color: $bg__canvas;
    border-top: 1px solid $bg__muted;
    border-bottom: 1px solid $bg__muted;
    @include shadow(40);
    display: flex;
    width: fit-content;
    min-width: 100%;
    padding: 0 32px;
}

.table__header-cell {
    padding: 16px 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    // justify-self: center;
    justify-self: flex-start;
    align-self: center;
}

.table__header-cell > span {
    color: $fg__muted;
    user-select: none;
    text-transform: uppercase;
    @include typo(caption, regular, 1);

    div {
        color: $fg__muted;
        user-select: none;
        text-transform: uppercase;
        @include typo(caption, regular, 1);
    }
    
    strong {
        color: $fg__muted;
        text-transform: uppercase;
        @include typo(tiny, regular, 1);
    }
}

.table__body {
    height: 360px;
    width: 100%;
}

.table__after-units-container { 
    display: flex;
    justify-content: center;
    padding: 40px;
}

.table__row {
    height: 64px;
    position: relative;
    cursor: pointer;
    width: fit-content;
    display: flex;
    min-width: 100% !important;
    padding: 0 32px;

    .table__cell:first-child span {
        font-weight: bold;
    }
}

.table__cell {
    margin: 0;
    padding: 16px 8px;
    position: relative;
    /* border-right: 2px solid #ffffff; */
}

.table__cell > div > span {
    @include typo(input_small, bold, 1);
    color: $fg__default;
}

// .table__header-cell.--sticky {
//     position: sticky !important;
//     top: auto;
//     left: 0;
//     z-index: 5; 
//     background: $bg__canvas;
// }

.table__cell.--sticky {
    position: sticky;
    top: auto;
    left: 0;
    background: $bg__canvas;
    z-index: 5; 
    box-shadow: 2px 0 4px rgb(0 0 0 / 4%);
}


// .table__resizer {
//     right: 0;
//     transform:translateX(50%);
//     background: #ffffff;
//     width: 20px;
//     border-left: 8px solid #3D59EC;
//     border-right: 8px solid #3D59EC;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     z-index: 1;
//     touch-action :none;
//     transition: 0.15s;
// }

.table__resizer:hover {
    border-left: 6px solid #3D59EC;
    border-right: 6px solid #3D59EC;
}

.table__resizer.--resizing {
    background: orange;
    border-left: 6px solid #3D59EC;
    border-right: 6px solid #3D59EC;
}

.table__row:nth-child(odd) {
    background-color: $bg__canvas;
}

.table__row:nth-child(even) {
    background-color: $bg__surface;
}

.table__cell-plan {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 8px;
    margin: 0 auto;
    transition: 0.15s;
}

.table__cell-plan > svg > path {
    fill: $accent__default;
}

.table__cell-plan > img {
    width: 20px;
    height: 20px;
}

.table__no-data {
    padding-top: 64px;
    color: #999999;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}