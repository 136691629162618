.unitCard__container {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #D6D6D6;
}

.unitCard__title-container {
    position: relative;
    padding: 24px 24px 16px;
    width: 100%;
    box-shadow: 0 2px 8px rgb(0 0 0 / 12%);
    background-color: #ffffff;
}

.unitCard__title-row {
    display: flex;
    justify-content: space-between;
}

.unitCard__unit-price {
    position: relative;
    font-weight: 500;
}

.unitCard__unit-price > span {
    font-size: 12px;
}

.unitCard__unit-price > div {
    position: absolute;
    margin-top: 2px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    font-weight: 400;
    color: #6B7886;
    white-space: nowrap;
}

.unitCard__project-address {
    display: flex;
    gap: 12px;
    margin-top: 8px;
    align-items: center;
}

.unitCard__project-address > svg {
    height: 16px;
    width: 16px;
}

.unitCard__project-address > div {
    color: #6B7886;
    font-size: 14px;
}

.unitCard__plan {
    height: 320px;
    width: 100%;
    position: relative;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #f6f6f6;
}

.unitCard__info-container {
    padding: 16px 8px;
    display: flex;
    align-items: center;
    gap: 16px;
    width: calc(100% - 48px);
    border-bottom: 1px solid #dddddd;
}

.unitCard__title {
    font-size: 24px;
    margin-bottom: 32px;
    font-weight: 600;
}

.unitCard__row {
    margin-bottom: 64px;
    width: 100%;
    display: flex;
    gap: 32px;
    flex-grow: 1;
}

.unitCard__expand-button {
    position: absolute;
    bottom: 16px;
    right: 16px;
    font-size: 14px;
    font-weight: 500;
    padding: 4px 8px;
    box-shadow:
        0 4px 8px rgb(0 0 0 / 8%),
        0 2px 2px rgb(0 0 0 / 12%),
        0 0 1px rgb(0 0 0 / 8%);
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    border-radius: 4px;
    background: #ffffff;
    transition: 0.15s;
}

.unitCard__expand-button:hover {
    background: #fafafa;
}

.unitCard__expand-button > svg {
    width: 18px;
}

.unitCard__unit-number {
    font-size: 16px;
    position: relative;
    font-weight: 500;
}

.unitCard__unit-number > span {
    font-weight: 600;
}

.unitCard__unit-info-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    width: 33.33333334%;
}

.unitCard__unit-info-title {
    font-weight: 500;
}

.unitCard__image {
    flex-grow: 1;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
}

.unitCard__checkout-container {
    padding: 16px 0;
    width: calc(100% - 48px);
}

.unitCard__checkout-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
}

.unitCard__checkout-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.unitCard__checkout-item {
    display: flex;
    justify-content: space-between;
}

.unitCard__checkout-item-name,
.unitCard__checkout-item-price {
    font-size: 14px;
}

.unitCard__checkout-item:last-child {
    margin-top: 8px;
}
.unitCard__checkout-item:last-child > div {
    font-weight: 700;
    font-size: 16px;
}

.unitCard__cancellation-panel {
    color: var(--blue);
    font-weight: 600;
}

.unitCard__cancellation-panel,
.unitCard__questions-panel {
    padding: 24px;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #D6D6D6;
}

.unitCard__questions-panel {
    display: flex;
    gap: 16px;
}

.unitCard__questions-panel > div > div:last-child {
    margin-top: 8px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}