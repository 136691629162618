.loadingBar__container { 
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 650px;
    height: 200px;
    padding: 48px 32px;
    background-color: white;
    border-radius: 12px;
    gap: 36px;
}

.loadingBar__text { 
    align-self: flex-start;
    font-size: 18px;
    font-weight: 600;
}

.loadingBar__outer-bar { 
    height: 25px;
    width: 100%;
    background-color: #cccccc;
    border-radius: 50px;
    overflow: hidden;
}
.loadingBar__outer-bar.--thin {
    height: 8px;
    border-radius: 4px;
}

.loadingBar__inner-bar { 
    background-color: #3d59ec;
    height: 100%;
    border-radius: 50px;
    width: 0%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: width 1s ease-out;
    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    animation: loading-bar-stripes 1s linear infinite;
    background-size: 2rem 2rem;
}

.loadingBar__inner-bar > span { 
    color: white;
    padding-right: 8px;
}

@keyframes loading-bar-stripes {
    from { background-position: 2rem 0; }
    to { background-position: 0 0; }
  }