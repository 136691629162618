@import "../../../../scss/main.scss";

.inputNumber__container {
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 6px;
    position: relative;

    label {
        @include typo(small, bold, 2);

        span {
            @include typo(small, bold, 1);
            color: $accent__default;
        }
    }

    .inputNumber__suffix-container {
        display: flex;
        position: relative;

        // &:not(:last-child) {
        //     border-right: 2px solid $input__border-default;
        // }

        input {
            width: fit-content;
            display: flex;
            flex-direction: row;
            align-items: center;
            @include typo(input_small, regular, 1);
            padding: 8px 16px;
            background: $bg__surface;
            transition: 0.15s;
            outline: none;
            border: 2px solid $input__border-default;

            &.--valid {
                border-color: $success__muted;
            }
            &.--denied {
                border-color: $error__muted;
            }
        }
    
        input::placeholder {
            @include typo(input_small, regular, 1);
            color: $input__placeholder;
        }
    
        input:focus {
            border-color: $accent__muted;
        }

        .inputNumber__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 16px;
            color: $accent__default;
            @include typo(button_small, bold, 1);
            border-top: 2px solid $input__border-default;
            border-bottom: 2px solid $input__border-default;
            border-right: 2px solid $input__border-default;
        }
    }

    // .input__text.--valid {
    //     border-color: #3ec775;
    // }
    // .input__text.--denied {
    //     border-color: #c06b5d;
    // }

    // /* Other elements */
    // .input__text-error {
    //     position: absolute;
    //     top: calc(100% + 4px);
    //     font-size: 14px;
    //     color: #c06b5d;
    // }

    .inputNumber__error {
        position: absolute;
        top: calc(100% + 0px);
        left: 0px;
        @include typo(caption, regular, 1);
        color: $error__muted;
    }
}
