@import "../../../../../../scss/main.scss";

.imageInfo__container {
    display: grid;
    grid-template-columns: 320px 320px;

    img {
        width: 100%;

        &:nth-child(1) {
            border-right: 1px solid $accent__default;
            border-bottom: 1px solid $accent__default;
        }

        &:nth-child(2) {
            border-left: 1px solid $accent__default;
            border-bottom: 1px solid $accent__default;
        }

        &:nth-child(4) {
            border-left: 1px solid $accent__default;
            border-top: 1px solid $accent__default;
        }
    }

    .imageInfo__info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 24px;
        gap: 12px;
        background: $bg__surface;
        border-right: 1px solid $accent__default;
        border-top: 1px solid $accent__default;


        .imageInfo__info-title {
            @include typo(small, bold, 2);
            color: $accent__default;
        }
    
        .imageInfo__info-items-title {
            @include typo(caption, regular, 2);
            
        }
            
        .imageInfo__info-items {
            ul {
                display: flex;
                flex-direction: column;
                margin: 0px;

                li {
                    @include typo(caption, regular, 2);
                }
            }
        }
    }
}