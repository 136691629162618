@import "../../../../scss/main.scss";

.vsoDashStats__container {
    overflow-y: auto;
    height: fit-content;
    background: $bg__canvas;
}

.vsoDashStats__header-container {
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding: 32px 48px 24px;
    background-color: $bg__canvas;
    @include shadow(40);
}

.vsoDashStats__header-inner-container {
    display: flex;
    justify-content: space-between;
}

.vsoDashStats__header {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.vsoDashStats__header > div {
    @include typo(headline_3, bold, 1);
}

.vsoDashStats__header > p {
    margin: 0;
    color: $fg__muted;
    @include typo(small, regular, 1);
}

.vsoDashStats__sections-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px 48px;
    background: $bg__canvas;
}

.vsoDashStats__title {
    @include typo(lead, bold, 1);
    margin-bottom: 24px;
}

.vsoDashStats__summary-container {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    background: $bg__surface;
    @include bd-radius(large);
    @include shadow(60);
}

.vsoDashStats__summary-item {
    margin: 32px 48px;
    border-right: 1px solid $bg__muted;
}

.vsoDashStats__summary-title {
    color: $fg__muted;
    @include typo(headline_4, bold, 1);

    @media screen and (max-width: 1200px) {
        @include typo(lead, bold, 1);
    }
}

.vsoDashStats__summary-value {
    color: $accent__default;
    @include typo(display, bold, 1);

    @media screen and (max-width: 1200px) {
        @include typo(headline_1, bold, 1);
    }
}

.vsoDashStats__summary-progress {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
        color: $fg__subtle;
        @include typo(caption, regular, 1);
    }
}

.vsoDashStats__progress-container {
    width: 100%;
    background: $bg__surface;
    padding: 24px;
    display: flex;
    justify-content: center;
    @include bd-radius(large);
    @include shadow(60);
}

.vsoDashStats__deposit-container {
    width: 100%;
    background: $bg__surface;
    padding: 24px 32px;
    display: flex;
    justify-content: center;
    gap: 64px;
    @include bd-radius(large);
    @include shadow(40);
}

.vsoDashStats__deposit-boxes {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.vsoDashStats__deposit-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px;
    @include bd-radius(medium);
    @include shadow(60);
}

.vsoDashStats__deposit-value {
    color: $accent__emphasis;
    @include typo(headline_3, bold, 1);
}

.vsoDashStats__deposit-title {
    color: $fg__muted;
    @include typo(body, regular, 2);
}

.vsoDashStats__deposit-chart {
    flex-grow: 1;
    max-width: 640px;
}

.vsoDashStats__unit-type-container {
    width: 100%;
    background: $bg__surface;
    padding: 32px;
    display: flex;
    justify-content: center;
    @include bd-radius(large);
    @include shadow(40);

    div {
        flex-grow: 1;
    }
}

.vsoDashStats__stats-table {
    .vsoDashStats__stats-table-header {
        position: relative;
        display: flex;
        width: 100%;
        background: $accent__emphasis;
    
        div {
            flex-grow: 1;
            width: 20%;
            padding: 12px 24px;
            color: $fg__on-accent;
            @include typo(small, bold, 2);
        }
    }

    .vsoDashStats__stats-table-row {
        position: relative;
        display: flex;
        width: 100%;
        background: $bg__surface;
    
        &:nth-child(odd) {
            background: $bg__canvas;
        }
        div {
            flex-grow: 1;
            width: 20%;
            padding: 12px 24px;
            color: $fg__default;
            @include typo(small, bold, 2);
        }

        &.--total {
            div {
                @include typo(small, bold, 1);
            }
        }
    }
}

.vsoDashStats__unit-floor-container {
    width: 100%;
    background: $bg__surface;
    padding: 32px;
    display: flex;
    justify-content: center;
    @include bd-radius(large);
    @include shadow(40);

    div {
        flex-grow: 1;
    }
}

.vsoDashStats__viewed-units-container {
    width: 100%;
    background: $bg__surface;
    padding: 32px;
    @include bd-radius(large);
    @include shadow(40);

    div {
        flex-grow: 1;
    }
}
