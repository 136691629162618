@import "../../../scss/main.scss";

.steps__container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: $bg__surface;
    @include bd-radius(large);
    @include shadow(60);

    .steps__title-desc-container{
        .steps__title {
            @include typo(body, bold, 1);
        }

        .steps__desc {
            @include typo(caption, bold, 2);
        }
    }
}