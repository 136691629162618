.copyTable__main-container { 
    background: var(--white);
    border-radius: 8px;
    width: 850px;
    height: fit-content;
    padding: 32px 48px;
}

.copyTable__confirm-copy-container { 
    display: flex;
    align-items: center;
    gap: 96px;
    margin: 64px 0px;
}

.copyTable__selected-floor-text { 
    background-color: var(--blue);
    padding: 12px 64px;
    height: fit-content;
    border-radius: 8px;
}

.copyTable__selected-floor-text > p {
    color: var(--white);
    margin: 0px;
    font-size: 18px;
    text-decoration: underline;
    font-weight: bold;
}

.copyTable__confirm-copy-icons {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.copyTable__confirm-copy-icons > p {
    margin: 0;
}

.copyTable__copy-to-floor-text {
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1), 0px 5px 24px -9px var(--blue);
    padding: 24px 48px;
}

.copyTable__copy-to-floor-items { 
    display: flex;
    align-items: center;
    gap: 8px;
}

.copyTable__copy-to-floor-items > p { 
    font-weight: bold;
    margin: 0;
}

.--disabled { 
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: default;
}

.--disabled:hover{ 
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: default;
}


