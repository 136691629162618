.mt-overview-container {
    padding: 64px 0 0 0;
    flex-grow: 1;
    min-height: 100%;
    background: #f3f5f7;
    overflow-y: scroll;
    position: relative;
}
.mt-o__banner {
    width: 100%;
    min-height: 400px;
    height: 33vh;
    position: relative;
}
.mt-o__banner-bg {
    position: absolute;
    left: 0;
    top: 0;
    background-color: grey;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.mt-o__tab-container {
    position: absolute;
    bottom: 0;
    left: 64px;
    transform: translateY(50%);
    background: #1551e8;
    display: flex;
    height: 64px;
    align-items: center;
    border-radius: 32px;
    z-index: 2;
}
.mt-o__tab-item {
    color: #ffffff;
    font-weight: 700;
    padding: 24px 64px;
    position: relative;
    cursor: pointer;
}
.mt-o__tab-item--active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 64px;
    height: 8px;
    background: #ff7600;
}
.mt-o__banner-info {
    bottom: 0;
    left: 64px;
    padding: 32px 32px 80px 32px;
    background: #ffffff;
    max-width: 400px;
    position: absolute;
    z-index: 1;
}
.mt-o__banner-dates-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.mt-o__banner-info-arrow {
    position: absolute;
    background: #1551e8;
    width: 16px;
    height: 16px;
    right: 48px;
}
.mt-o__tab-pane-container {
    padding: 80px 64px;
}
.mt-o__overview {
    display: grid;
    grid-template-columns: minmax(685px, 825px) minmax(315px, 375px);
    grid-template-rows: 400px 250px;
}
.mt-o__overview-reg_lead {
    min-width: 200px;
}
.mt-o__overview-reg_leads-tophalf {
    display: grid;
    grid-template-columns: 60% 40%;
}
.mt-o__overview-reg_leads {
    background: #ffffff;
    border-radius: 18px;
    height: 400px;
    padding: 30px;
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.mt-o__overview-current_leads {
    margin-top: 30px;
    margin-left: 80px;
}
.current_leads_num {
    font-size: 50px;
    font-weight: bold;
    color: #FF7600;
    text-align: right;
}
.total_leads_num {
    font-weight: bolder;
    font-size: 90px;
}
.total_leads-text {
    height: 50px;
    padding-top: 55px;
    text-align: right;
}
.arrow-down_o {
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #FF7600;
    margin-left: 50px;
  }
.arrow-down_b {
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid black;
    margin-left: 85%;
    margin-top: 2%;
}
.mt-o__overview-total_leads {
    display: grid;
    grid-template-columns: minmax(30px, 260px) minmax(110px, 110px);
}
.total_leads-num {
    font-size: 90px;
}

.progress-bar {
    position: relative;
    height: 20px;
    width: 100%;
    background: #FE9D0D;
    border-radius: 18px;
    margin-bottom: 50px;
}
.filler{
    background: #FF7600;
    height: 100%;
    border-radius: inherit;
    transition: width .2s ease-in;
}
.mt-o__overview-reg_leads-bottom {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
}
.mt-o__overview-ppl_reached, .mt-o__overview-link_clicks, .mt-o__overview-budget_spent {
    border: 1px solid #FFBA7F;
    border-radius: 18px;
    padding: 12px 16px;
    font-weight: 500;
    font-size: 16px;
}
.mt-o__overview-text {
    font-weight: 900;
    font-size: 30px;
    padding-top: 8px;
}
.mt-o__overview-location {
    background: #ffffff;
    border-radius: 18px;
    height: 400px;
    padding: 30px;
    margin-left: 12px;
}
.mt-o__overview-budget-demand {
    display: grid;
    grid-template-columns: 50% 50%;
}
.mt-o__overview-budget {
    background: #ffffff;
    border-radius: 18px;
    height: 230px;
    margin-right: 12px;
    margin-top: 12px;
    position: relative;
    overflow: hidden;
}
.mt-o__overview-budget_text {
    padding: 30px;
    height: auto;
    font-size: small;
}
.mt-o__overview-budget_info-container {
    position: relative;
}
.mt-o__overview-budget_info-triangle {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 12px solid #FF7600;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}
.mt-o__overview-budget_info-circle {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color:  white;
    z-index: 5;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    bottom: -38px;
}
.mt-o__overview-budget_info-circle::before {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color:  #FF7600;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    bottom: 2px;
    content: "";
}
.mt-o__overview-budget_info {
    font-size: 28px;
    font-weight: 600;
    background: linear-gradient(270deg, #FF7600 0%, #FE9D0D 100%);
    border-radius: 35px;
    padding: 8px 32px;
    width: fit-content;
    text-align: center;
    color: white;
    margin: 0px auto;
}
.mt-o__overview-waves_container {
    position: absolute;
    margin-top: 25px;
    height: 80px;
    width: 388px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background-size: cover;
    background-image: url("../../assets/images/waves.svg");
    background-repeat: no-repeat;
}
.mt-o__overview-demand {
    background: #ffffff;
    border-radius: 18px;
    height: 230px;
    padding: 30px;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 12px;
    overflow: hidden;
}
.mt-o__overview-demand-info{
    display: grid;
    grid-template-columns: 50% 50%;
    height: 100%;
    width: 100%;
}
.mt-o__overview-demand-info-all{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.row {
    display : flex;
    align-items : center;
    margin-bottom: 15px;
}
.box {
    height: 20px;
    width: 20px;
    margin-right : 5px;
}
.orange-1 {
    background: #FF7600;
    mix-blend-mode: multiply;
}
.orange-2 {
    background: #FE9D0D;
    mix-blend-mode: multiply;
} 
.orange-3 {
    background: #FFCF7B;
    mix-blend-mode: multiply;
}
.mt-o__overview-demand-circles {
    position: relative;
}
.mt-o__overview-demand-circle {
    border-radius: 100%;
    width: 80px;
    height: 80px;
    text-align: center;
    color: white;
    position: absolute;
    transition: 0.15s;
}
.circle1 {
    right: 0;
    transform: scale(1);
}
.circle2 {
    top: 30%;
    left: 0;
    transform: scale(0.8);
}
.circle3 {
    bottom: 0;
    right: 0;
    transform: scale(0.55);
}
.mt-o__overview-demand-circle-percent {
    color: white;
    position: absolute;
    font-weight: 600;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
}
.mt-o__overview-actions {
    background: #ffffff;
    border-radius: 18px;
    height: 230px;
    padding: 30px;
    margin-left: 12px;
    margin-top: 12px;
}
.mt-o__overview-full_listing, .mt-o__overview-leads_list, .mt-o__overview-results_report {
    border-radius: 18px;
    padding: 8px;
    font-size: 12px;
    cursor: pointer;
    margin-top: 22px;
    font-weight: 600;
    padding-left: 20px;
}
.mt-o__overview-full_listing{
    border: 1px solid #FFBA7F;
    color: #FF8D2C;
}
.mt-o__overview-leads_list {
    background-color: #FFCF7A;
    color: #ffffff;
}
.mt-o__overview-results_report {
    background-color:  #1551e8;
    color: #ffffff;
}
.arrow {
    color: #FFA150;
    text-align: right;
    float: right;
    padding-right: 8px;
}
.arrow2 {
    color: #ffffff;
    text-align: right;
    float: right;
    padding-right: 13px;
}
.mt-o__price {
    background: #ffffff;
    border-radius: 18px;
    height: 400px;
    padding: 30px;
    min-width: 1000px;
    max-width: 1200px;
}
.mt-o__price-tabs {
    display: grid;
    margin-left: 120px;
    margin-right: 120px;
    grid-template-columns: 20% 20% 20% 20% 20%;
}
.mt-o__price-tab-global, .mt-o__price-tab-studio, .mt-o__price-tab-1bedroom, .mt-o__price-tab-2bedroom, .mt-o__price-tab-3bedroom, .mt-o__product-tab-bdrmDmnd, .mt-o__product-tab-parkDmnd {
    border-radius: 18px;
    background-color: #E6E6E6;
    padding: 7px;
    text-align: center;
    margin: 10px;
    font-size: small;
    cursor: pointer;
}
.mt-o__product-tab-bdrmDmnd {
    margin-left: 280px;
    margin-right: 15px;
}
.mt-o__product-tab-parkDmnd {
    margin-left: 15px;
    margin-right: 280px;
}
.mt-o__price-title {
    display: grid;
    grid-template-columns: 20% 80%;
}
.mt-o__price-title_avg {
    text-align: right;
}
.mt-o__price-title_avg_price {
    font-weight: 600;
    font-size: 20px;
}
.chart_table {
    display: grid;
    grid-template-columns: 80% 20%;
}
table {
    margin-top: 12px;
    width: 100%;
}
th {
    line-height: 25px;
    font-weight: normal;
    min-width: 65px;
}
td {
    text-align: center;
    line-height: 48px;
    font-size: 15px;
}
tr:hover {
    background-color: #E6E6E6;
  }
.col1, .col2, .col3 {
    width: 33%;
}
.col1, .col2 {
    background: #E6E6E6;
}
.col3 {
    background: #ff7600;
    color: #ffffff;
}
.coll1, .coll2, .coll3 {
    background: #D1D1D1;
    font-weight: 600;
}
.coll1 {
    min-width: 675px;
}
.coll2 {
    min-width: 150px;
}
.coll3 {
    min-width: 100px;
}
.coll1, .colll1, .collll1 {
    text-align: left;
}
.coll2, .colll2, .collll2, .coll3, .colll3, .collll3 {
    text-align: center;
}
.colll1, .colll2, .colll3, .collll1, .collll2, .collll3 {
    line-height: 27px;
}
.collll1, .collll2, .collll3 {
    font-weight: 600;
}
.demand1{
    background-color: #A6B9F3;
}
.demand2{
    background-color: #C4D3FA;
}
.demand3{
    background-color: #D0DBF9;
}
.demand4{
    background-color: #E7EDFD;
}
.mt-o__price-results_report {
    border-radius: 18px;
    padding: 8px;
    font-size: 12px;
    cursor: pointer;
    margin-top: 22px;
    font-weight: 600;
    padding-left: 20px;
    background-color:  #1551e8;
    color: #ffffff;
    width: 25%;
    float: right;
}
g[seriesName=Average] {
    transform: translateY(-12px);
}
g[seriesName=Span] {
    transform: translateY(9px);
}
.mt-o__product {
    background: #ffffff;
    border-radius: 18px;
    height: 587px;
    padding: 30px;
    min-width: 1000px;
    max-width: 1200px;
}
.mt-o__product-tabs {
    display: grid;
    margin-left: 120px;
    margin-right: 120px;
    grid-template-columns: 50% 50%;
}
.mt-o__leads {
    background: #ffffff;
    border-radius: 18px;
    height: 1030px;
    padding: 30px;
    min-width: 1000px;
    max-width: 1200px;
}
.parkingDmndCol {
    background-color: #1551e8;
    color:#ffffff;
}
.parkingDmndTable {
    border-radius: 10px;
    border: 1px solid #E6E6E6;
    position: relative;
}
.parkingDmndTable th {
    position: sticky;
    top: 0px;
}
.parkingDmndTableWrapper {
    height: 300px;
    overflow-y: scroll;
}
.mt-map-container {
    margin-top: 50px;
}