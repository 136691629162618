@import "../../../../scss/main.scss";

.selectInput__container {
    display: flex;
    flex-direction: column;
    gap: 6px;
    position: relative;

    label {
        @include typo(small, bold, 2);

        span {
            @include typo(small, bold, 1);
            color: $accent__default;
        }
    }

    select {
        display: flex;
        padding: 8px 48px 8px 16px;
        border: 2px solid $input__border-default;
        background: $bg__surface;
        transition: 0.15s;
        outline: none;
        -webkit-appearance: none;
        appearance: none;
        @include typo(input_small, regular, 1);
        background-image: url("../../../../assets/images/icons/cabana_cheveron_down.svg");
        background-repeat: no-repeat, repeat;
        background-position: right 16px center;

        &.--valid {
            border-color: $success__muted;
        }

        &.--denied {
            border-color: $error__muted;
        }

        &.--active {
           border-color: $accent__default;
        }
        
        &:required:invalid {
            color: $input__placeholder;
        }

        option[value=""][disabled] {
            display: none;
        }
        
    }
    
    .selectInput__error{
        position: absolute;
        top: calc(100% + 0px);
        right: 0px;
        @include typo(caption, regular, 1);
        color: $error__muted;
    }
}

