.dropdown__portal{
    position: fixed;
    z-index: 12;
}

.dropdown__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}
.dropdown__container {
    display: flex;
    flex-direction: column;
    gap: 2px;
    background: #ffffff;
    position: absolute;
    opacity: 1;
    width: max-content;
    font-weight: 500;
    border-radius: 4px;
    z-index: 1000;
    transition: 0.15s opacity;
    box-shadow: 
        0 8px 24px rgb(0 0 0 / 7%),
        0 4px 16px rgb(0 0 0 / 7%),
        0 0 1px rgb(0 0 0 / 7%);
    user-select: none;
}

.dropdown__container.--closed {
    opacity: 0;
    visibility: hidden;
    transition: 0.15s opacity, 0s 0.15s visibility;
}

.dropdown__container.--s {
    top: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
}

.dropdown__container.--se {
    top: calc(100% + 8px);
    left: 0;
}

.dropdown__container.--sw {
    top: calc(100% + 8px);
    right: calc(100% + 16px);
}

.dropdown__container.--nw {
    bottom: calc(100% + 8px);
    right: 0;
}
.dropdown__container.--bottom-center { 
    top: calc(100% + 8px);
    right: 0;
    transform: translateX(28%);
}

.dropdown__item {
    display: flex;
    gap: 8px;
    border-radius: 2px;
    align-items: center;
    color: #565656;
}

/* .dropdown__item:hover {
    background-color: #fafafa;
} */

.dropdown__item > img {
    height: 14px;
}