@import "../../../../scss/main.scss";

.filterError__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 54px 0px;
    width: 600px;
    height: 450px;
    background: $bg__surface;
    @include shadow(80);

    .filterError__title {
        color: $accent__disabled;
        @include typo(colossus, bold, 1);
    }

    .filterError__error-image {
        width: fit-content;
        height: fit-content;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .filterError__error-message {
        @include typo(body, regular, 1);
    }
}