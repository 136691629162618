@import "../../../../scss/main.scss";

.cell {
    position: relative;
    width: 100%;
    @include typo(input_small, regular, 1);
    color: $fg__default;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 8px;
}

.cell > div {
    display: flex;
    width: 100%;
    align-items: center;
}

.cell.--header {
    padding: 8px 32px;
    color: #ffffff;
    font-weight: 600;
}

.cell.--fixed {
    position: sticky;
    top: auto;
    left: 0;
    box-shadow: 2px 0 4px rgb(0 0 0 / 4%);
}

.unitsTable__cell-status {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 4px 8px;
    border-radius: 3px;
    text-transform: capitalize;
}