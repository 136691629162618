@import "../../../../../scss/main.scss";

.tableInput__radio {
    display: flex;
    width: 100%;
}

.tableInput__radio > div {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding: 4px 8px;
    background: #ffffff;
    border-top: 2px solid $input__border-default;
    border-bottom: 2px solid $input__border-default;
    color: $fg__default;
    transition: 0.15s;
    
    @include typo(small, regular, 1);

    &:hover {
        background: $accent__subtle;
        // border-color: $accent__muted;
    }
}

.tableInput__radio > div:first-child {
    padding: 4px 8px;
    border-left: 2px solid $input__border-default;
    border-right: 2px solid $input__border-default;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.tableInput__radio > div:last-child {
    padding: 4px 8px;
    border-right: 2px solid $input__border-default;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.tableInput__radio > div.--selected {
    background: $accent__default;
    color: $fg__on-accent;
    border-right: 2px solid $accent__default;
    border-left: 2px solid $accent__default;
    border-color: $accent__default;

    @include typo(small, bold, 1);
}