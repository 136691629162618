@import "../../../scss/helpers/variables.scss";
@import "../../../scss/helpers/mixins.scss";

.areaMap__container {
    display: flex;
    width: 100vw;
    height: calc(100vh - 64px);
    z-index: 3;
    position: fixed;
    top: 64px;
    left: 0;
    background: $bg__surface;
}

.areaMap__pane {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 45%;
    max-width: 720px;
    min-height: 100%;
    z-index: 1;
    
    @include shadow(100);
}

.areaMap__area-google-map {
    width: 55%;
    flex-grow: 1;
}

.areaMap__title-header {
    width: 100%;
    background: $accent__default;
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    z-index: 3;
    
    h4, p {
        color: $fg__on-accent;
        margin: 0;
    }

    h4 {
        @include typo(headline_4, bold, 1);
    }

    p {
        @include typo(small, regular, 2);
    }
}

.areaMap__browsing-container {
    position: relative;
    display: flex;
    width: 100%;
    flex-grow: 1;
    z-index: 1;
    overflow-y: scroll;
    background-color: $bg__subtle;
}

.areaMap__filters-list {
    position: sticky;
    padding: 24px 0;
    min-width: 186px;
    height: 100%;
    z-index: 2;
    top: 0;
    background: $bg__surface;

    @include shadow(60);
}

.areaMap__filter-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 8px 8px 24px;
    min-width: fit-content;
    width: 100%;
    cursor: pointer;
    gap: 8px;
    transition: 0.15s;

    @include typo(caption, regular, 1);

    svg {
        width: 24px;

        * {
            fill: $accent__default;
        }
    }
}

.areaMap__filter-item::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0px;
    background: $accent__default;
    transition: 0.15s;
}

.areaMap__filter-item.--active {
    background: $accent__subtle;
    padding: 8px 8px 8px 32px;
    
    @include typo(caption, bold, 1);
}

.areaMap__filter-item.--active::before {
    width: 4px;
}

.areaMap__places-list-container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px 0 0;
    flex-grow: 1;

    h5 {
        margin: 0 24px 16px 24px;

        @include typo(headline_5, bold, 1);
    }
}

.areaMap__places-list {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    gap: 16px;
    width: 100%;
}

.areaMap__place-item {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    padding: 16px 16px 16px 16px;
    background: $bg__surface;
    cursor: pointer;
    border: 2px solid $bg__surface;
    transition: 0.15s;

    @include shadow(40);
    @include bd-radius(medium);
}

.areaMap__place-item.--active {
    border: 2px solid $accent__default;
    
    @include shadow(60);

    .areaMap__place-name {
        color: $accent__default;
    }

    .areaMap__place-distance {
        color: $accent__muted;
    }
}

.areaMap__place-name {
    padding-bottom: 8px;

    @include typo(small, bold, 1);
}

.areaMap__place-address {
    color: $fg__muted;
    @include typo(caption, regular, 2);
}

.areaMap__place-distance {
    min-width: fit-content;
    color: $fg__subtle;
    white-space: nowrap;

    @include typo(x_small, bold, 2);
}

.areaMap__center-button-container {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;
}

.gm-style-iw {
    border-radius: 10px !important;
    box-shadow: (0px 0px 1px rgba(48, 49, 51, 0.05), 0px 16px 24px rgba(48, 49, 51, 0.1)) !important;
} 

.gm-style-iw-t::after {
    display: none;
}

.gm-ui-hover-effect {
    top: 0 !important;
    right: 0 !important;
}

.areaMap__place-details > div:nth-child(1) {
    @include typo(caption, bold, 1);
}
.areaMap__place-details > div:nth-child(2) {
    color: $fg__subtle;

    @include typo(x_small, bold, 1);
}

.areaMap__project-details {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    bottom: 56px;
    left: 24px;
    padding: 8px 16px;
    background: $accent__default;
    z-index: 1;

    @include shadow(60);
    @include bd-radius(large);

    svg {
        width: 24px;
        height: 24px;
    }

    path {
        fill: $fg__on-accent;
    }

    .areaMap__project-details-name {
        color: $fg__on-accent;
        
        @include typo(small, bold, 1);
    }

    .areaMap__project-details-address {
        color: $fg__on-accent;
        padding-bottom: 4px;
        
        @include typo(caption, bold, 1);
    }
}