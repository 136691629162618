@import "../../../scss/main.scss";

.warningMessage__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 36px;
    background-color: $bg__surface;
    @include bd-radius(medium);

    svg {
        align-self: center;
        width: 64px;
        height: 64px;

        path {
            fill: $warning__emphasis;
        }
    }

    .warningMessage__text-container {
        .warningMessage__title {
            @include typo(body, bold, 1);
        }
        .warningMessage__info-text {
            @include typo(body, bold, 1);
            text-align: center;
            color: $fg__on-disabled;
        }
    }
    .warningMessage__button-container {
        display: flex;
        justify-content: center;
        gap: 16px;
    }
}