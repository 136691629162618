@import "../../../../../../scss/main.scss";

.uploadFloorPlans__container { 
    width: 100%;
    height: 100%;
    display: flex;

    .uploadFloorPlans__split-container {
        display: flex;
        width: 100%;
        height: 100%;
        gap: 32px;

        .uploadFloorPlans__upload-container {
            display: flex;
            flex-direction: column;
            padding: 24px;
            width: 65%;
            height: 100%;
            @include bd-radius(medium);
            @include shadow(60);
            overflow: hidden;
            position: relative;
            background-color: $bg__surface;
            gap: 48px;
        }
        .uploadFloorsPlans__floors-container {
            display: flex;
            flex-direction: column;
            width: 35%;
            height: 100%;
            background-color: $bg__surface;
            padding: 24px;
            gap: 24px;
            border: 1px solid transparent;
            @include bd-radius(medium);
            @include shadow(60);

            &.--error{
                border-color: $error__muted;
            }

            .uploadFloorPlans__button-error {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                
                .uploadFloorPlans__error {
                    color: $error__muted;
                }
            }

            .uploadFloorsPlans__floors-guide-container {
                display: flex;
                flex-direction: column;

                .uploadFloorsPlans__floors-guide-title {
                    @include typo(body, bold, 1);
                }

                .uploadFloorsPlans__floors-guide-info {
                    @include typo(caption, regular, 2);
                }
            }
            .uploadFloorsPlans__floors-items-container {
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                width: 100%;
                height: 100%;
                gap: 32px;
                padding-right: 4px;
            }

            .uploadFloorsPlans__options-container {
                display: flex;
                flex-direction: column;
                gap: 32px;
                margin-top: auto;
                
                .uploadFloorsPlans__divider {
                    height: 1px;
                    background-color: $bg__muted;
                    margin: 0px -24px;
                }
                .uploadFloorsPlans__button-options {
                    display: flex;
                    gap: 24px;
                    justify-content: flex-end;
                }
            }
        }
    }
}

@media only screen and (max-width: 1482px) {
    .uploadFloorPlans__batch-container {
        width: 1200px;
    }
}

@media only screen and (max-width: 1250px) {
    .uploadFloorPlans__batch-container {
        width: 960px;
    }
}

@media only screen and (max-height: 830px) {
    .uploadFloorPlans__batch-container {
        height: 700px;
        .uploadFloorPlans__batch-split-container {
            padding: 12px 24px 8px 24px;
        }
    }
}