@import "../../../../../scss/main.scss";

.multi-select__modal {
    position: relative;
    background: #ffffff;
    padding: 48px 64px 0px;
    border-radius: 8px;
    height: calc(100vh - 64px);
    max-width: calc(100vw - 64px);
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    gap: 32px;

    .multi-select__modal-title {
        font-size: 24px;
        font-weight: 600;
    }

    .multi-select__lists-container {
        display: flex;
        gap: 16px;
        overflow-x: scroll;

        .multi-select__list-item {
            flex: 1 1 0px;
            display: flex;
            flex-direction: column;
            min-width: 256px;

            .multi-select__list-name {
                font-size: 18px;
                font-weight: 500;
                padding: 8px;
                margin-bottom: 12px;
                background: #F8FAFC;
                color: #3D59EC;
                border-radius: 4px;
            }

            .multi-select__list-wrapper {
                overflow-y: scroll;
                border: 1px solid #cccccc;
                flex: 1;

                .multi-select__list {
                    padding-top: 8px;

                    .multi-select__category {
                        background-color: #F4F4F4;
                        width: 100%;
                        font-size: 14px;
                        font-weight: 600;
                        padding: 4px 8px;
                        border-radius: 4px;

                        .multi-select__items-container {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 8px;
                            padding: 8px 0;
                        } 
                    }
                    .multi-select__item {
                        display: inline-block;
                        padding: 4px 8px;
                        cursor: pointer;
                        transition: 0.15s;
                        border-radius: 16px;
                        font-size: 14px;
                        margin: 4px;

                        &:hover {
                            background: #F3F3F3;
                        }

                        &.selected {
                            background: #3D59EC;
                            color: #ffffff;
                            text-shadow: 0 0 1px #ffffff;
                        }
                    }
                }
            }
        }
    }
    .multi-select__next-container {
        display: flex;
        border-top: 1px solid #e4e9ec;
        background: #ffffff;
        width: 100%;
        padding: 24px 0;
        grid-gap: 24px;
        justify-content: flex-end;
        /* position: sticky;
        bottom: 0px; */
    } 
}