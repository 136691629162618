@import "../../../scss/main.scss";

.copyFloor__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 48px;
    width: fit-content;
    padding: 24px 54px;
    background: $bg__surface;

    .copyFloor__inner-container {
        width: 100%;
        background: $accent__subtle;
        max-height: 200px;
        padding: 36px 64px;
        display: flex;
        gap: 56px;
        @include bd-radius(medium);

        .copyFloor__select-floor {

        }

        .copyFloor__icon-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 24px;

            .copyFloor__icon-text {
                @include typo(input_medium, regular, 2);
            }
        }

        .copyFloor__choose-text {
            @include typo(small, bold, 2);
        }

        .copyFloor__choose-floor {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 12px;
            z-index: 1;

            .copyFloor__floor-items-container {
                position: absolute;
                top: 36px;
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding: 16px;
                max-height: 300px;
                width: 100%;
                overflow: auto;
                background-color: $input__bg;
                @include bd-radius(medium);
                @include shadow(100);
                z-index: 5;
                
            }
        }
    }
    .copyFloor__buttons {
        display: flex;
        align-self: flex-end;
        gap: 16px;
    }
}