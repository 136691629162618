.nameTag__container {
    position: absolute;
    display: flex;
    padding: 4px 16px 4px 4px;
    background: #ffffff;
    box-shadow: 0 2px 7px rgb(0 0 0 / 14%);
    border-radius: 32px;
    white-space: nowrap;
    user-select: none;
    align-items: center;
    transform: translateX(-50%);

}
.nameTag__container.--client {
    left: 372px;
    top: 544px;
    animation: 3.5s float infinite;
}
.nameTag__icon {
    margin-right: 8px;
    height: 32px;
}

.nameTag__text {
    font-size: 16px;
    line-height: 16px;
    color: #333333;
}

@keyframes float {
    0% {
        transform: translate(-50%, -8px);
    }
    50% {
        transform: translate(-50%, -4px);
    }
    100% {
        transform: translate(-50%, -8px);
    }
}