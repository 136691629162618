@import "../../../scss/helpers/variables.scss";
@import "../../../scss/helpers/mixins.scss";

.vsoDashSidebar__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: $accent__emphasis;
    height: 100vh;
    padding: 32px 20px;
    z-index: 1000;
}

.vsoDashSidebar__item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.15s;
    padding: 8px;
    aspect-ratio: 1 / 1;
    
    @include bd-radius(large);
}

.vsoDashSidebar__project-image:hover,
.vsoDashSidebar__item.--active,
.vsoDashSidebar__item:hover {
    background-color: $accent__default;
}

.vsoDashSidebar__item::before {
    content: "";
    position: absolute;
    left: -20px;
    width: 0px;
    height: 100%;
    background: #ffffff;
    opacity: 0.5;
    transition: 0.15s;

    border-radius: 0 10px 10px 0;
}
.vsoDashSidebar__item.--active::before {
    width: 8px;
    opacity: 1;
}

.vsoDashSidebar__item.--edit-button {
    aspect-ratio: initial;
    color: $fg__on-accent;
    padding: 2px 8px;

    @include bd-radius(medium);
    @include typo(body, bold, 1);
}

.vsoDashSidebar__item-label {
    visibility: hidden;
    opacity: 0;
    transform: translateX(-16px);
    position: absolute;
    left: calc(100% + 8px);
    background: $fg__default;
    color: $fg__on-accent;
    padding: 8px 12px;
    white-space: nowrap;
    transition: visibility 1s 0s, opacity 0.15s, transform 0.15s;

    @include bd-radius(small);
    @include typo(caption, regular, 1);
}

.vsoDashSidebar__item:hover > .vsoDashSidebar__item-label {
    visibility: visible;
    opacity: 1;
    transform: translateX(0px);
    transition: visibility 0s, opacity 0.15s, transform 0.15s;
}

.vsoDashSidebar__project-image {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    object-fit: cover;
    padding: 4px;
    width: 57px;
    aspect-ratio: 1 / 1;
    cursor: pointer;
    border-radius: 100%;
}

.vsoDashSidebar__project-image > img {
    
}

.vsoDashSidebar__divider {
    width: 100%;
    height: 1px;
    background: $bg__muted;
}