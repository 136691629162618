@import "../../../scss/main.scss";

.officeImages__container {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
    
    .officeImages__image-topbar {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: fit-content;
        padding: 12px 24px;
        z-index: 1;
        justify-content: flex-end;
        background-color: rgba(47, 48, 50, 0.5);

        .officeImages__back-button {
            display: flex;
            align-items: center;
            gap: 12px;
            cursor: pointer;
            z-index: 2;
            border: 2px solid $bg__surface;
            color: $bg__surface;
            padding: 4px 16px;
            @include bd-radius(small);
            @include typo(button_medium, bold, 1);
            transition: 0.15s;

            svg {
                width: 10px;
                height: 12px;
            }
        }

        .officeImages__back-button:hover {
            transform: scale(1.05);
        }
    }

    .officeImages__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: rgb(0 0 0 / 70%);
        cursor: pointer;
    }
    
    .officeImages__button {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: fit-content;
        padding: 12px;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 1000px;
        cursor: pointer;
        z-index: 2;
        transition: 0.15s;

        &.--previous {
            left: 16px;
        }
        
        &.--next {
            right: 16px;
        }
    }
    
    .officeImages__button:hover {
        transform: translateY(-50%) scale(1.05);
    }
    
    .officeImages__images-container {
        position: relative;
        flex-grow: 1;
        width: 100%;
        height: 100%;
        padding: 16px;
        pointer-events: none;
    
        .officeImages__image {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 0;
            left: 50%;
            height: 100%;
            width: 100%;
            transform: translateX(-50%);
            opacity: 0;
            visibility: hidden;
            pointer-events: all;
            transition: 0s visibility;
        
            &.--current {
                transition: 0.15s opacity, 0s visibility;
                opacity: 1;
                visibility: visible;
            }
            
            img {
                max-height: 100%;
                max-width: 100%;
            }
            
        }
    }
    
    .officeImages__thumbnails-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        background: transparent;
        position: absolute;
        bottom: 36px;
        height: fit-content;
        width: 100%;
    
        .officeImages__image-title {
            color: $bg__surface;
            @include typo(headline_5, bold, 1);
        }
    
        .officeImages__vertical-dots {
            display: flex;
            flex-direction: row;
            gap: 16px;
            cursor: pointer;
    
            .officeImages__dots {
                height: 8px;
                width: 8px;
                border-radius: 50%;
                background-color: $bg__muted;
                transition: 0.15s;
            }
    
            .officeImages__dots.active {
                background-color: $accent__default;
            }
        }
    }
}

// .officeImages__close-button {
//     position: absolute;
//     text-align: center;
// }

// .officeImages__image-title {
//     position: relative;
//     padding: 8px 16px;
//     background: rgb(0 0 0 / 80%);
//     box-shadow: 0 2px 14px rgb(0 0 0 / 7%);
//     transform: translateY(calc(-50% + 16px));
//     text-align: center;
//     min-width: fit-content;
//     font-weight: 500;
//     color: #ffffff;
// }


// .officeImages__thumbnails-list {
//     display: flex;
//     gap: 16px;
//     margin: 16px 0;
// }

// .officeImages__thumbnail {
//     height: 64px;
//     width: 80px;
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     position: relative;
//     cursor: pointer;
// }

// .officeImages__thumbnail::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: #000000;
//     opacity: 0.4;
// }

// .officeImages__thumbnail:hover::before,
// .officeImages__thumbnail.--current::before {
//     opacity: 0;
//     transition: 0.15s;
// }


// .officeImages__thumbnail::after {
//     content: "";
//     position: absolute;
//     top: -2px;
//     left: -2px;
//     width: 100%;
//     height: 100%;
//     border: 2px solid #ffffff;
//     opacity: 0;
// }

// .officeImages__thumbnail.--current::after {
//     opacity: 1;
//     transition: 0.15s;
// }