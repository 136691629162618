@import "../../../../scss/main.scss";

.planView__container {
    width: 100%;
    height: 100%;
    padding: 40px 70px;
    overflow: auto;
    background: $bg__subtle;
    border-top: 1px solid $bg__muted;

    .planView__card-items {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 40px;
        justify-items: center;
    }
}

.planView__modal-view {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    @include bd-radius(medium);
    height: calc(100vh - 64px);
    
    .headerBanner__container {
        padding: 40px 48px 24px 48px;
    }

    .headerBanner__container > h4 {
        @include typo(headline_1, bold, 1);
    }
}

@media only screen and (max-width: 1630px) {
    .planView__container {
        .planView__card-items {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}