.pwd-constraints__main-container {
    position: absolute;
    left: calc(100% + 16px);
    top: 50%;
    transform: translateY(-50%);
    width: fit-content;
    box-shadow: 0 2px 14px -7px rgba(0,0,0,0.2), 0 1px 4px rgba(0,0,0,0.1);
    padding: 16px;
    border-radius: 8px;
    opacity: 1;
    transition: opacity 0.25s, visibility 0s;
    border: 1px solid #FF9401;
    background: #ffffff;
}
.pwd-constraints__main-container::before {
    content: "";
    position: absolute;
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
    width: 0; 
    height: 0; 
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent; 
    border-right:8px solid #FF9401; 
}
.pwd-constraints__main-container.--hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s, visibility 0s 1s;
}
.pwd-constraints__header {
    font-size: 14px;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 12px;
    padding-right: 8px;
}
.pwd-constraints__list-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 0 0 32px;
    margin: 12px 0 0 0;
}

.pwd-constraints__list-item {
    position: relative;
    font-size: 14px;
    list-style-type: none;
}
.pwd-constraints__list-item::before {
    content: "";
    left: -18.5px;
    top: calc(50% - 2.5px);
    position: absolute;
    width: 6px;
    height: 6px;
    background: rgba(255,148,1,1);
    border-radius: 100%;
}

.pwd-constraints__list-item.--faded {
    color: #929caa;
}
.pwd-constraints__list-item.--faded::before {
    background-color: #929caaaa;
}
.pwd-constraints__success {
    font-size: 14px;
    background: #d1e7dd;
    border-color: #479f76;
    padding: 8px;
    border-radius: 4px;
    margin-top: 12px;
}
.pwd-constraints__success.--hidden {
    display: none;
}