.reservationForm__container{
    width: 100vw;
    min-height: calc(100vh - 64px);
    z-index: 3;
    position: absolute;
    top: 64px;
    left: 0;
    background: #F3F4F6;
}

.reservationForm__steps-container {
    display: flex;
    background: #ffffff;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    width: 100%;
    margin-bottom: 32px;
    padding: 16px 16px 24px;
    justify-content: center;
    align-items: center;
}

.reservationForm__steps-list {
    position: relative;
    width: max(50vw, 400px);
    height: 56px;
}

.reservationForm__steps-line {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 1px;
    background-color: #D8D8D8;
    transition: 0.9s;
}

.reservationForm__steps-line.--active {
    background-color: var(--blue);
    height: 2px;
}

.reservationForm__steps-item {
    position: absolute;
    padding: 16px 32px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    transition: 0.15s;
    transform: translateX(-50%);
}

.reservationForm__step-circle {
    position: relative;
    width: 24px;
    height: 24px;
    background-color: var(--white);
    color: #666666;
    border-radius: 100%;
    border: 1px solid #D8D8D8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    transition: 0.15s;
}

.reservationForm__step-circle > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.15s;
}

.reserationForm__step-text {
    position: absolute;
    color: #666666;
    font-size: 14px;
    bottom: -4px;
    width: fit-content;
    white-space: nowrap;
}

.reservationForm__steps-item svg {
    height: 16px
}
.reservationForm__steps-item.--active > .reservationForm__step-circle {
    color: var(--white);
    background-color: var(--blue);
    border: 1px solid var(--blue);
}
.reservationForm__steps-item.--active > .reserationForm__step-text {
    color: var(--blue);
    font-weight: 500;
}
.reservationForm__steps-item svg > path {
    fill: #36c775;
}
.reservationForm__steps-item.--active svg > path{
    fill: #ffffff;
}

.reservationForm__step-title {
    font-size: 24px;
    margin-bottom: 32px;
    font-weight: 600;
}

.reservationForm__button-container {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 32px;
}

.reservationForm__step-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.codeVerification__input-container {
    /* padding-bottom: 16px; */
}

.codeVerification__error {
    color: #D93025;
}

.codeVerification__success {
    margin-bottom: 32px;
}

.reservationForm__dual-container {
    display: flex;
    gap: 16px;
    align-items: flex-start;
    padding: 0 32px;
}

.identityForm__column {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 640px;
    margin-bottom: 32px;
}

.codeVerification__container,
.identityForm__container {
    background: #ffffff;
    padding: 32px;
    border-radius: 5px;
    border: 1px solid #d8d8d8;
}

.identityForm__billing-info-container {
    border: 1px solid #eaeaea;
    background: #fafafa;
    border-radius: 4px;
    padding: 8px 16px;
    margin-bottom: 32px;
}

.identityForm__billing-info-container > legend {
    padding: 8px;
    color: #565656;
    font-weight: 500;
}

.identityForm__billing-info-container input {
    margin-bottom: 16px !important;
}

.unitSelection__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    max-width: min(100vw, 1280px);
    background: #ffffff;
    padding: 48px;
    border-radius: 8px;
}

.unitSelection__logo {
    height: 64px;
    margin-bottom: 32px;
}

.unitSelection__title {
    font-size: 24px;
    margin-bottom: 32px;
    font-weight: 600;
}

.unitSelection__table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    width: 100%;
}

.identityForm__title {
    font-size: 24px;
    margin-bottom: 32px;
    font-weight: 600;
}

.identityForm__subtitle {
    font-weight: 600;
    color: var(--blue);
    margin-bottom: 8px;
}

.identityForm__small-text {
    font-size: 12px;
    color: #6B7886;
    line-height: 1.5;
}

.identityForm__paragraph {
    font-size: 14px;
    margin-bottom: 24px;
    color: #333333;
    font-weight: 300;
    line-height: 1.4;
}

.identityForm__small-text > span {
    color: var(--blue);
}

.identityForm__row-half {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    align-items: center;
}

.codeVerification__row {
    display: flex;
    align-items: center;
    gap: 16px;
}

.depositInfo__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    max-width: min(100vw, 1280px);
    padding: 48px;
    border-radius: 8px;
}

.depositInfo__jumbotron {
    display: flex;
    max-width: 960px;
    border: 2px solid #3D59EC;
    background:#3D59EC;
    border-radius: 8px;
    margin: 16px 0 64px;
}

.depositInfo__left-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    flex-direction: column;
    padding: 32px 64px;
    line-height: 1.5;
    font-weight: 500;
}

.depositInfo__right-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 32px 64px;
    background: #ffffff;
    border-radius: 6px;
}

.depositInfo__price {
    font-size: 72px;
    font-weight: 600;
    position: relative;
}

.depositInfo__price > span {
    color: #4AD184;
}

.depositInfo__price-taxes {
    position: absolute;
    top: 0;
    left: 100%;
    font-size: 10px;
}

.depositInfo__subtitle {
    font-weight: 600;
    color: #777777;
}

.depositInfo__grid {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 64px;
    align-items: center;
    width: 100%;
}

.depositInfo__grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 272px;
}
.depositInfo__grid-item > div {
    text-align: center;
    line-height: 1.5;
}

.depositInfo__info-title {
    font-weight: 600;
    margin-bottom: 16px;
}

.depositInfo__info-desc > span {
    font-weight: 600;
}

.payment__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    padding: 48px;
    border-radius: 8px;
    max-width: min(100vw, 1040px);
}

.payment__subtitle {
    color: #A6A9B7;
    font-weight: 600;
}

.payment__inner-container {
    display: grid;
    width: calc(100% - 64px);
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    padding: 48px 0;
}

.payment__left-panel {
    padding: 24px 16px;
    border-radius: 4px;
    background-color: #fafafa;
}

.payment__title {
    font-weight: 600;
    margin-bottom: 16px;
}

.payment__card-row {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 32px;  
}

.payment__address-row {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 32px;  
}

.payment__right-panel {
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment__blue-card {
    background: #3D59EC;
    padding: 32px 40px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 420px;
}

.payment__price-title {
    color: #ffffff;
}

.payment__price {
    position: relative;
    font-size: 72px;
    font-weight: 600;
    color: #ffffff;
    width: fit-content;
}

.payment__price > span {
    color: #4AD184;
}

.payment__price-taxes {
    position: absolute;
    top: 0;
    left: 100%;
    font-size: 10px;
    color: #ffffff;
    white-space: nowrap;
    font-weight: 400;
}

.payment__paragraph {
    color: #ffffff;
    text-align: center;
}

.payment__box {
    padding: 16px;
    color: #ffffff;
    background-color: #7085F2;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.payment__error-message {
    color: #df1b41;
    padding-top: 32px;
}

.reservationConfirmation__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    background: #ffffff;
    padding: 56px;
    border-radius: 8px;
    border: 1px solid #d8d8d8;
}

.reservationConfirmation__blue-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #3D59EC;
    padding: 32px 64px;
    gap: 32px;
    /* width: fit-content; */
    border-radius: 8px;
}

.reservationConfirmation__checkmark {
    width: 96px;
    margin: 16px 0;
}

.reservationConfirmation__title {
    font-size: 48px;
    font-weight: 600;
    color: var(--blue);
}

.reservationConfirmation__subtitle {
    font-size: 20px;
    font-weight: 600;
}

.reservationConfirmation__paragraph--white {
    color: #ffffff;
}

.reservationConfirmation__split-container {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px; 
    flex-grow: 1;
}

.reservationConfirmation__paragraph {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    flex-direction: column;
    max-width: 600px;
    line-height: 1.5;
}

.reservationConfirmation__paragraph > div > span {
    text-decoration: underline;
    font-weight: 500;
}

.reservationConfirmation__contact-info {
    display: flex;
    justify-content: center;
    align-items: center;
}

.reservationConfirmation__button-container { 
    display: flex;
    gap: 36px;
}