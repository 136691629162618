@mixin typo($name, $weight, $family) {

    $font-size-map: (
        colossus: 140px,
        uber: 112px,
        hero: 80px,
        display: 64px,
        headline_1: 48px,
        headline_2: 37px,
        headline_3: 31px,
        headline_4: 26px,
        headline_5: 21px,
        lead: 24px,
        body: 18px,
        small: 16px,
        caption: 14px,
        x_small: 12px,
        tiny: 10px,
        input_medium: 18px,
        input_small: 16px,
        button_large: 21px,
        button_medium: 16px,
        button_small: 14px,
    );

    $line-height-map: (
        colossus: 144px,
        uber: 120px,
        hero: 96px,
        display: 80px,
        headline_1: 60px,
        headline_2: 52px,
        headline_3: 48px,
        headline_4: 40px,
        headline_5: 32px,
        lead: 36px,
        body: 32px,
        small: 28px,
        caption: 20px,
        x_small: 20px,
        tiny: 16px,
        input_medium: 28px,
        input_small: 24px,
        button_large: 32px,
        button_medium: 28px,
        button_small: 20px,
    );

    $font-weight-map: (
        bold: 700,
        regular: 400,
    );

    $font-family-map: (
        1: ('Inter', sans-serif),
        2: ('Oxygen', sans-serif)
    );

    @each $key, $value in $font-size-map {
        @if($key == $name) {
            font-size: map-get($font-size-map, $key);
        }
    }

    @each $key, $value in $line-height-map {
        @if($key == $name) {
            line-height: map-get($line-height-map, $key);
        }
    }

    @each $key, $value in $font-weight-map {
        @if($key == $weight) {
            font-weight: map-get($font-weight-map, $key);
        }
    }

    @each $key, $value in $font-family-map {
        @if($key == $family) {
            font-family: map-get($font-family-map, $key);
        }
    }
}

@mixin bd-radius($name) {
    $border-radius-map: (
        small: 4px,
        medium: 6px,
        large: 10px
    );

    @each $key, $value in $border-radius-map {
        @if($key == $name) {
            border-radius: map-get($border-radius-map, $key);
        }
    }
}

@mixin shadow($shadow-value) {
    $box-shadow-map: (
        20: (0px 0px 1px rgba(48, 49, 51, 0.05), 0px 1px 3px rgba(48, 49, 51, 0.1)),
        40: (0px 0px 1px rgba(48, 49, 51, 0.05), 0px 2px 4px rgba(48, 49, 51, 0.1)),
        60: (0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1)),
        80: (0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1)),
        100: (0px 0px 1px rgba(48, 49, 51, 0.05), 0px 16px 24px rgba(48, 49, 51, 0.1))
    );

    @each $key, $value in $box-shadow-map {
        @if($key == $shadow-value) {
            box-shadow: map-get($box-shadow-map, $key);
        }
    }
}