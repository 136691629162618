@import "../../../../../scss/main.scss";

.editColumns__container {
    background: $bg__surface;
    min-width: 320px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 12px 0 16px;
    border: 1px solid $accent__subtle;

    @include bd-radius(medium);
    @include shadow(80);
}

.editColumns__header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0 16px;
    width: 100%;
}

.editColumns__title {
    @include typo(small, bold, 1);
}

.editColumns__subtitle {
    @include typo(caption, regular, 2);
    color: $fg__muted;
}

.editColumns__divider { 
    border-bottom: 1px solid $bg__muted;
    width: 100%;
}

.editColumns__list { 
    display: flex;
    flex-direction: column;
    padding: 0 4px;
    width: 100%;
}

.editColumns__item { 
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    transition: 0.15s;

    @include bd-radius(small);
}

.editColumns__item:hover { 
    background-color: #ECECEC;
    cursor: pointer;
}

.editColumns__item-name-container {
    display: flex;
    gap: 8px;
    align-items: center;

    div {
        text-transform: capitalize;
        @include typo(caption, regular, 1);
    }

    svg {
        height: 16px;
        width: 16px;
    }

    .editColumns__item-custom-tag {
        display: inline-block;
        background-color: $accent__subtle;
        color: $accent__emphasis;
        padding: 0 8px;
        
        @include bd-radius(large);
        @include typo(x_small, bold, 1);
    }
}


.editColumns__add-column-button { 
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    border: 1px solid #CBCBCB;
    padding: 12px 96px;
    border-radius: 5px;
    margin-top: 16px;
    cursor: pointer;
}

.editColumns__add-column-text {
    font-size: 14px;
    font-weight: 600;
    color: var(--black);
}

.editColumns__remove-and-toggle-container {
    display: flex;
    gap: 16px;
    align-items: center;
}

.editColumns__remove-column {
    display: flex;
    align-items: center;
}

.editColumns__remove-column > svg > path:nth-child(1) {
    fill: var(--white);
}

.editColumns__remove-column > svg > path:nth-child(2) {
    fill: rgb(199, 199, 192);
}