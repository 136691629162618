@import "../../../scss/helpers/variables.scss";
@import "../../../scss/helpers/mixins.scss";


.headerBanner__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    gap: 4px;
    padding: 24px;
    position: relative;
    background-color: $accent__default;
    @include shadow(60);

    .headerBanner__close-button {
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
    }

    h4 {
        @include typo(headline_4, bold, 1);
        color: $fg__on-accent;
        margin: 0px;
    }

    p {
        @include typo(caption, regular, 2);
        color: $fg__on-accent;
        margin: 0px;
    }

    &.--form {
        background-color: $accent__emphasis;
    }
}