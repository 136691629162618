@import "../../../scss/main.scss";

.stepsBanner__container {
    display: flex;
    height: fit-content;
    justify-content: center;
    align-items: center;
    gap: 32px;
    background: $bg__canvas;
    @include shadow(40);
    padding: 16px 32px;
    z-index: 1;

    .button {
        align-self: flex-end;
    }

    @media only screen and (max-width: 1300px)  {
        justify-content: space-between;
        gap: 8px;
        padding: 16px;
    }
}

