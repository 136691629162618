@import "../../../scss/helpers/variables.scss";
@import "../../../scss/helpers/mixins.scss";

.unitsPlans__container {
    width: 100vw;
    height: calc(100vh - 64px); 
    background: $bg__surface;
    position: fixed;
    top: 64px;
    left: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .unitsPlans__header-container { 
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 40px 40px 0px 40px;

        .unitsPlans__availability-and-buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .unitsPlans__units-available {
                @include typo(lead, bold, 1);
            }

            .unitsPlans__units-available > strong {
                @include typo(lead, bold, 1);
                color: $accent__default;
            }

            .unitsPlans__view-buttons {
                display: flex;
                gap: 16px;
    
                button > svg {
                    fill: $bg__canvas;
                }
    
                button.--active > svg > path {
                    fill: $bg__surface;
                }
            }
        }
        
        .unitsPlans__info-text {
            color: $fg__muted;
            @include typo(caption, regular, 2);
        }
    }

    .unitsPlans__filterTable-wrapper {
        padding-left: 40px;
    }
}

// .unitsPlans__units-available {
//     font-weight: 600;
//     font-size: 24px;
//     align-self: center;
// }

// .unitsPlans__units-available > span {
//     color: #3D59EC;
// }

// .unitsPlans__header-container { 
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     margin-bottom: 32px;
// }

// .unitPlans__view-mode-container { 
//     display: flex;
//     gap: 36px
// }

// .unitPlans__building-view,
// .unitPlans__plan-view,
// .unitPlans__unit-view {
//     display: flex;
//     align-items: center;
//     gap: 12px;
//     background-color: #F4F4F4;
//     border-radius: 4px;
//     padding: 0 16px;
//     cursor: pointer;
//     transition: 0.15s;
// }

// .unitPlans__building-view:hover,
// .unitPlans__plan-view:hover,
// .unitPlans__unit-view:hover { 
//     background-color: #3D59EC;
// }

// .unitPlans__building-view:hover > div,
// .unitPlans__plan-view:hover > div,
// .unitPlans__unit-view:hover > div { 
//     color: white;
// }

// .unitPlans__building-view:hover > svg > path,
// .unitPlans__plan-view:hover > svg > path,
// .unitPlans__unit-view:hover > svg > path { 
//     fill: white;
// }

// @media only screen and (max-width: 1482px) { 
//     .unitPlans__unit-view,
//     .unitPlans__plan-view {
//         font-size: 14px;
//     }
    
//     .unitsPlans__container {
//         padding: 32px 64px 32px 64px;
//     }
// }