@import "../../../../scss/main.scss";

.fileInput__container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .fileInput__label-caption-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .fileInput__label-info-container {
            display: flex;
            align-items: center;
            gap: 12px;

            label {
                @include typo(small, bold, 2);
    
                span {
                    @include typo(small, bold, 1);
                    color: $accent__default;
                }
            }
        }


        .fileInput__caption {
            @include typo(caption, regular, 2);
            max-width: 500px;
        }
    }

    .fileInput__preview-upload-container {
        width: 100%;
        display: flex;
        gap: 24px;

        &.--vertical {
            flex-direction: column;
        }

        &.--horizontal {
            flex-direction: row;
        }

        .fileInput__single-upload-preview {
            display: none;

            &.--active {
                width: 240px;
                height: 100%;
                border: 1px solid $border__default;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
            }

            img {
                width: 100%;
            }

            .fileInput__upload-delete {
                display: flex;
                height: fit-content;
                width: fit-content;
                position: absolute;
                background: $bg__surface;
                bottom: 4px;
                right: 4px;
                padding: 4px;
                cursor: pointer;
                @include bd-radius(medium);
                @include shadow(60);

            }

        }

        .fileInput__multiple-upload {
            display: none;

            &.--active {
                display: flex;
                width: 100%;
            }

            .fileInput__drag-files-container {
                display: grid;
                width: 100%;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 8px;
            }
        }

        .fileInput__files-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 24px;
            gap: 12px;
            background: $bg__canvas;
            border: 1px dashed $bg__muted;
            width: 100%;
            position: relative;

            &.--fileError {
                background-color: $error__subtle;

                svg {
                    width: 23px;
                    height: 20px;
                    
                    path {
                        fill: $error__emphasis;
                    }
                }
            }

            &.--valid {
                border-color: $success__muted;
            }

            &.--denied {
                border-color: $error__muted;
            }

            .fileInput__upload-text {
                @include typo(caption, bold, 2);
            }

            .fileInput__error {
                position: absolute;
                bottom: 4px;
                right: 8px;
                @include typo(caption, regular, 1);
                color: $error__muted;
            }
        }
    }

    .fileInput__description {
        @include typo(caption, regular, 2);
        color: $fg__muted;
    }

}

@media only screen and (max-width: 1250px) {
    .fileInput__container {
        .fileInput__preview-upload-container {
            .fileInput__multiple-upload {
                .fileInput__drag-files-container {
                    grid-template-columns: 1fr 1fr;
                }
            }
        }
    }
}