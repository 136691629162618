@import "../../../scss/main.scss";

.planModal__backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $overlay__bg;
    z-index: 10000;

    .planModal__backdrop-close {
        position: absolute;
        right: 24px;
        top: 24px;
        cursor: pointer;

        svg {
            path {
                fill: $bg__surface;
            }
        }
    }
}