@import "../../../scss/helpers/variables.scss";
@import "../../../scss/helpers/mixins.scss";

.rangeSlider__container {
    width: 100%;
    padding: 36px 0px;

    .rangeSlider__range-track-container {
        height: 32px;
        display: flex;
        flex-grow: 1;
        padding-right: 32px;
    }

    .rangeSlider__range-track {
        height: 8px;
        width: 100%;
        @include bd-radius(small);
        align-self: center;
    }

    .rangeSlider__thumb {
        width: 16px;
        height: 16px;
        padding: 8px;
        border-radius: 50%;
        background: $accent__emphasis;
        display: flex;
        justify-content: center;
        outline: none;
        transform: translate(-50%, 0);
    }

    .rangeSlider__limits-label {
        display: flex;
        justify-content: space-between;
        padding: 0px 24px 0px 0px;
        
        .rangeSlider__limits {
            @include typo(caption, bold, 2);
        }
    }
}
