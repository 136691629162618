@import "../../../../scss/main.scss";

.vsoDashUnits__container {
    display: flex;
    height: calc(100vh - 64px);
    flex-direction: column;
}

.vsoDashUnits__header-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px 48px 24px;
    background-color: $bg__canvas;
}

.vsoDashUnits__header-inner-container {
    display: flex;
    justify-content: space-between;
}

.vsoDashUnits__header {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.vsoDashUnits__header > div {
    @include typo(headline_3, bold, 1);
}

.vsoDashUnits__header > p {
    margin: 0;
    color: $fg__muted;
    @include typo(small, regular, 1);
}

.vsoDashUnits__view-buttons-container {
    display: flex;
    gap: 16px;

    button > svg {
        fill: $bg__canvas;
    }

    button.--active > svg > path {
        fill: $bg__surface;
    }
}

.vsoDashUnits__buttons-container {
    display: flex;
    justify-content: space-between;
}

.vsoDashUnits__buttons-left-container {
    display: flex;
    gap: 16px;

    button > svg {
        fill: $bg__canvas;
    }

    button.--active > svg > path {
        fill: $bg__surface;
    }
}

.vsoDashUnits__buttons-right-container {
    display: flex;
    align-items: flex-end;
    gap: 16px;

    button > svg {
        fill: $bg__canvas;
    }

    button.--active > svg > path {
        fill: $bg__surface;
    }
}

.vsoDashUnits__save-status {
    color: $fg__subtle;
    opacity: 0;
    transition: 0.9s 1.5s;
    
    @include typo(small, regular, 1);
}

.vsoDashUnits__save-status.--shown {
    opacity: 1;
    transition: 0s;
}