.--delete { 
    border: 1px solid #F52C00;
    color:#F52C00;
}
.--delete:hover {
    background-color: #F52C00;
    color: #ffffff;
}
.--pause { 
    border: 1px solid #F7C25B;
    color:#F7C25B; 
}
.--pause:hover { 
    background-color: #F7C25B;
    color: #ffffff;
}
.--activate {
    border: 1px solid #53A204;
    color:#53A204; 
}
.--activate:hover { 
    background-color: #53A204;
    color: #ffffff;
}

.vso__list-item {
    width: 100%;
    background: #ffffff;
    border-radius: 8px;
    padding: 24px 32px;
    margin-bottom: 42px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    overflow: hidden;
    padding-left: calc(28% + 24px);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
    transition: 0.15s;
}

.vso__item-image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 25%;
    background-position: center;
    background-size: cover;
    background-color: #ddd;
    border-radius: 8px;

}
.vso__item-header {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.vso__item-header > h2 {
    font-size: 24px;
    margin: 0;
}

.vso__item-header > p {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    color: rgba(102, 102, 102, 1);
}

.vso__item-options { 
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: #ffffff;
    transition: 0.15s;
}

.vso__item-options:hover {
    background-color: #eeeeee;
}

.vso__item-options > svg {
    width: 32px;
    height: 32px;
}

.vso__item-options-container { 
    display: flex;
    flex-direction: column;
    gap: 16px
}

.vso__item-options-items { 
    text-align: center;
    padding: 12px 24px;
    border-radius: 8px;
    transition: 0.15s;
    cursor: pointer;
}

.vso__item-status-last-update-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.vso__item-status-container { 
    display: flex;
    gap: 16px;
}

.vso__item-status-container > p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.vso__item-status-last-update-container > p { 
    margin: 0;
    font-size: 12px;
}

.vso__item-button-upon-status { 
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    gap: 16px;
    padding: 12px 24px;
    background-color: #3D59EC;
    border-radius: 8px;
    text-decoration: none;
    cursor: pointer;
}
.vso__item-button-upon-status > p {
    margin: 0;
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.vso__item-status-type { 
    display: flex;
    align-items: center;
    gap: 8px;
}

.vso__item-status-type > p, 
.vso__item-status-type > .status-text {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: rgba(136, 136, 136, 1);
}

.circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
}

.circle.active {
    background: #53A204;
}

.circle.inactive {
    background: #F7C25B;
}

.status-text.active {
    color: #53A204;
}

.status-text.inactive {
    color: #F7C25B;
}

.vso__item-modal { 
    display: flex;
    flex-direction: column;
    gap: 32px;
    background-color: white;
    padding: 32px 48px;
    border-radius: 8px;
}

.vso__item-modal-text { 
    font-size: 18px;
}

.vso__item-modal-text > strong { 
    color: #3D59EC;
    font-weight: 800;
    text-decoration: underline;
    font-size: 16px;
}

.vso__item-modal-button-container {
    display: flex;
    justify-content: center;
    gap: 32px;
}

.vso__item-modal-button {
    padding: 8px 16px;
    border: 1px solid #3D59EC;
    border-radius: 8px;
    color: #3D59EC;
    cursor: pointer;
    transition: 0.15s;
}

.vso__item-modal-button:hover {
    background-color: #3D59EC;
    color: white;
}