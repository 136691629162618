.mt-form__split-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    background: #fff;
    margin-top: 64px;
    width: 100%;
}
.mt-form__step-container {
    overflow-y: auto;
    padding: 40px 56px 0;
    position: relative;
}
.mt-form__step__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
}
.mt-form__step__title > h1 {

}
.mt-form__tabs-container {
    display: flex;
    grid-gap: 32px;
    margin-bottom: 56px;
}
.mt-form__tabs-item {
    position: relative;
    min-width: 12%;
    padding-bottom: 16px;
    color: #666666;
}
.mt-form__tabs-item::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #D4D4D4;
    transition: 0.15s;
}
.mt-form__tabs-item.active {
    color: #333333;
}
.mt-form__tabs-item.active::after {
    background-color: #FF7600;
}
.mt-form__save-button {
    padding: 8px 16px;
    background-color: #fcedd8;
    color: #FF7600;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.15s;
}
.mt-form__save-button:hover {
    background-color: #ffe6c3;
}
.mt-form__progress-box {
    padding: 24px 32px;
    background: #ffffff;
    border-radius: 8px 8px 0 0;
    height: 100%;
    text-align: center;
    overflow: hidden;
    position: relative;
    margin-top: 16px;
    min-width: 320px;
    box-shadow: 0 2px 14px -7px rgb(0 0 0 / 20%);
}
.mt-form__progress-box::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #FE9D0D;
}
.mt-form__progress__steps {
    display: block;
    text-align: left;
    padding: 16px;
    margin: 0 auto;
    width: fit-content;
}
.mt-form__progress__step {
    font-weight: 300;
    padding: 8px;
    color: #777777;
}
.mt-form__progress__step.done,
.mt-form__progress__step.current {
    font-weight: 600;
    position: relative;
    color: #333333;
}
.mt-form__progress__step.done::before {
    content: "";
    position: absolute;
    left: -16px;
    width: 12px;
    height: 12px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #73CA74;
    border-radius: 100%;
}
.mt-form__progress__step.current::before {
    content: "";
    position: absolute;
    left: -16px;
    top: 50%;
    transform: translateY(-50%);
    width: 0; 
    height: 0; 
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid #FE9D0D;
}
.mt-form__mandatory-container,
.mt-form__mandatory-container--fluid {
    display: flex;
    margin: 32px 0;
    justify-content: center;
    align-items: center;
    width: fit-content;
    font-size: 14px;
}
.mt-form__mandatory-container--fluid {
    width: 100%;
}
.mt-form__mandatory-text {
    color: #dd2900;
}
.mt-form__mandatory-text.hidden {
    visibility: hidden;
}
.mt-form__next-container {
    display: flex;
    border-top: 1px solid #e4e9ec;
    background :#ffffff;
    width: 100%;
    padding: 24px 0;
    margin-top: 32px;
    grid-gap: 24px;
    justify-content: flex-end;
    position: sticky;
    bottom: 0px;
}
.mt-form__back-button {
    display: block;
    padding: 12px 32px;
    background: #ffffff;
    border: 1px solid #C1C1C1;
    font-weight: 600;
    border-radius: 10px;
    color: #333333;
    cursor: pointer;
    transition: 0.15s;
}
.mt-form__back-button:hover {
    background: #f9f9f9;
}
.mt-form__next-button {
    display: block;
    padding: 12px 48px 12px 16px;
    cursor: pointer;
    background: #FE9D0D;
    color: #ffffff;
    font-weight: 600;
    border: 1px solid #FE9D0D;
    border-radius: 10px;
    position: relative;
    transition: 0.15s;
}
.mt-form__next-button::after {
    content: ">";
    position: absolute;
    font-weight: 600;
    font-size: 24px;
    margin-left: 24px;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);   
}
.mt-form__next-button:hover {
    background: #FF7600;
    border: 1px solid #FF7600;
}
.mt-form__row-half {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
}
.mt-form__row-third {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px;
}
.mt-form__row-units {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 2fr 1fr;
    grid-gap: 32px;
}



.mt-form__rent-sell-container {
    display: flex;
    grid-gap: 32px;
}
.mt-form__rent-sell-item {
    width: 100%;
    max-width: 360px;
    text-align: center;
    background-color: #ffffff;
    padding: 32px;
    border-radius: 16px;
    border: 1px solid #DDDDDD;
    cursor: pointer;
    transition: 0.15s;
}
.mt-form__rent-sell-item:hover {
    border: 1px solid #FE9D0D;
}
.mt-form__rent-sell-item.selected {
    border: 1px solid #FE9D0D;
    box-shadow: 0 2px 32px -14px #fe9d0d;
}
.mt-form__rent-sell-item > h2 {
    margin-bottom: 0;
}

.mt-form__address-map-container {
    border-radius: 8px;
}

.mt-form__substep-container {
    display: none;
}
.mt-form__substep-container.active {
    display: block;
}
.mt-form__buildings-floors-container {
    background: #FCFCFC;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    padding: 24px 32px;
    width: fit-content;
}
.mt-form__details-more-container {
    position: relative;
    margin-top: 32px;
    display: flex;
    width: fit-content;
    padding: 32px;
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    background-color: #FCFCFC;
}

.mt-form__details-more-container > .form__input-group {
    margin-right: 16px;
}

.mt-form__details-more-container select {
    margin-bottom: 0;
}

.mt-form__details-more-container > .v-separator {
    height: 80px;
    margin: 0 32px 0 16px;
}

.mt-form__details-logo-container {
    display: flex;
    padding-top: 32px;
    grid-gap: 32px;
}
#mt-form__logo-input {
    visibility: hidden;
    width: 0;
}
.mt-form__details-logo-preview {
    width: 240px;
    height: 240px;
    border: 1px solid #DDDDDD;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 8px;
    padding: 16px
}
.mt-form__details-logo-preview > img {
    width: 100%;
}
.mt-form__details-logo-preview-name {
    display: flex;
    justify-content: space-between;
    position:absolute;
    bottom: 0;
    width: 100%;
    background: #ffffff;
    text-align: center;
    font-size: 14px;
    padding: 4px;
    border-top: 1px solid #dddddd;
}
.mt-form__details-logo-preview-name > span {
    font-size: 14px;
}
.mt-form__details-logo-preview-delete {
    color: #F4A208;
    font-weight: 600;
    cursor: pointer;
}
.mt-form__details-logo-upload-button {
    display: inline-block;
    position: relative;
    padding: 16px 24px;
    line-height: 1.5;
    font-size: 0.95rem;
    cursor: pointer;
    border: none;
    border-radius: 64px;
    color: #ffffff;
    outline: none;
    transition: all 0.15s;
    font-weight: 500;
    overflow: hidden;
    text-align: center;
    background: #FF7600;
    margin-top: 24px;
}
.mt-form__details-logo-upload-text {
    margin-top: 32px;
    color: #A6A9B7;
    line-height: 2;
    font-weight: 300;
}
.mt-form__unit-counter-container{
    position: absolute;
    top: 0;
    right: 24px;
    display: flex;
}
.mt-form__total-units.error{
    color: #d93025;
}
.mt-form__unit-details-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
    background: #FCFCFC;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    padding: 24px 32px;
    width: 100%;
    grid-gap: 16px;
    margin: 16px 0 32px;
}
.mt-form__unit-details-item {
    text-align: center;
    padding: 16px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.15s;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mt-form__unit-details-item:hover {
    background: #fffdfa;
    border: 1px solid #FE9D0D;
}
.mt-form__unit-details-item.active {
    background:#F4A208;
    border: 1px solid #F4A208;
    color: #ffffff;
    font-weight: 600;
}
.mt-form__unit-details-item > span {
    color: #9698a1;
}
.mt-form__unit-details-item.active > span {
    color: #ffffff;
}
.mt-form__unit-types-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 32px;
    margin: 16px 0 32px;
}
.mt-form__unit-types-item {
    display: none;
    padding: 8px 16px;
    color: #9698a1;
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 8px;
}
.mt-form__unit-types-item.active {
    display: block;
}

.mt-form__parking-available-container {
    display: flex;
    width: 100%;
    grid-gap: 64px;
    flex-wrap: wrap;
    margin-bottom: 32px;
}

.mt-form__desc-tabs-container {
    border-radius: 16px;
    border: 1px solid #DCDCDC;
    background: #F9F9F9;
    color: #949494;
    display: flex;
    grid-gap: 32px;
    padding: 0 24px;
    width: fit-content;
    margin-bottom: 32px;
}
.mt-form__desc-tabs-item {
    color: #949494;
    cursor: pointer;
    padding: 16px;
    position: relative;
}
.mt-form__desc-tabs-item.active {
    color: #666666;
}
.mt-form__desc-tabs-item::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    width: 0px;
    height: 4px;
    background: #fe9d0d;
    transition: 0.15s;
}
.mt-form__desc-tabs-item.active::after {
    width: calc(100% - 16px);
}

.mt-form__desc-tab-pane {
    display: none;
}
.mt-form__desc-tab-pane.current {
    display: block;
}

.mt-form__listing-container {
    position: relative;
    max-width: 100%;
}
.mt-form__img-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 400px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #fcfcfc;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23EEEEEEFF' strokeWidth='10' stroke-dasharray='20%2c 20' stroke-dashoffset='0' strokeLinecap='round'/%3e%3c/svg%3e");
    border-radius: 10px;
    cursor: pointer;
    transition: 0.15s;
    position: relative;
    overflow: hidden;
}
.mt-form__img-container:hover {
    background-color: #fafafa;
}
.mt-form__images-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
}
.mt-form__images-preview-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000077;
    opacity: 0;
    transition: 0.15s;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mt-form__images-preview:hover > .mt-form__images-preview-backdrop {
    opacity: 1;
}
.mt-form__images-preview-backdrop > div {
    padding: 16px 32px;
    border: 2px solid #ffffff;
    color: #ffffff;
    border-radius: 32px;
    font-weight: 600;
}
.mt-form__images-preview--hidden {
    display: none;
}
.mt-form__img-example-container {
    padding: 40px;
    margin-top: 32px;
    display: flex;
    border: 1px solid #eeeeee;
    background: #fcfcfc;
    border-radius: 2px;
}
.mt-form__img-example {
    margin-right: 32px;
    position: relative;
}
.mt-form__img-example > img:nth-child(1) {
    border-radius: 8px;
    border: 3px solid #4CA90C;
}
.mt-form__img-example > img:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-45%, -30%);
}
.mt-form__img-example-text > h3 {
    color: #4CA90C;
}
.mt-form__thank-you-image {
    display: block;
    height: 600px;
    border-radius: 16px;
}
