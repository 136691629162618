/* Virtual Office no content*/
.projects__no-vso-container{
    display: flex;
    justify-content: space-between;
    gap: 4%;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 64px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04)
}

.projects__no-vso-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
}

.projects__no-vso-content > div:nth-child(1) {
    font-size: 32px;
    font-weight: 800;
}

.projects__no-vso-content > div:nth-child(2) {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
}

.projects__no-vso-button-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.projects__no-vso-button-container > img:hover{
    cursor: pointer;
}

.projects__no-vso-button-container > div {
    font-size: 18px;
}

.projects__no-vso-img-container{
    display: flex;
    justify-content: center;
    width: 500px;
    height: 300px;
}

.projects__no-vso-img-container > img{
    object-fit: cover;
}

.projects__no-vso-start{
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 36px 0px;
    background-color: #3D59EC;
    width: 164px;
    height: 64px;
    border-radius: 10px;
    padding: 8px 8px;
    gap: 4px;
    align-self: flex-end;
    text-decoration: none;
}

.projects__no-vso-start:hover{
    cursor: pointer;
}

.projects__no-vso-start > div:nth-child(1){
    font-size: 24px;
    color: white;
    font-weight: 700;
}

.projects__no-vso-start > div:nth-child(2){
    font-size: 11px;
    color: white;
    font-weight: 700;
}

.projects__no-vso-start > img{
    position: absolute;
    top: 20px;
    right: 8px;
}

/*Virtual offices with content*/

.vso__start-project{
    width: 100%;
    background: #ffffff;
    border-radius: 16px;
    padding: 8px 0px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    transition: 0.15s;
    text-align: center;
    font-size: 72px;
    text-decoration: none;
    color: #c4c4c4;
    font-weight: 100;
    margin-bottom: 42px;
}
