@import "../../../../scss/main.scss";

.stepsCard__container {
    width: 100%;
    max-width: 226px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px 16px;
    border: 2px solid transparent;
    @include bd-radius(medium);
    @include shadow(60);
    
    &:hover {
        cursor: pointer;
    }

    &.--active {
        border: 2px solid $accent__default;
    }

    .stepsCard__step-container {
        display: flex;
        align-items: center;
        gap: 8px;

        .stepsCard__step {
            @include typo(x_small, bold, 1);
            color: $fg__muted;
        }

        svg {
            width: 12px;
            height: 12px;
        }
    }

    .stepsCard__title {
        @include typo(small, bold, 1);

        @media only screen and (max-width: 1130px)  {
            @include typo(caption, bold, 1);
        }
    }

    .stepsCard__progess-container {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 8px;

        .stepsCard__progress-text {
            white-space: nowrap;
            @include typo(x_small, bold, 1);
            color: $accent__default;
        }

        .stepsCard__progress-bar {
            flex-grow: 1;
            height: 8px;
            overflow: hidden;
            background: $bg__subtle;
            @include bd-radius(large);
            
            .stepsCard__progress-bar-track {
                height: 100%;
                background: $accent__default;
                transition: width 2s;
            }
        }
    }
}