@import "../../../scss/main.scss";

.batchUpload__batch-container {
    display: flex;
    flex-direction: column;
    width: 1440px;
    height: 100%;
    background-color: $bg__surface;
    overflow: hidden;
    @include bd-radius(large);

    .batchUpload__batch-split-container {
        display: flex;
        gap: 64px;
        width: 100%;
        height: 100%;
        padding: 48px;

        .batchUpload__batch-drag-drop {
            width: 50%;
        }

        .batchUpload__batch-info {
            width: 50%;
            height: 100%;
        }
    }
    .batchUpload__batch-save {
        display: flex;
        justify-content: flex-end;
        padding: 0px 48px 48px 48px;

        .button {
            svg {
                width: 16px;
                height: 16px;
                path {
                    fill: $bg__surface
                }
            }
        }
    }
}