@import "../../../../scss/main.scss";

.radioInput__container {
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 6px;
    position: relative;

    label {
        @include typo(small, bold, 2);

        span {
            @include typo(small, bold, 1);
            color: $accent__default;
        }
    }

    .input__radio {
        width: fit-content;
        display: flex;
        border: 2px solid $accent__default;
        @include bd-radius(small);
        overflow: hidden;
        cursor: pointer;
        
        div {
            @include typo(button_small, regular, 1);
            color: $accent__default;
            padding: 6px 24px;
            transition: 0.15s;

            &.selected {
                @include typo(button_small, bold, 1);
                color: $fg__on-accent;
                background: $accent__default;
                cursor: default;
            }

            &:not(:last-child) {
                border-right: 2px solid $accent__default;
            }
        }
    }

    .radioInput__error {
        width: max-content;
        position: absolute;
        top: calc(100% + 0px);
        left: 0px;
        @include typo(caption, regular, 1);
        color: $error__muted;
    }

}

