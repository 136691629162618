@import "../../../scss/main.scss";

.infoTemplate__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .infoTemplate__items {
        display: flex;
        gap: 24px;

        .infoTemplate__icons {
            width: fit-content;
            height: fit-content;
            
            svg {
                width: 105px;
                height: 120px;
            }
        }

        .infoTemplate__text-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 4px;

            .infoTemplate__info-title {
                @include typo(button_medium, bold, 1);
            }

            .infoTemplate__info-text {
                @include typo(caption, regular, 2);
            }
        }
    }
}