@import "../../../../scss/main.scss";

.projectOverviewBox__container {
    display: flex;
    width: 100%;
    background-color: $bg__surface;
    height: fit-content;
    overflow: hidden;
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1);
    @include bd-radius(large);

    .projectOverviewBox__split-container {
        display: flex;
        flex-direction: column;
        padding-top: 12px;
        width: calc(40% + 36px);
        gap: 18px;
        box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 16px 24px rgba(48, 49, 51, 0.1);

        .projectOverviewBox__title-address-container {
            display: flex;
            flex-direction: column;
            padding: 0px 24px;
            gap: 8px;

            .projectOverviewBox__title {
                @include typo(lead, bold, 1);
            }

            .projectOverviewBox__address {
                display: flex;
                align-items: center;
                gap: 12px;
                color: $fg__subtle;
                @include typo(small, regular, 2);
            }
        }

        .projectOverviewBox__building-info-container {
            display: flex;
            gap: 48px;
            padding: 0px 24px;
            
            .projectOverviewBox__building-info {
                display: flex;
                align-items: center;
                gap: 8px;
                color: $accent__default;
                @include typo(button_small, bold, 1);
            }
        }

        .projectOverviewBox__image-hero {
            background-position: center;
            background-size: cover;
            flex-grow: 1;
            width: 100%;
        } 
    }

    .projectOverviewBox__split-two-container {
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 32px 48px;
        width: 60%;
        
        .projectOverviewBox__description-text {
            color: $fg__subtle;
            @include typo(caption, regular, 2);
        }

        .projectOverviewBox__divider {
            height: 1px;
            width: 100%;
            background: $bg__muted;
        }
        
        .projectOverviewBox__details-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: calc(60% + 24px);

            .projectOverviewBox__details-info {
                width: 100%;
                display: flex;
                justify-content: space-between;

                .projectOverviewBox__details-header {
                    @include typo(caption, bold, 1);
                }
                .projectOverviewBox__details-answer {
                    @include typo(caption, regular, 1);
                }
            }
        }
    }

}