@import "../../../scss/main.scss";

.overview__container {
    background: $bg__subtle;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 32px;
    z-index: 3;
    margin-top: 64px;
    padding: 50px 120px;
    position: absolute;

    .overview__units-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(224px, 1fr));
        gap: 32px 84px;
    }

    .overview__policies-container {
        display: flex;
        gap: 24px;
        flex-direction: column;
        background-color: $bg__surface;
        padding: 32px;
        @include shadow(60);
        @include bd-radius(medium);
        
        .overview__policies-grid {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            max-height: 80px;
            align-content: flex-start;
            height: fit-content;
            gap: 20px;

            .overview__policy-item {
                display: flex;
                align-items: center;
                gap: 10px;
                position: relative;
                width: fit-content;
            }
        }
    }
}

@media only screen and (max-width: 1482px) {
    .overview__container {
        padding: 50px 64px;
    }
}
    

/* .overview__container {
    background: #F3F7FA;
    width: 100%;
    min-height: 100vh;
    display: flex;
    z-index: 3;
    margin-top: 64px;
    padding: 50px 120px;
    position: absolute;
}

.overview__tabs-container {
    display: flex;
    flex-direction: column;
    width: 10vw;
    max-width: 200px;
    gap: 16px;
    margin-top: 16px;
    position: sticky;
    position: fixed;
}

.overview__tab-item {
    padding: 16px 24px;
    width: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: 0.15s;
    color: #777777;
}

.overview__tab-item.--active {
    background: #ffffff;
    color: #3D59EC;
    box-shadow: 0 2px 4px rgb(0 0 0 / 4%);
}

.overview__tab-item:hover {
    background: #ffffff;
}


.overview__info-container {
    flex-grow: 1;
    background-color: #ffffff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 48px 64px;
    box-shadow: 0 2px 8px -2px rgb(0 0 0 / 8%);
    margin-left: 10vw;
    min-width: 800px;
    width: 70vw;
}

.overview__project-name {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 32px;
}

.overview__project-address {
    font-weight: 500;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
}

.overview__project-address > img {
    padding-right: 16px;
}
.overview__project-address > span {
    color: #565656;
}

.overview__building-stats {
    display: flex;
    margin-bottom: 16px;
    gap: 8px;
    position: relative;
    align-items: center;
}

.overview__building-stats > span {
    font-weight: 500;
    color: #565656;
}

.overview__building-stats > img {
    padding-right: 8px;
}

.overview__building-separator {
    color: #cccccc;
}

.overview__split-hero {
    display: flex;
    position: relative;
    align-items: stretch;
    margin-bottom: 48px;
}

.overview__hero-image {
    background-position: center;
    background-size: cover;
    width: 50%;
    border-radius: 8px;
    height: 400px; 
}

.overview__hero-table {
    padding: 0 32px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.overview__hero-table-title {
    width: 100%;
    padding: 8px;
    color: #3D59EC;
    font-weight: 600;
    background: #F8FAFC;
    border-radius: 4px;
}

.overview__hero-table-item {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 4px;
}

.overview__hero-table-item > div {
    width: 50%;
}

.overview__hero-table-item-value {
    font-weight: 500;
    text-transform: capitalize;
}

.overview__units-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(224px, 1fr));
    gap: 32px;
    margin-top: 64px;
}

.overview__units-item {
    padding: 16px 24px;
    background: #F8FAFC;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 224px;
}

.overview__units-name {
    color: #3D59EC;
    background: #edf2f7;
    font-weight: 500;
    padding: 8px 16px;
    width: fit-content;
    border-radius: 4px;
    margin-left: -8px;
    margin-top: -32px;
}

.overview__units-number {
    font-weight: 500;
    color: #3D59EC;
}

.overview__nav-title {
    width: 100%;
    padding: 8px;
    color: #3D59EC;
    font-weight: 600;
    background: #F8FAFC;
    border-radius: 4px;
    margin: 48px 0 24px;
}

.overview__policies-container {
}

.overview__policies-grid {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 80px;
    align-content: flex-start;
    height: fit-content;
    gap: 16px;
}

.overview__policy-item {
    position: relative;
    width: fit-content;
    margin-right: 32px;
    padding-left: 32px;
}

.overview__policy-item.--allowed::before,
.overview__policy-item.--prohibited::before {
    content:"";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.overview__policy-item.--allowed::before {
    content:"✔";
    color: rgb(70, 173, 70);
    font-size: 24px;
    text-shadow: 0 0 #000000;
}

.overview__policy-item.--prohibited::before {
    content:"❌";
} */