@import "../../../../scss/main.scss";

.addMultiUnits__container { 
    background-color: $bg__surface;
    width: 250px;
    display: flex;
    flex-direction: column;
    padding: 8px;
    width: fit-content;
    height: fit-content;

    .addMultiUnits__text {
        @include typo(small, regular, 1); 
    }

    .addMultiUnits__input-button-container {
        display: flex;
        gap: 8px;

        .button {
            @include typo(caption, bold, 1); 
            align-self: flex-end;
        }
    }

}

