@import "../../../../../scss/main.scss";

.unitTable__container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;

    .unitTable__buttons-bar {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 24px;
        background-color: $bg__surface;
        height: fit-content;

        .unitTable__bar-left {
            display: flex;
            gap: 28px;
        }

        .unitTable__bar-right {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;

            .unitTable__error{
                color: $error__muted;;
            }
        }
    }
}