@import "../../../../../../scss/main.scss";

.documents__documents-item {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(229, 229, 229, 0.75);
    background-color: $bg__surface;
    @include bd-radius(medium);
    width: 100%;
    padding: 24px;

    .documents__documents-item-options {
        display: flex;
        height: fit-content;
        width: fit-content;
        position: absolute;
        background: $bg__surface;
        bottom: 4px;
        right: 4px;
        padding: 4px;
        cursor: pointer;
        @include bd-radius(medium);
        @include shadow(60);
    }

    &:hover {
        cursor: move;
    }

    p {
        @include typo(small, bold, 1);
        color: $fg__default;
    }

    .documents__documents-item-separator {
        border: 2px solid rgba(5, 39, 207, 0.2);
        margin-top: 10px;
        width: 100%;
    }
    .documents__documents-item-file-size {
        align-self: flex-start;
        margin-top: 8px;
        
        p {
            margin: 0;
        }

        p:nth-child(1) {
            @include typo(small, bold, 1);
        }
    }
}
