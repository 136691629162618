@import "../../../../../scss/main.scss";

input[type=text].tableInput__text {
    /* width: 82px; */
    width: 100%;
    height: 32px;
    border: 2px solid $input__border-default;
    transition: 0.15s;
    outline: none;
    border-radius: 2px;
    text-align: left;
}

input[type=text].tableInput__text:focus {
    border: 2px solid $accent__default;
    box-shadow: 0 2px 8px -2px rgb(61 83 245 / 50%);
}

.input__text--valid {
    border-color: #3ec775;
}
.input__text--denied {
    border-color: #c06b5d;
}