:root { 
    --blue: #3d59ec;
    --white: #ffffff;
    --red: #b63211;
    --black: #333333;
}
.bd-blue{
    border-color: #3d59ec !important;
}
.bg-blue{
    background-color: #3d59ec !important;
}
.txt-color {
    color: #3d59ec !important;
}
.bd-blue-focus:focus{
    border-color: #3d59ec !important;
}

.bd-blue-hover:hover{
    border-color: #3d59ec !important;
}

.bd-blue-active:active {
    border-color: #3d59ec !important;
}

.main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1400px;
    margin: 0 auto;
    height: 100%;
    overflow: hidden;
}
.main-container--dashboard {
    max-width: 100vw;
    justify-content: left;
    align-items: stretch;
}
.auth__container-vso{
    display: flex;
    flex-direction: column;
    background: #ffffff;
    width: 581px;
    height: 658px;
    border: 8px solid #000;
    border-radius: 10px;
    padding: 48px 72px;
}
.auth__logo-title-container-vso{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}
.auth__logo-title-container-vso > div{
    font-size: 32px;
    font-weight: 800;
}
.auth__visit-text-vso{
    align-self: center;
    font-size: 36px;
    font-weight: 800;
    margin-top: 56px;
}
.auth__form-first-last-name-vso{
    display: flex;
    flex-direction: row;
    margin-top: 36px;
    gap: 24px;
}
.auth__form-button-vso{
    display: flex;
    justify-content: center;
    margin-top: 36px;
}
.auth__form-button-vso > button{
    text-align: center;
    padding: 12px 56px;
    color: #FFFFFF;
    background-color: #3d59ec;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 800;
}
.auth__form-button-vso > button:hover{
    cursor: pointer;
}
.auth__container {
    min-width: 400px;
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.auth__form {
    margin-top: 24px;
    width: 100%;
}
.form__group {
    border: 1px solid #f4f4f4;
    padding: 16px 16px 0 16px;
    border-radius: 8px;
    background: hsl(0 0% 98% / 1);
    margin-bottom: 32px;
}
.form__input-row {
    display: flex;
    justify-content: space-between;
    grid-gap: 16px;
}
.form__input-row > .form__input-group {
    width: 100%;
}
.form__input-group {
    margin-bottom: 32px;
}
.form__input-group label {
    line-height: 1.5;
    margin-bottom: 8px;
    display: inline-block;
    font-weight: 500;
    font-size: 0.9rem;
    color: #9698a1;
}
.form__input-group--hz {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
}
.form__input-group--hz label {
    display: inline-block;
}
.form__input-group--hz > .form__input-number-container{
    margin-left: 32px;
} 
.form__input-group > .label--strong {
    color: #333333;
    font-weight: 600;
}


textarea:not(.amt-field__value-card-field_value-input, .amt-field__value-card-input) {
    font-weight: 400;
    border-radius: 0.375rem;
    padding: 0.75rem 1.25rem;
    line-height: 1.5;
    color: #4A5568;
    font-size: 0.9rem;
    border: 1px solid #E2E8F0;
    transition: all 0.15s ease;
    height: calc(1.5em + 1.5rem + 2px);
    width: 100%;
    outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button  {
    -webkit-appearance: none;
}

textarea:not(.amt-field__value-card-field_value-input, .amt-field__value-card-input) {
    height: 160px;
    resize: none;
    line-height: 1.4 !important;
}

.form__input-number-container {
    display: flex;
    border: 1px solid #E2E8F0;
    border-radius: 0.375rem;
    background: #ffffff;
    align-items: stretch;
}
.form__input-number-container > div {
    padding: 0 16px;
    cursor: pointer;
    user-select: none;
    transition: 0.15s;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form__input-number-container > div > span {
    color: #FF7600;
    font-weight: 400;
    font-size: 22px;
}
.form__input-number-container > div:hover {
    background: #F3F5F7;
}

/* .input__radio {
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #DDDDDD;
    margin-top: 10px;
    width: fit-content;
}
.input__radio > div {
    padding: 8px 24px;
    background: #ffffff;
    cursor: pointer;
    font-size: 14px;
    transition: 0.15s;
}
.input__radio > div.selected {
    background: #FE9D0D;
    color: #ffffff;
}
.input__radio > div:not(:last-child) {
    border-right: 1px solid #dddddd;
} */

input[type=text]::placeholder,
input[type=email]::placeholder,
input[type=tel]::placeholder,
input[type=password]::placeholder,
textarea::placeholder {
    color: #A0AEC0;
}

textarea:focus {
    border-color: 1px solid #FF9401;
}

input[type=checkbox] {
    margin-right: 8px;
}
 
.errorMsg {
    color: #D93025;
    font-size: 0.9rem;
    position: absolute;
    bottom: -8px;
  }

.form__input-group {
    position: relative;
}

input.error {
    border-color: #D93025
}

.input__password-forgotten {
    display: block;
    text-align: right;
    margin-top: -8px;
}

p {
    font-weight: 300;
    line-height: 1.5;
}

.p--muted {
    color: #718096 !important;
}
.p--small {
    font-size: 0.8rem;
}
.p--centered {
    text-align: center;
}

.h-line {
    width: 100%;
    border-top: 1px solid #e1e7ea;
    margin-bottom: 32px;
}
.v-separator {
    height: 100%;
    border-left: 1px solid #e1e7ea;
    margin: 0 32px;
}

.btn-primary {
    display: block;
    text-decoration: none;
    position: relative;
    width: 100%;
    padding: 0.75rem 1rem;
    line-height: 1.5;
    font-size: 0.95rem;
    cursor: pointer;
    border: none;
    border-radius: 16px;
    color: #ffffff;
    margin: 32px 0 24px;
    outline: none;
    transition: all 0.15s;
    overflow: hidden;
    text-align: center;
    z-index: 0;
}
.btn-primary::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(255,148,1,1);
    z-index: -2;
}
.btn-primary::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(5deg, rgba(255,85,0,1) 0%, rgba(255,148,1,1) 100%);
    z-index: -1;
    transition: all 0.15s;
}
.btn-primary:hover::after {
    opacity: 0;
}
strong {
    color: #FF7600 ;
}

.card-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.market-tests__link {
    text-decoration: none;
}

.market-tests__list-card {
    border-radius: 18px;
    background: white;
    margin: 7px;
    cursor: pointer;
}
.market-tests__list-card:hover {
    transform: scale(1.01);
}
.market-tests__list-card-top {
    display: grid;
    grid-template-columns: 30% 50% 20%;
    width: 100%;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-top: 10px;
    font-size: 0.8rem;
}
.market-tests__list-card-image {
    padding: 10px;
}
.market-tests__list-card-top-info {
    text-align: left;
}
.market-tests__list-card-projname {
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 1rem;
    font-weight: bold;
}
.market-tests__list-card-top-details {
    text-align: right;
    padding: 10px;
}

.market-tests__list-card-bottom {
    width: 100%;
    text-align: left;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-top: 5px;
    font-size: 0.8rem;
}
.market-tests__list-card-package {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: #F3F5F7;
    text-align: center;
    margin-bottom: 5px;
}
.market-tests__list-card-type {
    width: 45%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #F3F5F7;
    text-align: center;
    margin-bottom: 5px;
}
.market-tests__list-card-3d__checkbox {
    margin-left: 5px;
}
.line {
    margin-left: 20px;
    margin-right: 20px;
}

/* @media only screen and (max-width: 1482px) {

    input[type=text]:not(.input__number-with-price),
    input[type=email],
    input[type=tel],
    input[type=password] 
    textarea:not(.amt-field__value-card-field_value-input, .amt-field__value-card-input),
    .form__input-group label,
    .input__radio > div {
        font-size: 14px;
    }

    .form__input-number-container > div > span { 
        font-size: 16px;
    }

    input[type=number]:not(.input__reservation) { 
        font-size: 14px;
        width: 48px;
    }
} */
