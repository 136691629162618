.subSteps__tabs-container {
    display: flex;
    grid-gap: 32px;
    margin-bottom: 56px;
}

.subSteps__tabs-item {
    position: relative;
    min-width: 12%;
    padding-bottom: 16px;
    color: #666666;
}

.subSteps__tabs-item::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #D4D4D4;
    transition: 0.15s;
}

.subSteps__tabs-item.active {
    color: var(--black);
}

.subSteps__tabs-item.active::after {
    background-color: var(--blue);
}