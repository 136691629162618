.amenities-list__wrapper {
    margin-bottom: 32px;
}
.amenities-list__title {
    margin-bottom: 24px;
    font-weight: 600;
}
.amenities-list__list-container {
    display: flex;
    gap: 24px 32px;
    flex-wrap: wrap;
}
.amenities-list__list-container > div {
    position: relative;
    padding-left: 24px;
}
.amenities-list__list-container > div::before {
    content: "";
    position: absolute;
    left: 0;
    width: 17px;
    height: 12px;
    background: url('../../../assets/images/listing/amenity_check.svg');
}