.sidebar {
    width: 264px;
    min-width: 264px;
    height: 100%;
    padding-top: 64px;
    z-index: 99;
}
.sidebar__container {
    padding: 0 16px 32px;
    height: 100%;
}
.sidebar__logo {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    margin: -32px 0 48px -8px;
}
.sidebar__logo > img {
}

.sidebar__new-mt-btn {
    margin: 32px 0 24px;

}

.sidebar__link-container {
    display: block;
    margin: 12px 0;
    padding: 16px 8px 12px 32px; 
    transition: 0.15s;
    text-decoration: none;
    border-radius: 4px;
}
.sidebar__link-container:hover {
    background: #f4f4f4;
}

.sidebar__link-container--active,
.sidebar__link-container--active:hover {
    background: #FF760033;
}