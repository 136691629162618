@import "../../../../../scss/main.scss";

.planDetailViewer__container {
    position: relative;
    display: flex;
    background: $bg__canvas;

    .planDetailViewer__detail-container {
        display: flex;
        flex-direction: column;
        width: fit-content;
        min-width: 360px;
        gap: 24px;
        padding: 32px 0px 64px 48px;
        overflow-y: auto;

        .planDetailViewer__unit-info {
            display: flex;
            flex-direction: column;
            gap:16px;

            .planDetailViewer__unit-sqft-bed-bath-container {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .planDetailViewer__unit-sqft {
                    color: $fg__muted;
                    @include typo(body, bold, 1);
                }

                .planDetailViewer__unit-bed-bath {
                    color: $fg__default;
                    @include typo(lead, bold, 1);
                }
            }

            .planDetailViewer__unit-price {
                display: flex;
                align-items: center;
                color: $fg__default;
                @include typo(headline_2, bold, 1);
            }
        }
        
        .divider {
            height: 1px;
            width: 100%;
            background: $bg__muted;
        }

        .planDetailViewer__detail-info-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .planDetailViewer__detail-title {
                color: $fg__muted;
                @include typo(x_small, bold, 1);
            }

            .planDetailViewer__detail-info {
                display: flex;
                align-items: center;
                gap: 16px;
                @include typo(caption, regular, 1);

                svg > path {
                    fill: $accent__default;
                }
            }

            .planDetailViewer__detail-move-in {
                display: flex;
                align-items: center;
                gap: 16px;
                @include typo(caption, bold, 1);
            }

        }
    }

    .planDetailViewer__image-container {
        display: flex;
        flex-grow: 1;
        padding: 0px 64px 48px 64px;
        position: relative;
        justify-content: center;

        img {
            @include bd-radius(medium);
            @include shadow(100);
        }
    }
}

// @media only screen and (max-width: 1482px) {
    
//     .planDetailViewer__container {
//         .planDetailViewer__image-container {
//             .planDetailViewer__image {
//                 width: 600px;
//                 height: 700px;
//             }
//         }
//     }
// }