@import "../../../../../../scss/main.scss";

.customColumnForm__container {
    display: flex;
    flex-direction: column;
    background-color: $bg__surface;
    height: fit-content;

    @include bd-radius(large);
    @include shadow(100);
}

.customColumnForm__header {
    width: 100%;
    background-color: $accent__emphasis;
    padding: 24px 48px;
    color: $fg__on-accent;
    border-radius: 10px 10px 0 0;

    @include typo(headline_4, bold, 1);
}

.customColumnForm__container > h1 {
    margin: 0px;
}

.customColumnForm__body {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 40px 48px;
}

.customColumnForm__body-split-container {
    display: flex;
    gap: 48px;
}

.customColumnForm__buttons-container {
    display: flex;
    gap: 16px;
    align-self: flex-end;
}

.customColumnForm__choose-type-container {
}

.customColumnForm__choose-type-container > .form__input-group > input[type=text]:focus {
    border: 1px solid var(--blue);
}

.customColumnForm__choose-type-container > .form__input-group > select {
    width: fit-content;
}

.customColumnForm__type-item-with-desc {
    display: flex;
    gap: 16px;
    align-items: center;
}

.customColumnForm__item-text {
    @include typo(small, bold, 1);
}

.customColumnForm__item-desc {
    color: $fg__muted;

    @include typo(caption, regular, 2);
}

.customColumnForm__type-item {
    display: flex;
    gap: 8px;
    align-items: center;
}

.customColumnForm__container > .button {
    align-self: end;
}

.customColumnForm__delete-confirmation {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 24px 0;
    background: $error__subtle;
    padding: 24px;
    width: 100%;

    @include bd-radius(small);

    div {
        color: $error__fg;
    }

    .customColumnForm__delete-buttons-container {
        display: flex;
        gap: 12px;
    }
}