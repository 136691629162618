.diaporama__container {
    position: absolute;
    overflow: hidden;
}
.diaporama__container.--images {
    top: 5.5px;
    left: 2px;
    width: 184px;
    height: 145px;
    transform: matrix(1, 0.4, 0, 0.94, 0, 0);
}
.diaporama__container.--plans {
    top: 5px;
    left: 2px;
    width: 184px;
    height: 161px;
    transform: matrix(1, -0.4, 0, 0.94, 0, 0);
}
.diaporama__item {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    filter: contrast(1.1) brightness(0.9) grayscale(0.05);
    transition: 1s opacity;
}
.diaporama__item.--images {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.diaporama__item.--plans {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #dddddd;
}
.diaporama__item.--hidden {
    opacity: 0;
}
.diaporama__shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3; 
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 70%);
}
.diaporama__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2; 
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    mix-blend-mode: screen;
    opacity: 0.9;
}
.diaporama__plan-table-container {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 80%;
    height: 75%;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 
        0 2px 8px rgb(0 0 0 / 10%),
        0 0 16px rgb(0 0 0 / 5%);
    z-index: 1;
}
.diaporama__plan-table {
    position: relative;
    width: 60%;
    border-right: 1px solid #cccccc;
    height: 100%;
}
.diaporama__plan-image {
    width: 40%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.diaporama__plan-table-header {
    position: relative;
    height: 24px;
    width: 100%;
    background: #3d59ec;
}
.diaporama__plan-table-header::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 8px;
    width: calc(100% - 16px);
    background: rgb(255 255 255 / 25%);
    height: 5px;
    border-radius: 8px;
    transform: translateY(-50%);
}
.diaporama__plan-table-line {
    position: relative;
    height: 16px;
    width: 100%;
    border-bottom: 1px solid #cccccc;
}
.diaporama__plan-table-line::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 8px;
    background: #dddddd;
    height: 4px;
    border-radius: 8px;
    transform: translateY(-50%);
}
.diaporama__plan-table-line.--0::after {
    width: calc(100% - 32px);
}
.diaporama__plan-table-line.--1::after {
    width: calc(100% - 24px);
}
.diaporama__plan-table-line.--2::after {
    width: calc(100% - 48px);
}