@import "../../../../scss/helpers/variables.scss";
@import "../../../../scss/helpers/mixins.scss";


.filterMultiSelect__container {
    display: flex;
    flex-direction: column;

    .checkbox__input-label {
        cursor: pointer;
        @include typo(button_medium, bold, 2);
    }
}

.copyFloor__floor-items-container {
    .checkbox__input-label {
        cursor: pointer;
    } 
}