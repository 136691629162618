@import "../../../scss/helpers/variables.scss";
@import "../../../scss/helpers/mixins.scss";

@keyframes fadeIn{
    0%{opacity: 0;}
    100%{opacity: 1;}
}

.modal__backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: $overlay__bg;
    z-index: 10000;

    .modal__panel {
        position: relative;
    }
}

.modal__backdrop.--contentCenter {
    align-items: center;
}

.modal__backdrop.--animation-delayed-slow{
    opacity: 0;
    animation: fadeIn 1s 1s forwards;
    overflow: hidden;
}