@import "../../../scss/main.scss";

.button {
    display: flex;
    width: fit-content;
    height: fit-content;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    cursor: pointer;
    transition: 0.15s;
    @include bd-radius(medium);
    @include typo(button_medium, bold, 1);

    &.--primary {
        background: $accent__default;
        color: $fg__on-accent;
        border: 1px solid $accent__default;
        @include shadow(60);
    
        path {
            fill: $fg__on-accent;
        }
    }

    &.--secondary {
        background: $bg__surface;
        color: $fg__default;
        border: 1px solid $bg__muted;
        @include shadow(40);

        &.--active {
            color: $fg__on-accent;
            background-color: $accent__default;
            border: 1px solid $accent__default;
            @include shadow(100);  
            
            path {
                fill: $fg__on-accent;
            }
        }

        &:active {
            color: $fg__on-accent;
            background-color: $accent__emphasis;
            border: 1px solid $accent__emphasis;
            @include shadow(20);  
            
            path {
                fill: $fg__on-accent;
            }
        }

        &:not(.--active):hover {
            border: 1px solid $accent__emphasis;
        }
    }

    

    &.--ternary {
        background: $border__default;
        color: $fg__muted;
        border: 1px solid $border__default;
    }

    &.--danger {
        background: $error__fg;
        color: $error__on-error;
        border: 1px solid $error__fg;
        @include shadow(60);
    }

    &.--pill {
        background: $bg__canvas;
        border: 1px solid $accent__disabled; 
        border-radius: 100px;
        color: $fg__subtle;
        @include typo(x_small, bold, 1);
    }
    &.--pill.--active {
        background: $accent__default;
        border: 1px solid $accent__default;
        color: $bg__surface;
    }

    &.--small {
        padding: 6px 16px;
    }

    &.--fluid {
        width: 100%;
    }

    &[disabled] {
        background: $accent__disabled;
        color: $fg__on-disabled;
        border: 1px solid $accent__disabled;
        box-shadow: none;        
    }

}

// .button {
//     display: flex;
// }

// .button__primary {
//     border: none;
//     text-align: center;
//     background-color: var(--blue); 
//     color: var(--white);
//     border-radius: 8px; 
//     padding: 12px 32px;
//     font-weight: 800;
//     font-size: 17px;
//     height: fit-content;
//     width: fit-content;
//     cursor: pointer;

// }

// .button__link {
//     border: none;
//     background: none;
//     text-decoration: underline;
//     cursor: pointer;
//     width: fit-content;
//     height: fit-content;
//     padding: 8px 12px;
//     transition: 0.15s;
//     border-radius: 4px;
// }

// .button__link:hover {
//     background: rgb(0 0 0 / 5%);
// }

// .button__ceiling-input {
//     display: flex;
//     align-items: center;
//     gap: 16px;
//     border-radius: 8px;
//     padding: 8px 24px;
//     background-color: var(--white);
//     cursor: pointer;
//     border: 1px solid #B2B2B2;
//     box-sizing: border-box;
//     border-radius: 8px;
//     font-size: 16px;
//     transition: 0.15s;
//     width: fit-content;
// }

// .button__reservation, .button__reservation-input, .button__reservation-pay {
//     justify-content: center;
//     align-items: center;
//     border-radius: 8px;
//     padding: 12px 24px;
//     background-color: var(--white);
//     cursor: pointer;
//     border: 1px solid #B2B2B2;
//     box-sizing: border-box;
//     font-weight: 600;
//     font-size: 16px;
//     transition: 0.15s;
// } 

// .button__reservation:not(.--active):hover, 
// .button__reservation-input:not(.--active):hover, 
// .button__reservation-pay:not(.--active):hover,
// .button__contact_info_s1:not(.--active):hover {
//     box-shadow: 0px 1px 3px 0px #666A7DE5;
// }

// .button__reservation.--active, 
// .button__reservation-input.--active,
// .button__reservation-pay.--active {
//     color: var(--white);
//     background-color: #5E75EF;
//     border: 1px solid #3D59EC 
// }

// .button__reservation-input.--active > input {
//     color: var(--white);
//     border-bottom: 1px solid var(--white);
// }

// .button__contact_info_s1 {
//     padding: 12px;
//     justify-content: center;
//     align-items: center;
//     background-color: var(--white);
//     cursor: pointer;
//     border: 1px solid #B2B2B2;
//     box-sizing: border-box;
//     border-radius: 8px;
//     font-weight: 600;
//     font-size: 16px;
//     transition: 0.15s;
//     width: max-content;
// }

// .button__contact_info_s1:hover { 
//     box-shadow: 0px 1px 3px 0px #666A7DE5;
// }

// .button__contact_info_s1.--active { 
//     background-color: #5E75EF;
//     border: 1px solid #3D59EC;
//     color: var(--white);
// }

// .button__secondary {
//     display: block;
//     padding: 12px 32px;
//     background: var(--white);
//     border: 1px solid #C1C1C1;
//     font-weight: 600;
//     border-radius: 10px;
//     color: #333333;
//     cursor: pointer;
//     transition: 0.15s;
// }

// .button__secondary:hover {
//     background: #f9f9f9;
// }

// .button__save-button {
//     padding: 8px 16px;
//     background-color: var(--blue);
//     color: var(--white);
//     font-weight: 600;
//     border-radius: 8px;
//     cursor: pointer;
//     transition: 0.15s;
//     align-self: flex-start;
//     border: 1px solid var(--blue);
// }

// .button__save-button:hover {
//     background-color: #002aff;
//     border: 1px solid #002aff;
// }

// .button__back-button {
//     display: block;
//     padding: 12px 32px;
//     background: var(--white);
//     border: 1px solid #C1C1C1;
//     font-weight: 600;
//     border-radius: 10px;
//     color: var(--black);
//     cursor: pointer;
//     transition: 0.15s;
// }

// .button__back-button:hover {
//     background: #f9f9f9;
// }

// .button__next-button {
//     display: block;
//     padding: 12px 48px 12px 16px;
//     cursor: pointer;
//     background: var(--blue);
//     color: var(--white);
//     font-weight: 600;
//     border: 1px solid var(--blue);
//     border-radius: 10px;
//     position: relative;
//     transition: 0.15s;
// }

// .button__next-button::after {
//     content: ">";
//     position: absolute;
//     font-weight: 600;
//     font-size: 24px;
//     margin-left: 24px;
//     top: 50%;
//     right: 16px;
//     transform: translateY(-50%);   
// }

// .button__next-button:hover {
//     background: #002aff;
//     border: 1px solid #002aff;
// }


// @media only screen and (max-width: 1482px) {
//     .button__ceiling-input,
//     .button__contact_info_s1 {
//         font-size: 14px;
//     }
// }

// @media only screen and (max-width: 1200px) { 
//     .button__reservation, 
//     .button__reservation-input, 
//     .button__reservation-pay {
//         font-size: 14px;
//     }
// }