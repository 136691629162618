@import "../../../../scss/helpers/variables.scss";
@import "../../../../scss/helpers/mixins.scss";

.filterTags__container {
    display: flex;
    height: fit-content;
    width: fit-content;
    gap: 8px;
    background: $accent__subtle;
    border-radius: 1000px;

    .filterTags__info {
        padding: 6px 0px 6px 16px;
        display: flex;
        align-items: center;

        .filterTags__label {
            @include typo(button_medium, regular, 1);
            color: $accent__emphasis;
        }
    }

    .filterTags__close {
        padding: 6px 8px 6px 0px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .filterTags__close > svg {
        width: 16px;
        height: 16px;
    }
}