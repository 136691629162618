.slider__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 54px;
    background-color: #fff;
    position: relative;
    height: 560px;
    width: 600px;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    animation: slideFromBottom 1.2s 1.2s;
    transform: translateY(64px);
    animation-fill-mode: forwards;
}

@keyframes slideFromBottom {
    from {
        transform: translateY(64px);
    }

    to {
        transform: translateY(0);
    }
}

.slide {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    opacity: 0;
    transition: 0.15s;
}

.slide > p {
    margin: 0;
    text-align: center;
    padding: 0px 110px;
    font-size: 18px;
    line-height: 2;
    font-weight: 500;
}

.slide.active {
    opacity: 1;
}

.slide-image {
    width: 100%;
    height: 360px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.vertical-dots {
    display: flex;
    flex-direction: row;
    gap: 16px;
    position: absolute;
    bottom: 24px;
    cursor: pointer;
}

.dots {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #c0c0c0;
    transition: 0.15s;
}

.dots.active {
    background-color: #3D59EC;
}

.dots:not(.active):hover {
    background-color: #90a2ff;
}