/* disabled */
@import "../../scss/main.scss";


.--disabled { 
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: default;
}

.product-form__container {
    display: flex;
    flex-direction: column;
    /* grid-template-columns: 1fr 3fr; */
    background: $bg__subtle;
    margin-top: 64px;
    width: 100%;
}

.product-form__progress-box {
    padding: 24px 32px;
    background: var(--white);
    border-radius: 8px 8px 0 0;
    height: 100%;
    text-align: center;
    overflow: hidden;
    position: relative;
    margin-top: 16px;
    min-width: 320px;
    box-shadow: 0 2px 14px -7px rgb(0 0 0 / 20%);
    display: none;
}

.product-form__progress-steps {
    display: block;
    text-align: left;
    padding: 16px;
    margin: 0 auto;
    width: fit-content;
}

.product-form__step-container {
    overflow-y: auto;
    padding: 48px 120px;
    position: relative;
    display: flex;
    flex-direction: column;
    background: $bg__subtle;
    align-items: center;

    &.--fullWidth {
        padding: 0px;
        height: 100%;
        width: 100%
    }
}

.product-form__progress-box.vso::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: var(--blue);
}

.product-form__progress-step {
    font-weight: 300;
    padding: 8px;
    color: #777777;
}
.product-form__progress-step.done,
.product-form__progress-step.current {
    font-weight: 600;
    position: relative;
    color: var(--black);
}

.product-form__progress-step.vso.done::before {
    content: "";
    position: absolute;
    left: -16px;
    width: 12px;
    height: 12px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #73CA74;
    border-radius: 100%;
}

.product-form__progress-step.vso.current::before {
    content: "";
    position: absolute;
    left: -16px;
    top: 50%;
    transform: translateY(-50%);
    width: 0; 
    height: 0; 
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid var(--blue);
}

.product-form__step__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
}

.product-form__step-subtext { 
    padding-right: 16px;
    width: 75%;
}

.product-form__progress-box.vso > h1,
.product-form__step-subtext > h1 {
    margin-top: 22px;
    margin-bottom: 16px;
}

.product-form__step-subtext > p { 
    margin: 0;
    font-size: 16px;
}

.product-form__substep-container {
    display: none;
}
.product-form__substep-container.active {
    display: block;
}

.product-form__next-container {
    display: flex;
    border-top: 1px solid #e4e9ec;
    background :var(--white);
    width: 100%;
    padding: 24px 0;
    margin-top: 32px;
    grid-gap: 24px;
    justify-content: flex-end;
    position: sticky;
    top: 100vh;
}

/*Project Details*/

.product-form__substep-container {
    display: none;
}
.product-form__substep-container.active {
    display: block;
}

.product-form__row-half {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
}

.product-form__row-half > .form__input-group > input[type="text"]:focus {
    border: 1px solid var(--blue);
}

.product-form__row-third {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0px 32px;
}

.product-form__row-units {
    display: grid;
    width: fit-content;
    grid-template-columns: 2fr 2fr 1fr;
    grid-gap: 32px;
}

.product-form__buildings-floors-container {
    background: #FCFCFC;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    padding: 24px 32px;
    width: max-content;
}

.product-form__details-more-container {
    position: relative;
    margin-top: 32px;
    display: flex;
    width: fit-content;
    padding: 32px;
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    background-color: #FCFCFC;
}

.product-form__details-more-container > .form__input-group {
    margin-right: 16px;
}

.product-form__details-more-container select {
    margin-bottom: 0;
}

.product-form__details-more-container > .v-separator {
    height: 80px;
}

.product-form__details-box {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.product-form__tolerance-container,
.product-form__parking-available-container {
    display: flex;
    width: 100%;
    grid-gap: 64px;
    flex-wrap: wrap;
    margin-bottom: 32px;
}

.product-form__tolerance-container {
    margin-top: 32px;
}

.product-form__unit-types-item {
    display: none;
    padding: 8px 16px;
    color: #9698a1;
    background: var(--white);
    border: 1px solid #eeeeee;
    border-radius: 8px;
}
.product-form__unit-types-item.active {
    display: block;
}

/*Errors*/

.product-form__error-container {
    width: fit-content;
    height: fit-content;
    background: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 24px;
    gap: 16px;
}

.product-form__error-container > h3 { 
    color: var(--red);
    margin: 0px;
}

.product-form__error-container > div {
    font-size: 18px;
    color: var(--red);
    text-align: center;
}

.product-form__error-container > button {
    background-color: var(--blue);
    border-radius: 8px;
    padding: 8px 16px;
    border: 1px solid var(--white);
    color: var(--white);
    transition: 0.15s;
}

.product-form__error-container > button:hover {
    background-color: var(--white);
    border-radius: 8px;
    padding: 8px 16px;
    border: 1px solid var(--blue);
    color: var(--blue);
    cursor: pointer;
}

@media only screen and (max-width: 1482px) {
    
    .product-form__substep-container .form__input-group select { 
        font-size: 14px;
    }

    .product-form__split-container > p,
    .product-form__split-container > div,
    .product-form__step-subtext > p {
        font-size: 14px;
    }

    .product-form__progress-box > h1 {
        font-size: 1.5em;
    }

    .product-form__step-subtext > h1 { 
        font-size: 1.5em;
    }
}

@media only screen and (max-width: 1360px) {
    .product-form__parking-available-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 1200px) { 

    .product-form__row-units {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .product-form__row-units > .form__input-group select {
        width: max-content;
    }

    .product-form__row-units > .form__input-group > select {
        margin-bottom: 8px;
    }

    .product-form__details-more-container {
        flex-direction: column;
        gap: 16px;
        width: fit-content
    }

    .product-form__details-box {
        display: flex;
        flex-wrap: wrap;
        gap: 36px;
    }

    .product-form__details-more-container > .v-separator {
        height: 2px;
        border: 1px solid #e1e7ea;
        margin: 0px;
    }
}

@media only screen and (max-width: 1100px) {
    .product-form__row-third {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0px 24px;
    }
}
