.elementHighlight {
    position: relative;
    z-index: 4;
    transition: 0.15s;
    opacity: 0;
}

.sales-office__element-container:hover > .elementHighlight {
    opacity: 1;
}

.elementHighlight.--plans {
    top: -48px;
    left: -22px;
}

.elementHighlight.--images {
    top: -46px;
    left: -19px;
}

.elementHighlight.--documents {
    top: 0;
    left: 0;
}

.elementHighlight.--overview {
    top: 0;
    left: 0;
}

.elementHighlight.--map {
    top: 0;
    left: 0;
}