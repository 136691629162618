@import "../../../../../../scss/main.scss";

.floorPlanOutline__container {
    width: 100%;
    height: 100%;
    display: flex;

    .floorPlanOutline__split-container {
        display: flex;
        width: 100%;
        height: 100%;
        gap: 24px;

        .floorPlanOutline__outline-container {
            display: flex;
            flex-direction: column;
            width: 65%;
            height: 100%;
            @include bd-radius(medium);
            @include shadow(60);
            overflow: hidden;
            position: relative;

            .floorPlanOutline__outline-change-image {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                background-color: $bg__surface;
                padding: 16px;

                .floorPlanOutline__outline-text {
                    @include typo(lead, bold, 1);

                    strong {
                        @include typo(lead, bold, 1);
                        color: $accent__default;
                    }
                }
            }

            .floorPlanOutline__floor-plan-outline {
                width: 100%;
                height: 100%;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                gap: 16px;

                .floorPlanOutline__change-plan-container {
                    position: absolute;
                    transform: translate(-50%, -50%);
                    left: 50%;
                    bottom: 0px;
                }
            }
        }

        .floorPlanOutline__units-container {
            display: flex;
            flex-direction: column;
            gap: 32px;
            width: 35%;
            background: $bg__surface;
            padding: 24px;
            @include bd-radius(large);
            @include shadow(60);

            .floorPlanOutline__units-save-exit-container {
                display: flex;
                justify-content: space-between;

                .floorPlanOutline__units-guide-select-container {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
    
                    .floorPlanOutline__units-guide-container {
        
                        .floorPlanOutline__units-guide-title {
                            @include typo(body, bold, 1); 
                        }
        
                        .floorPlanOutline__units-guide-info {
                            @include typo(caption, regular, 2);
                           color: $fg__muted;
                        }
                    }
        
                    .form__input-group {
                        margin: 0px;
                        width: fit-content;
        
                        label {
                            display: none;
                        }
        
                        select {
                            margin: 0px;
                            color: $accent__emphasis;
                            @include bd-radius(medium);
                            @include typo(input_medium, bold, 1);
                            @include shadow(40);
                            border: 2px solid $accent__emphasis;
                            padding: 8px 16px;
                        }
                    }
                }
            }

            .floorPlanOutline__units-items-container {
                display: flex;
                flex-direction: column;
                gap: 32px;
                overflow-y: auto;
                padding-right: 8px;

                .floorPlanOutline__units-items {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
    
                    .floorPlanOutline__unit-item-text-icon {
                        display: flex;
                        align-items: center;
                        gap: 12px;
    
                        .unit-item-text {
                           @include typo(small, bold, 2);
                        }
    
                        .floorPlanOutline__checkmark-placeholder {
                            width: 20px;
                            height: 20px;
                            background-color: $accent__subtle;
                            @include shadow(20);
                            border-radius: 100px;
                        }
                    }
    
                    .floorPlanOutline__edit-delete-buttons {
                        display: flex;
                        gap: 24px;
                    }
                }
            }
            .floorPlanOutline__options-container {
                display: flex;
                flex-direction: column;
                gap: 32px;
                margin-top: auto;
                
                .floorPlanOutline__divider {
                    height: 1px;
                    background-color: $bg__muted;
                    margin: 0px -24px;
                }
    
                .floorPlanOutline__button-options {
                    display: flex;
                    align-self: flex-end;
                    gap: 16px;
                }
            }
        }
    }
}
    
.floorPlanOutline__copy-floor-modal {
    border-radius: 8px;
    overflow: hidden;
}



