@import "../../../scss/main.scss";

.infoPopover__container {
    display: flex;
    align-items: center;
    width: fit-content;
    height: fit-content;

    .infoPopover__icon-container {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}