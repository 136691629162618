@import "../../../../../../scss/main.scss";

.imageSelection__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .imageSelection__choose-image-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 24px;
        width: fit-content;
        height: fit-content;
        background-color: $bg__surface;
        @include bd-radius(large);
        @include shadow(60);
        border: 1px solid transparent;

        &.--error {
            border-color: $error__muted;
        }
    
        .imageSelection__choose-image-text-container {
            display: flex;
            flex-direction: column;
            
            .imageSelection__choose-image-title {
                @include typo(body, bold, 1);
            }
    
            .imageSelection__choose-image-info {
                @include typo(caption, regular, 2);
                color: $fg__muted;
            }
        }
    
        .imageSelection__images-container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 14px 10px; 
            
            .imageSelection__image-item {
                aspect-ratio: 2 / 3;
                min-width: 152px;
                height: 160px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                cursor: pointer;
                transition: 0.15s;
                border: 3px solid transparent;
                @include bd-radius(small);
            }
    
            .imageSelection__image-item.active {
                border: 3px solid $accent__default;
            }
        }

        .imageSelection__error {
            color: $error__muted;
        }
    
        .divider {
            height: 1px;
            background-color: $bg__muted;
            margin: 0px -24px;
        }
        
        .imageSelection__choose-image-buttons {
            display: flex;
            gap: 24px;
            justify-content: flex-end;
    
            svg {
                width: 16px;
                height: 16px;
            }
    
            svg > path{
                fill: $bg__surface;
            }
        }
    }
}
