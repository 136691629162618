@import "../../../../scss/main.scss";

.textInput__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    position: relative;

    .textInput__label-info-container {
        display: flex;
        align-items: center;
        gap: 12px;

        label {
            @include typo(small, bold, 2);
    
            span {
                @include typo(small, bold, 1);
                color: $accent__default;
            }
        }
    }


    input {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include typo(input_small, regular, 1);
        padding: 8px 16px;
        border: 2px solid $input__border-default;
        background: $bg__surface;
        transition: 0.15s;
        outline: none;
    }

    input::placeholder {
        @include typo(input_small, regular, 1);
        color: $input__placeholder;
    }

    input:focus {
        border-color: $accent__muted;
    }
     
    .input__text.--valid {
        border-color: $success__muted;
    }
    .input__text.--denied {
        border-color: $error__muted;
    }

    /* Other elements */
    .input__text-error {
        position: absolute;
        top: calc(100% + 0px);
        right: 0px;
        @include typo(caption, regular, 1);
        color: $error__muted;
    }
}
