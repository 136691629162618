/*.main-container,
.main-container--dashboard {
    height: fit-content;
}*/
.listing-container {
    width: 100%;
    position: relative;
}
.listing__form-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #2b2b2b;
    position: fixed;
    height: 100%;
    top: 0;
    right: 0;
    justify-content: center;
    z-index: 9;
    padding: 0;
    width: 33.3333333333%;
    flex: 0 0 auto;
    transition: 0.5s cubic-bezier(0.5, 0, 0, 1);
}
.listing__form-container--closed {
    transform: translateX(100%);
}
.listing__form {
    padding: 0 48px;
}
.listing__form-header {
    text-align: center;
}
.listing__form-title {
    font-weight: 700;
    color: #ffffff;
}
.listing__form-tagline {
    margin-bottom: 32px;
    color: #ffffff;
    line-height: 1.5;
}
.listing__form-tagline > div {
    text-transform: uppercase;
    color: #ffffff;
    line-height: 1.5;
    padding-top: 8px;
    font-weight: 600;
    letter-spacing: 0.2px;
}
.listing__form-container label {
    color: #ffffff;
}
.listing__form-container .btn-primary {
    width: fit-content;
    margin: 32px auto 24px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.5px;
    padding: 0.75rem 1.5rem;
}
.listing__thank-you-container {
    color: #ffffff;
}

.listing__page-container {
    width: 100%;

}
.listing__hero-section {
    height: 100vh;
    width: 100%;
    background-image: url(../../assets/images/listing/texture_1.svg);
    background-blend-mode: saturation;
    background-size: cover;
    background-position: bottom;
    background-color: #ECECEC99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.listing__left-col {
    position: relative;
    flex: 0 0 66.666667%;
    width: 66.6666666667%;
    padding: 0 120px;
}
.listing__hero-project-title {
    position: absolute;
    top: 48px;
    left: 48px;
}
.listing__jumbotron-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.listing__jumbotron {
    padding: 32px;
    background: transparent;
    max-width: 800px;
}
.listing__jumbotron-vp {
    display: block;
    padding: 16px 24px;
    background: #ff9401;
    color: #ffffff;
    font-weight: 700;
    font-size: 24px;
}
.listing__jumbotron-logo{
    height: 64px;
}
.listing__jumbotron-name {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 32px;
}
.listing__jumbotron-title {
    font-size: 60px;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
}
.listing__jumbotron-status {
    font-size: 24px;
    margin: 16px 0;
    text-transform: uppercase;
}
.listing__jumbotron-status > div {
    display: inline-block;
    margin: 0 12px 7px;
    width: 32px;
    height: 2px;
    background: #ff5500;
}
.listing__jumbotron-date {
    font-size: 28px;
    font-weight: 700;
    margin: 16px 0;
    text-transform: uppercase;
}
.listing__jumbotron-units {
    display: flex;
    font-size: 20px;
    align-items: center;
}
.listing__icon-bed {
    padding-right: 8px;
}
.listing__unit-types{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    grid-gap: 8px 0;
}
.listing__unit-types > div{
    padding: 0 16px;
    font-weight: 600;
}
.listing__unit-types > div:not(:last-child){
    border-right: 2px solid #333333;
}

.listing__big-image-container {
    position: relative;
    height: 95vh;
    width: 100%;
    overflow: hidden;
}
.listing__big-image {
    background-image: url(../../assets/images/listing/project_image.jpg);
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}
.listing__info-section {
    position: relative;
    padding: 48px 0;
    background-image: url(../../assets/images/listing/texture_3.svg);
    background-blend-mode: saturation;
    background-size: cover;
    background-position: top;
    background-color: #ECECECcc;
}
.listing__info-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #ECECEC66 75%, #ECECECff 100%);
    z-index: -1;
}
.listing__info-title-box--top {
    position: absolute;
    bottom: calc(100% + 48px);
    max-width: calc(100% - 240px);
}
.listing__info-title {
    margin: 0;
    width: fit-content;
    text-align: left;
    color: #ffffff;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1.5;
}
.listing__info-title-box {
    width: fit-content;
    margin: 0;
    padding: 24px 48px;
    background-image: url(../../assets/images/listing/texture_2.svg);
    background-blend-mode: overlay;
    background-size: cover;
    background-position: bottom;
    background-color: #2b2b2b;
    color: #ffffff;
    box-shadow: 0 4px 14px rgb(0 0 0 / 20%);
}
.listing__info-title-box--location {
    width: 100%;
    text-align: center;
    margin-top: 64px;
}
.listing__info-title-box--inclusions {
    width: 100%;
    text-align: center;
    margin-bottom: 48px;
}
.listing__info-title-box--location > .listing__info-title {
    max-width: fit-content;
    margin: 0 auto;
}
.listing__info-title-box--inclusions > .listing__info-title {
    max-width: fit-content;
    margin: 0 auto;
}
.listing__lines-container {
    margin: 64px 0;
    width: 100%;
}
.listing__lines-item {
    padding: 8px 12px 8px 32px;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: 16px;
}
.listing__lines-item::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    background-color: #ff9401;
    border-radius: 100%;
}
.listing__lines-item::after {
    content: "";
    position: absolute;
    left: 32px;
    bottom: -2px;
    width: calc(100% - 32px);
    border-bottom: 1px solid #bbbbbb;
}
.listing__lines-item-value {
    display: flex;
}
.listing__lines-item-value--units > div {
    padding-left: 16px;
}
.listing__lines-item-value--units > div:not(:last-child) {
    padding-right: 16px;
    border-right: 1px solid #777777;
}
.listing__amenities-icons-container {
    display: flex;
    gap: 64px;
    margin-bottom: 48px;
}
.listing__primary-amenity {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}
.listing__primary-amenity > img {
    width: 112px;
}
.listing__primary-amenity > div {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 18px;
}
.listing__description-title {
    font-size: 24px;
    margin: 32px 0;
    font-weight: 600;
}
.listing__partners-container {
    padding-top: 32px;
    padding-bottom: 32px;
}
.listing__partners-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 32px;
}
.listing__map-address-container {
    position: relative;
    bottom: 48px;
    left: 24px;
    padding: 8px;
    background: #ffffff;
    border-radius: 4px;
    font-weight: 600;
    box-shadow: 0 2px 14px -7px rgba(0,0,0,0.2);
    border: 1px solid #ff9401;
}
.listing__footer {
    background-color: #222222;
    padding: 40px 0 24px;
}
.listing__footer p {
    color: #ffffff;
    font-weight: 300;
    font-size: 12px;
}
.listing__footer a {
    color: #ffffff;
    opacity: 0.7;
    transition: 0.15s;
    display: block;
    width: fit-content;
    margin: 16px auto 0;
    text-decoration: none;
    font-size: 12px;
}
.listing__footer a:hover {
    opacity: 1;
    text-decoration: underline;
}