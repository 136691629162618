.videoCall__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  gap: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
}

.videoCall__participants-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.videoCall__participant {
  position: relative;
  width: 100%;
  height: 240px;
  overflow: hidden;
  border-radius: 8px;
  border: 6px solid rgb(0 0 0 / 75%);
}

.videoCall__participant-video-container {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.videoCall__participant-video {
  position: absolute;
  background: #000000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: contain;
}

.videoCall__participant-name {
  position: absolute;
  bottom: 0;
  left: 0;
  border-top-right-radius: 3px;
  background: rgb(0 0 0 / 60%);
  color: #ffffff;
  padding: 6px 8px 6px 6px;
  font-size: 14px;
}

.videoCall__toolbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 8px;
  background-color: rgb(0 0 0 / 75%);
  border-radius: 8px;
}

.videoCall__toolbar-item {
  cursor: pointer;
  color: #ffffff;
  height: 100%;
  padding: 8px;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
  transition: 0.15s;
  background-color: rgb(255 255 255 / 5%);
}

.videoCall__toolbar-item:hover {
  background-color: rgb(255 255 255 / 15%);
}