@import "../../../../scss/main.scss";

.campaignLaunch__buttons-bar {
    display: flex;
    justify-content: flex-end;
}

.campaignLaunch__container  {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    width: 100%;

    .campaignLaunch__image-container > img {
        display: flex;
        width: 500px;
    }

    .campaignLaunch__text {
        @include typo(headline_3, bold, 1);
    }

    .campaignLaunch__setup-text {
        @include typo(body, regular, 1);
    }
}
