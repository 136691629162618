@import "../../../../../scss/main.scss";

.tableInput__number-container {
    display: flex;
    height: 100%;
}

input[type=number].tableInput__number {
    width: 100%;
    height: fit-content;
    height: 100%;
    padding: 8px;
    border-radius: 2px;
    border: 2px solid $input__border-default;
    transition: 0.15s;
    text-align: right;
}

input[type=number].tableInput__number:focus {
    border: 2px solid $accent__default;
    outline: none;
    box-shadow: 0 2px 8px -2px rgb(61 83 245 / 50%);
}
/* 
input[type=number].tableInput__number.--price { 
    width: 84px;
} */

.tableInput__number + span {
    display: block;
    border: 2px solid $input__border-default;
    border-left: none;
    height: 100%;
    padding: 8px 16px;
    background: $bg__surface;
    color: $accent__emphasis;
    @include typo(caption, bold, 1);
}

.tableInput__number:focus + span {
    border: 2px solid $accent__default;
    border-left: none;
    box-shadow: 0 2px 8px -2px rgb(61 83 245 / 50%);
}