.popover__portal{
    position: fixed;
    z-index: 12;
    visibility: visible;
    transition: none;

    &.--closed {
        visibility: hidden;
        transition: 0s 0.3s visibility;
    }
}

.popover__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;

    &.--visible {
        background: rgba(0 0 0 / 8%);
    }
}
.popover__container {
    position: absolute;
    opacity: 1;
    width: max-content;
    z-index: 1000;
    transition: 0.3s opacity, 0.3s transform;
}

.popover__container.--closed {
    opacity: 0;
    transition: 0.15s opacity, 0.15s transform;
}

.popover__container.--s {
    top: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);   
    
    &.--closed {
        transform: translate(-50%, 8px);
    }
}

.popover__container.--se {
    top: calc(100% + 8px);
    left: 0;
    
    &.--closed {
        transform: translate(0, 8px);
    }
}

.popover__container.--sw {
    top: calc(100% + 8px);
    right: calc(100% + 16px);
    
    &.--closed {
        transform: translate(0, 8px);
    }
}

.popover__container.--n {
    bottom: calc(100% + 8px);
    transform: translate(-50%, 0%);
}

.popover__container.--nw {
    bottom: calc(100% + 8px);
    right: 0;
}