@import "../../../../../../../scss/main.scss";

.selectOptionPanel__container {
    width: 50%;
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.selectOptionPanel__helper-container {
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.selectOptionPanel__label-info-container{
    display: flex;
    gap: 8px;
}

.selectOptionPanel__label-info-container > .form__input-group, 
.selectOptionPanel__label-info-container > .form__input-group > label {
    margin: 0px;
}

.selectOptionPanel__info-icon-container {
    position: relative;
}

.selectOptionPanel__info-icon-container > svg,
.selectOptionPanel__sort-container {
    cursor: pointer;
}

.selectOptionPanel__options {
    display: none;
}

.selectOptionPanel__options.--active {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 200px;
    overflow: auto;
}

.selectOptionPanel__option-item-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.selectOptionPanel__option-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    border: 2px solid #E3E8EF;
    border-radius: 5px;
    padding: 8px 0px;
}

.selectOptionPanel__remove_item {
    cursor: pointer;
}

.selectOptionPanel__remove_item > svg > path:nth-child(1) {
    fill: var(--white);
}

.selectOptionPanel__remove_item > svg > path:nth-child(2) {
    fill: rgb(199, 199, 192)
}

.selectOptionPanel__input-options {
    display: flex;
    gap: 16px;
    height: 100%;

    .form__input-group {
        height: 100%;
    }
}

.selectOptionPanel__input-options textarea {
    padding: 12px; 
}

.selectOptionPanel__input-options > .form__input-group {
    width: 100%;
}

.selectOptionPanel__checkmark {
    align-self: flex-end;
    margin-bottom: 64px;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
}
