@import "../../../../../scss/main.scss";

select.tableInput__select {
    width: 100%;
    border: 2px solid $input__border-default;
    padding: 8px;
    border-radius: 2px;
    transition: 0.15s;
}
select.tableInput__select:focus {
    border: 2px solid $accent__default;
    box-shadow: 0 2px 8px -2px rgb(61 83 245 / 50%);
}