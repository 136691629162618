@import "../../../../../scss/main.scss";

.inCompleteForm__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;

    .inCompleteForm__progress-bar {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 64px;
        
        .inCompleteForm__progress-text {
            @include typo(headline_3, bold, 1);
        }
    }
    .inCompleteForm__fields-text-container {
        @include typo(body, regular, 1);
        color: $fg__default;
    }
}