@import "../../../../scss/main.scss";

.multi-select__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .multi-select__label {
        @include typo(small, bold, 2);

        span {
            @include typo(small, bold, 1);
            color: $accent__default;
        }
    }

    .multi-select__inner-container {
        position: relative;
        user-select: none;
        margin-bottom: 24px;
        width: 100%;

        .multi-select__input-box {
            position: relative;
            display: flex;
            cursor: pointer;
            padding: 8px 48px 8px 16px;
            border: 2px solid $input__border-default;
            background: $bg__surface;
            transition: 0.15s;
            outline: none;
            -webkit-appearance: none;
            appearance: none;
            @include typo(input_small, regular, 1);
            background-image: url("../../../../assets/images/icons/cabana_cheveron_down.svg");
            background-repeat: no-repeat, repeat;
            background-position: right 16px center;

            .multi-select__current-selection {
                display: inline-flex;
                align-items: center;
                width: max-content;

                span {
                    display: inline-flex;
                    background: $accent__default;
                    color: $fg__on-accent;
                    border-radius: 100%;
                    height: 24px;
                    width: 24px;
                    align-items: center;
                    justify-content: center;
                    margin: 1px 8px 0 0;
                    font-size: 14px;
                    position: relative;
                    @include typo(input_medium, bold, 1);
                }

                div {
                    @include typo(input_medium, regular, 1);
                }

                .multi-select__placeholder {
                    @include typo(input_medium, regular, 1);
                    color: $input__placeholder;
                }
            }
        }

        .multi-select__input-box:hover,
        .multi-select__input-box.--open {
            border-color: $accent__default;
        }

        .multi-select__dropdown-backdrop {
            position: fixed;
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            z-index: 999;

            &.--hidden{
                display: none;
            }
        }

        .multi-select__dropdown {
            position: absolute;
            // top: calc(100% + 8px);
            max-width: 600px;
            width: 100%;
            max-height: 320px;
            overflow-y: scroll;
            border: 1px solid #E2E8F0;
            padding: 0px 8px 8px;
            @include bd-radius(medium);
            z-index: 1000;
            background: $bg__surface;
            @include shadow(60);
            transition: 0.3s transform, 0.15s opacity;
            margin-bottom: 32px;
        }
        .multi-select__dropdown.--closed {
            visibility: hidden;
            opacity: 0;
            transform: translateY(-8px);
        }
        
        .multi-select__item-container {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 0px 16px;
        }
    }
}
