@import "../../../../../../scss/main.scss";

.buildingOutline__container {
    width: 100%;
    height: 100%;
    display: flex;

    .buildingOutline__split-container {
        display: flex;
        width: 100%;
        height: 100%;
        gap: 24px;

        .buildingOutline__building-container {
            display: flex;
            flex-direction: column;
            width: 65%;
            height: 100%;
            @include bd-radius(medium);
            @include shadow(60);
            overflow: hidden;
            position: relative;

            .buildingOutline__outline-change-image {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                background-color: $bg__surface;
                padding: 16px;

                .buildingOutline__outline-text {
                    @include typo(lead, bold, 1);
                }

                .buildingOutline__change-image {
                    padding: 6px 16px;
                    @include typo(button_small, bold, 1);
                    border: 2px solid $accent__muted;
                    color: $accent__muted;
                    @include bd-radius(small);
                    cursor: pointer;
                } 
            }

            .buildingOutline__building-outline {
                width: 100%;
                height: 100%;
                overflow: hidden;
            }

            .buildingOutline__building-infobox {
                width: fit-content;
                position: absolute;
                bottom: 24px;
                left: 24px;
                background-color: $bg__surface;
                padding: 6px 16px;
                @include bd-radius(medium);
               
                
                .buildingOutline__infobox-close {
                    position: absolute;
                    top: 4px;
                    right: 4px;
                    cursor: pointer;

                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }

                .buildingOutline__infobox {
                    display: flex;
                    gap: 16px;
                    align-items: center;

                    svg {
                        width: 32px;
                        height: 32px;
                    }

                    .buildingOutline__infobox-text {
                        @include typo(x_small, bold, 1);
                        padding-right: 16px;
                    }
                }
                &.--active {
                    opacity: 1;
                    transition: opacity 3s;
                }
                &.--closed {
                    opacity: 0;
                    transition: opacity 3s;
                }
            }
            
        }

        .buildingOutline__floors-container {
            display: flex;
            flex-direction: column;
            gap: 32px;
            width: 35%;
            background: $bg__surface;
            padding: 24px;
            border: 1px solid transparent;
            @include bd-radius(large);
            @include shadow(60);

            &.--error {
                border-color: $error__muted;
            }

            .buildingOutline__floors-button-error-container {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;

                .buildingOutline__floors-error {
                    color: $error__muted;
                }
            }

            .buildingOutline__floors-guide-container {

                .buildingOutline__floors-guide-title {
                   @include typo(body, bold, 1); 
                }
                .buildingOutline__floors-guide-info {
                   @include typo(caption, regular, 2);
                   color: $fg__muted;
                }
            }
            
            .buildingOutline__floors-items-container {
                display: flex;
                flex-direction: column;
                gap: 32px;
                overflow-y: auto;
                padding-right: 8px;

                .buildingOutline__floors-items {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
    
                    .buildingOutline__floor-item-text-icon {
                        display: flex;
                        align-items: center;
                        gap: 12px;
    
                        .floor-item-text {
                           @include typo(small, bold, 2);
                        }
    
                        .buildingOutline__checkmark-placeholder {
                            width: 20px;
                            height: 20px;
                            background-color: $accent__subtle;
                            @include shadow(20);
                            border-radius: 100px;
                        }
                    }
    
                    .buildingOutline__edit-delete-buttons {
                        display: flex;
                        gap: 24px;
                    }
                }
            }
            .buildingOutline__options-container {
                display: flex;
                flex-direction: column;
                gap: 32px;
                margin-top: auto;
                
                .buildingOutline__divider {
                    height: 1px;
                    background-color: $bg__muted;
                    margin: 0px -24px;
                }

                .buildingOutline__button-options {
                    display: flex;
                    align-self: flex-end;
                    gap: 16px;
                }
            }
        }
    }
}

.buildingOutline__change-image-warning {
    background-color: $bg__surface;
    padding: 24px;
    @include bd-radius(medium);
    display: flex;
    flex-direction: column;
    gap: 24px;

    .buildingOutline__warning-text {
        @include typo(body, regular, 1);
    }

    .buildingOutline__warning-button {
        display: flex;
        align-self: flex-end;
        gap: 16px;
    }
}