.floorsPagination__container {
    display: flex;
    margin-top: 32px;
    overflow-x: auto;
}

.floorsPagination__container.--unit-details {
    margin-top: 0px;
}

.floorsPagination__container.--unit-details > .floorsPagination__item:first-child {
    margin-left: auto;
}

.floorsPagination__container.--unit-details > .floorsPagination__item:last-child {
    margin-right: auto;
}

.floorsPagination__item {
    padding: 16px 32px;
    background: #F2F2F2;
    font-weight: 600;
    color: #3D59EC;
    text-align: center;
    cursor: pointer;
}

.floorsPagination__item:hover {
    background: #eeeeee;
    transition: 0.2s;
}

.floorsPagination__item:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.floorsPagination__item:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.floorsPagination__item.--current {
    background: #3D59EC;
    color: #ffffff;
    transition: 0.2s;
}