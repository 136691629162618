@import "../../../../scss/main.scss";

.overViewTags__container {
    display: flex;
    width: fit-content;
    justify-content: center;
    padding: 6px 24px;
    border-radius: 1000px;
    background-color: $accent__default;
    
    .overViewTags__text {
        color: $fg__on-accent;
        @include typo(button_small, bold, 1);
    }
}
