@import "../../../../scss/main.scss";

.inclusionsList__container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px;
    width: 100%;
    height: fit-content;
    position: relative;
    overflow-y: hidden;
    transition: 0.15s;
    background: $bg__surface;
    @include shadow(60);
    @include bd-radius(medium);
}

.inclusionsList__container.--open {
    max-height: initial;

    .inclusionsList__inner-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 32px 88px;
        
        .inclusionsList__wrapper {
            display: flex;
            flex-direction: column;
            gap: 16px;
            flex-grow: 1;
            width: 100%;

            .inclusionsList__title {
               @include typo(body, bold, 1);
            }

            .inclusionsList__divider {
                height: 1px;
                width: 100%;
                background-color: $bg__muted;
            }

            .inclusionsList__list {
                display: flex;
                flex-direction: column;
                gap: 20px;
                flex-wrap: wrap;
                max-height: 800px;

                .inclusionsList__item {
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    @include typo(caption, regular, 1);
                    
                    div {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1680px) {
    .inclusionsList__container.--open {
        .inclusionsList__inner-container {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

// .inclusionsList__great-title {
//     color: #3D59EC;
//     background: #edf2f7;
//     font-weight: 500;
//     padding: 8px 16px;
//     width: fit-content;
//     border-radius: 4px;
//     margin-left: 16px;
//     margin-top: 48px;
//     z-index: 10;
// }



// .inclusionsList__list {
//     display: flex;
//     flex-direction: column;
//     gap: 16px;
//     flex-wrap: wrap;
//     max-height: 800px;
// }

// .inclusionsList__list.--open {
//     max-height: initial;
//     height: fit-content;
// }

// .inclusionsList__title {
//     font-weight: 500;
//     border-bottom: 1px solid #cccccc;
//     padding-bottom: 4px;
//     font-size: 14px;
//     margin-bottom: 16px;
// }

// .inclusionsList__see-more {
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background: linear-gradient(0deg, rgb(248 250 252 / 100%) 0%, rgb(248 250 252 / 95%) 20%, rgb(248 250 252 / 80%) 50%, rgb(248 250 252 / 0%) 100%);
//     padding: 64px 32px 24px 32px;
//     z-index: 100;
//     cursor: pointer;
//     font-weight: 600;
//     color: #777777;
//     flex-direction: column;
//     gap: 4px;
//     transition: 0.15s;
//     opacity: 1;
//     user-select: none;
// }
// .inclusionsList__see-more::after {
//     content: "";
//     width: 8px;
//     height: 8px;
//     border-bottom: 3px solid #3D59EC;
//     border-right: 3px solid #3D59EC;
//     transform: rotate(45deg);
//     border-radius: 2px;
// }

// .inclusionsList__container.--open > .inclusionsList__see-more {
//     opacity: 0;
// }

// .inclusionsList__item {
//     position: relative;
//     padding-left: 16px;
//     font-size: 14px;
// }

// .inclusionsList__item::before {
//     content: "";
//     position: absolute;
//     left: 0;
//     top: 50%;
//     transform: translateY(-50%);
//     width: 4px;
//     height: 4px;
//     background: #3D59EC;
//     border-radius: 100%;
// }
