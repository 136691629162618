@import "../../../../scss/main.scss";

.addressInput__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;

    .addressInput__address-box {
        display: flex;
        flex-direction: column;
        gap: 6px;

        label {
            @include typo(small, bold, 2);
    
            span {
                @include typo(small, bold, 1);
                color: $accent__default;
            }
        }

        .autocomplete-container {
            position: relative;

            .location-search-input {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                @include typo(input_small, regular, 1);
                padding: 8px 16px;
                border: 2px solid $input__border-default;
                background: $bg__surface;
                transition: 0.15s;
                outline: none;
    
                &:focus {
                    border: 2px solid $accent__default;
                }

                &.--valid {
                    border-color: $success__muted;
                }

                &.--denied {
                    border-color: $error__muted;
                }
            }
    
            .autocomplete-dropdown-container {
                position: absolute;
                z-index: 1;
                top: 100%;
                @include shadow(40);
                overflow: hidden;

                &.--active {
                    border: 2px solid $accent__default;
                    border-top: none;
                }

                .suggestion-item {
                    background: $bg__surface;
                    padding: 12px 16px;
                    cursor: pointer;

                    &:not(:last-child) {
                        border-bottom: 1px solid $border__default;
                    }
                    
                    &.--active {
                        background: $bg__subtle;

                        span {
                            color: $accent__default;
                        }
                    }
                }
            }
        }
    }
    .addressInput__error {
        position: absolute;
        top: calc(100% + 0px);
        right: 0px;
        @include typo(caption, regular, 1);
        color: $error__muted;
    }
}