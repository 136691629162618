@import "../../../scss/helpers/variables.scss";
@import "../../../scss/helpers/mixins.scss";

.documents__container {
    width: 562px;
    height: fit-content;
    max-height: calc(100vh - 148px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    @include bd-radius(large);

    .documents__item-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        height: fit-content;
        overflow: auto;
        width: 100%;
        background: $bg__subtle;
        padding: 36px 58px;

        .documents__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            background: $bg__surface;
            padding: 16px;
            transition: 0.15s;
            border: 1px solid $bg__surface;
            @include bd-radius(large);
            @include shadow(40);

            .documents__icon-name-size-container {
                display: flex;
                align-items: center;
                gap: 24px;

                .documents__icon-container > .fileIcon__container {
                    width: 36px;
                    height: 44px;
                    margin: 0px;
                }

                .documents__icon-container > .fileIcon__container > .fileIcon__type {
                    position: absolute;
                    bottom: 8px;
                    right: -8px;
                    padding: 2px 4px;
                    @include typo(tiny, bold, 1);
                    
                }

                .documents__info-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 4px;

                    .documents__name {
                        @include typo(caption, bold, 1);
                        color: $fg__default;
                    }

                    .documents__size {
                        @include typo(x_small, regular, 2);
                        color: $fg__default;
                    }
                }
            }

            .documents__single-download {
                display: flex;
                align-items: center;
                gap: 6px;
                color: $accent__default;
                @include typo(button_small, bold, 1);
                cursor: pointer;
                visibility: hidden;
                opacity: 0;
                transition: 0.15s opacity;
            }
        }

        .documents__item:hover {
            border: 1px solid $accent__muted;
            @include shadow(60);

            .documents__single-download {
                opacity: 1;
                visibility: visible;
                transition: 0.15s opacity, 0.15s 0s visibility;
            }
        }
    }
    .documents__download-all {
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0px;
        width: 100%;
        background: $bg__subtle;
        padding: 24px;

        .button.button__primary {
            align-items: center;
            gap: 10px;
            padding: 6px 16px;
            @include bd-radius(small);
            @include typo(button_medium, bold, 1);
        }
    }
}