.errorMessage__container { 
    display: flex;
    padding: 16px;
    width: fit-content;
    height: fit-content;
    position: relative;
    background-color: var(--white);
    border-radius: 8px;
    gap: 24px;
}

.errorMessage__errorbar { 
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 10px;
    border-radius: 8px 0px 0px 8px;
    background-color: #EB625F;
}

.errorMessage__icon-and-text { 
    display: flex;
    align-items: center;
    gap: 16px;
    padding-left: 16px;
}

.errorMessage__code-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.errorMessage__code { 
    font-size: 24px;
    font-weight: 500;
    color: #555555;
}

.errorMessage__text { 
    font-size: 14px;
    color: #898c90
}

.errorMessage__close-container { 
    display: flex;
    gap: 16px;
    cursor: pointer;
}

.errorMessage__seperator { 
    height: 100%;
    width: 0px;
    border: 1px solid #CCCCCC;
}

.errorMessage__close { 
    font-size: 14px;
    color: #898c90;
    align-self: center;
}

