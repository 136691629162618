@import "../../../scss/main.scss";

.tooltip__portal{
    position: fixed;
    z-index: 10;
}

.tooltip__container {
    background: rgb(0 0 0 / 80% );
    position: absolute;
    bottom: calc(100% + 12px);
    left: 50%;
    transform: translate(-50%, 0px);
    opacity: 1;
    color: #ffffff;
    font-size: 12px;
    max-width: 200px;
    width: max-content;
    padding: 4px 8px;
    @include bd-radius(medium);
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.25), 0px 4px 8px rgba(48, 49, 51, 0.5);
    z-index: 1000;
    transition: 0.15s transform, 0.15s opacity;
    pointer-events: none;
}

.tooltip__container.--closed {
    transform: translate(-50%, 8px);
    opacity: 0;
    visibility: hidden;
    transition: 0.15s transform, 0.15s opacity, 0s 0.15s visibility;
}

.tooltip__container::after {
    position: absolute;
    content: "";
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 8px solid rgb(0 0 0 / 75%);
}