@import "../../../../../../../scss/main.scss";

.selectColumnType__container {
    display: flex;
    font-weight: 400; 
    border-radius: 6px; 
    padding: 12px 16px; 
    line-height: 1.5; 
    border: 1px solid #E2E8F0; 
    transition: all 0.15s ease; 
    width: 100%; 
    outline: none; 
    cursor: pointer;
    position: relative;
    
    @include typo(caption, regular, 1);
}

.selectColumnType__container.--active{
    border: 1px solid var(--blue);
}

.selectColumnType__selected-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: $fg__default;
}

.selectColumnType__selected-value::after {
    content: '';
    width: 0px;
    height: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $fg__default;
}

.selectColumnType__select-container {
    display: none;
    z-index: 5000;
}

.selectColumnType__select-container.--active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% + 8px);
    left: 0px;
    height: 320px;
    width: 100%;
    overflow: auto;
    background-color: $bg__surface;
    border: $accent__muted;
    z-index: 5000;

    @include bd-radius(medium);
    @include shadow(60);
}

.selectColumnType__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 100;
}

.selectColumnType__item {
    padding: 8px 16px;
    border-bottom: 1px solid $accent__subtle;
    z-index: 5000;
}

.selectColumnType__item:hover {
   background-color: #eeeeeeb4;
}

