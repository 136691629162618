.amt-header-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.amt-header-info > div {
    text-align: right;
}
.amt-header-date {
    justify-self: end;
}
.amt-header-details {
    display: grid;
    grid-template-columns: 10% 90%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 0.8rem;
}
.amt__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(640px, 1fr));
    grid-gap: 1rem;
}
.amt-field__value-card {
    background: white;
    border-radius: 18px;
    margin: 7px;
    padding: 20px;
    border: 1px solid white;
}
.pending {
    background: #fff3cd;
    border-color: #ffcd39;
}
.validated{
    background: #d1e7dd;
    border-color: #479f76;
}
.denied {
    background: #f8d7da;
    border-color: #e35d6a;
}
.amt-field__value-card-text {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}
.amt-field__value-card-title {
    width: 90%;
    font-size: 0.8rem;
    padding-bottom: 10px;
    font-weight: bold;
}
.amt-field__value-card-comment-title {
    font-size: 0.8rem;
    padding-bottom: 10px;
}
.amt-field__value-card-input-container{
    display: flex;
    width: 100%;
}
.amt-field__value-card-input,
.amt-field__value-card-field_value-input {
    width: 30%;
    padding: 2px 0.75rem;
    transition: all 0.15s ease;
}
.amt-field__value-card-input{
    margin-right: 56px;
}
.amt-field__value-card-field_value-input {
    margin-right: 5px;
}
.amt-field__value-card-input-container > button{
    height: fit-content;
}
.amt-field__value-card-comment-field {
    padding: 0 0 75px;
    width: 100%;
    font-size: 0.8rem;
}
.mt-field__value-card-denied-validate-container{
    margin-top: 23px;
    display: flex;
    gap: 52px;
    justify-content: center;
}
.mt-field__value-card-denied-validate-container > button{
    padding: 10px 48px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    font-size: 0.8rem;
    font-weight: 600;
    /* background-color: #F8D7DA;
    background-color: #D1E7DD; */
}
.amt-field__value-card-denied{
    background-color: #F8D7DA;
}
.amt-field__value-card-validate{
    background-color: #D1E7DD;
}

.amt-field__value-card-save {
    font-size: 0.8rem;
    margin-top: 23px;
}
.amt-field_value-select {
    font-size: 0.8rem;
    margin-top: 23px;
}