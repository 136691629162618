@import "../../../../../../scss/main.scss";

.image__drag-images-item {
    aspect-ratio: 3 / 2;
    min-width: 240px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: move;
    position: relative;

    .image__cover-image-icon {
        position: absolute;
        left: 4px;
        top: -8px;
    }

    .image__delete-image {
        display: flex;
        height: fit-content;
        width: fit-content;
        position: absolute;
        background: $bg__surface;
        bottom: 4px;
        right: 4px;
        padding: 4px;
        cursor: pointer;
        @include bd-radius(medium);
        @include shadow(60);
    }
}

@media only screen and (max-width: 1063px) { 
    .image__drag-images-item { 
        width: calc(50% - 16px)
    }
}