@import "../../../scss/helpers/variables.scss";
@import "../../../scss/helpers/mixins.scss";

.contactUsCard__container {
    position: fixed;
    bottom: 20px;
    right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    background-color: $bg__surface;
    padding: 24px;
    cursor: pointer;
    z-index: 5;
    @include bd-radius(large);
    @include shadow(100);

    .contactUsCard__text {
        @include typo(body, bold, 1);
        color: $fg__default;
    }

    .contactUsCard__message-bubble-icon {
        display: flex;
        align-items: center;
        svg {
            width: 32px;
            height: 32px;
        }
    
        svg > path {
            fill: $accent__default;
        }
    }
}

.contactUsCard__container.--active {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: unset;
    cursor: unset;

    .contactUsCard__close-button {
        position: absolute;
        top: 12px;
        right: 16px;
        cursor: pointer;

        svg > path {
            fill: $fg__disabled;
        }
    }

    .contactUsCard__text-container {
        display: flex;
        flex-direction: column;
        gap: 4px;

        h5 {
            margin: 0px;
            @include typo(headline_5, bold, 1);
            color: $fg__default;
        }
    }

    .contactUsCard__info-container {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .contactUsCard__mail-info,
        .contactUsCard__phone-info {
            display: flex;
            align-items: center;
            gap: 10px;

            .contact-info {
                @include typo(small, regular, 1);
                color: $fg__default;
            }
        }
    }

    .contactUsCard__divider {
        display: flex;
        align-items: center;
        gap: 8px;

        .divider {
            border: 1px solid $bg__muted;
            width: 100%;
        }
    }

    .contactUsCard__buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .button.button__primary {
            justify-content: center;
            align-items: center;
            gap: 10px;
            width: 100%;
            @include typo(button_medium, bold, 1);
            @include bd-radius(small);
        }
    }
}