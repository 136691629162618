.topbar {
    position: fixed;
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: center;
    box-shadow: 0 2px 14px -7px rgba(0,0,0,0.2);
    z-index: 100;
    top: 0px;
    background: #ffffff;
}
.topbar--no-shadow {
    box-shadow: none;
}
/*Virtual Sales Office*/
.topbar__container-vso{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 32px 0 64px;
    background: #ffffff;
}
.topbar__left-container--vso{
    position: relative;
    width: 112px;
}
.topbar__left-logo-text-vso{
    font-size: 18px;
    font-weight: 800;
    line-height: 0.8;
}
.topbar__vso-logo {
    position: absolute;
    z-index: 1;
    inset: -16px auto auto 32px;
    padding: 24px 32px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 
        0 18px 32px rgb(0 0 0  / 7%),
        0 6px 24px rgb(0 0 0 / 5%),
        0 3px 12px rgb(0 0 0 / 3.5%),
        0 0 6px rgb(0 0 0 / 2%);
    cursor: pointer;
    transition: 0.3s;
}
.topbar__vso-logo > img {
    max-height: 48px;
    transition: 0.3s;
}
.topbar__vso-logo.--minimized {
    padding: 0;
    box-shadow: none;
    inset: -20px auto auto 32px;
}
.topbar__vso-logo.--minimized > img {
    max-height: 40px;
}

.topbar__center-container--vso{
    display: flex;
    align-items: center;
    padding: 0 24px;
    justify-content: center;
    gap: 48px;
    flex-grow: 1;
    height: 100%;
}
.topbar__center-container--vso > a {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    position: relative;
    color: #7a7a7a;
    transition: 0.15s;
    padding: 0 8px;
    cursor: pointer;
    height: 100%;
}
.topbar__center-container--vso > a::before {
    content: "";
    position: absolute;
    top: calc(50% + 12px);
    left: 50%;
    transform: translateX(-50%);
    background-color: #3d59ec;
    height: 2px;
    width: 0;
    border-radius: 4px;
    transition: 0.15s;
}
.topbar__center-container--vso > a.--active,
.topbar__center-container--vso > a:hover {
    color: #333333;
    text-shadow: 0 0 #333333;
}
.topbar__center-container--vso > a.--active::before {
    width: 32px;
}
.topbar__right-container--vso{
    display: flex;
    align-items: center;
    gap: 24px;
}

/*Listing, MarketTests, Overview*/
.topbar__container {
    width: 1400px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;
}
.topbar__logo {
    padding: 16px 0;
}
.topbar__logo > img {
    height: 100%;
}
.topbar__logo--left {
    position: absolute;
    left: 64px;
    top: 50%;
    transform: translateY(-50%);
}
.topbar__center-container {
    margin: 0 auto;
    display: flex;
    align-items: center;
}
.topbar__right-container {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    height: 100%;
    padding-right: 64px;
}
.topbar__notifications-container {
    padding: 0 32px;
    border-right: 1px solid #F4F4F4;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.notifications__button {

}
.topbar__profile-container {
    position: relative;
    padding: 0 32px;
    height: 100%;
    display: flex;
    align-items: center;
}
.topbar__profile-button {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.topbar__profile-icon {
    width: 40px;
    height: 40px;
    background: #f4f4f4;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.topbar__profile-icon > span {
    font-weight: 700;
    color: #5E6563;
    text-transform: uppercase;
}
.topbar__profile-name {
    padding: 0 16px;
}
.topbar__profile-caret {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 8px 0 8px;
    border-color: #FF7600 transparent transparent transparent;
}
.topbar__profile-dropdown {
    display: none;
    position: absolute;
    width: 100%;
    padding: 16px;
    background: #ffffff;
    top: calc(100% + 8px);
    left: 0;
    box-shadow: 0 1px 16px rgba(0,0,0,0.2);
    border-radius: 4px;
    opacity: 0;
    transform: translateY(16px);
    transition: opacity 0.15s, transform 0.15s;
    z-index: 999;
}
.topbar__profile-dropdown-backdrop{
    display: none;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 998;
}
.topbar__profile-dropdown--open {
    display: block;
    opacity: 1;
    transform: translateY(0);
}
.topbar__profile-log-out-btn {
    width: 100%;
    cursor: pointer;
}