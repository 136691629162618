.autocomplete-container {
    position: relative;
}
.autocomplete-container > input {
    margin: 0;
    border-radius: 0.375rem;
}
.autocomplete-dropdown-container {
    position: absolute;
    border: 1px solid #dddddd;
    border-top: none;
    z-index: 1;
    top: 100%;
    left: 8px;
    border-radius: 0 0 0.375rem 0.375rem;
    box-shadow: 0 2px 8px -1px rgba(0,0,0,0.14);
    overflow: hidden;
}
.suggestion-item {
    background: #ffffff;   
}
.suggestion-item,
.suggestion-item--active {
    padding: 12px 16px;
    cursor: pointer
}
.suggestion-item:not(:last-child),
.suggestion-item--active:not(:last-child) {
    border-bottom: 1px solid #dddddd;
}
.suggestion-item--active {
    background: #fafafa;
}