.vso-login__container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    background: transparent;
    width: 100vw;
    height: 100%;
}

.vso-login__form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    position: relative;
    gap: 64px;
    box-shadow: 
        2px 0 14px -7px rgb(0 0 0 / 20%);
    animation: slideFromLeft 1.2s 1.2s;
    transform: translateX(-100%);
    animation-fill-mode: forwards;
}

@keyframes slideFromLeft {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0%);
    }
}

.vso-login__split-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: transparent;
}

.vso-login__title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding: 128px 80px 40px;
}

.vso-login__title-container > img {
    height: 64px;
    width: fit-content;
}

.vso-login__title-container > p {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
}

.vso-login__form-bottom-container {
    padding: 0 80px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.vso-login__form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
}

.vso-login__form-input-container {
    display: flex;
    flex-direction: column;
}

.vso-login__form-input-container > .form__input-group > label {
    font-size: 16px;
    margin-bottom: 0px;
    color: #333333;
}

.vso-login__form-input-name-container {
    display: flex;
    gap: 24px;
}

.vso-login__form-input-name-container > .form__input-group > label {
    font-size: 16px;
    margin-bottom: 0px;
    color: #333333;
}

.vso-login__form-button {
    display: flex;
    gap: 8px;
    align-self: flex-end;
    background-color: #3d59ec;
    padding: 12px;
    border-radius: 5px;
    border: none;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 0.5px;
    margin: 16px auto 0;
}

.vso-login__form-button:hover {
    cursor: pointer;
}

.vso-login__rights-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.vso-login__rights-container > p {
    font-size: 12px;
    /* color: #fff; */
    text-align: center;
}

.vso-login__rights-container > p > u {
    /* color: #fff; */
}