@import "../../../../scss/main.scss";

.buildingView__container {
    width: 100%;
    height: 100%;
    background-color: $bg__surface;
    display: flex;

    .buildingView__choose-floor {
        width: 60%;
        height: 100%;
        display: flex;
    }

    .buildingView__floor-plan-table-container {
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .buildingView__floor-plan {
            width: 100%;
            height: 50%;
        }

        .buildingView__unit-table {
            width: 100%;
            height: 50%;
        }
    }
}