@import "../../../../scss/main.scss";

.checkbox__container {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    margin-top: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @include typo(body, regular, 1);
    
    input {
        display: none;
    }

    input[type="checkbox"]:checked ~ .checkbox__checkmark{
        background-color: $accent__default;
        border: 1px solid $accent__default;
    }

    .checkbox__checkmark {
        position: relative;
        height: 18px;
        width: 18px;
        background-color: $bg__surface;
        border: 2px solid $accent__default;
        @include bd-radius(small);

        &::after {
            content: "";
            position: absolute;
            left: 6px;
            top: 2px;
            width: 2px;
            height: 8px;
            border: 2px solid $bg__surface;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

// label {
//     @include typo(body, regular, 1);
// }




