@import "../../../../scss/main.scss";

.filterDropdown__container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .filterDropdown__arrow-and-name {
        display: flex;
        gap: 12px;
        align-items: center;
        cursor: pointer;
        
        .filterDropdown__arrow {
            border: solid black;
            border-width: 0 1px 1px 0;
            display: inline-block;
            padding: 3px;
            transition: 0.15s;

            &.--right {
                transform: rotate(-45deg);
            }

            &.--down {
                transform: rotate(45deg);
              }
        }

        .filterDropdown__name {
            @include typo(small, bold, 1);
        }

        .filterDropdown__filter-tags {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 8px;
            border-radius: 100px;
            background: $accent__subtle;
            color: $accent__default;
            @include typo(caption, bold, 1);
        }
    }

    .filterDropdown__content {
        height: fit-content;
        background: $bg__subtle;
        margin: 0px -24px;

        &.--open {
            display: flex;
            align-items: center;
            padding: 8px 0px 8px 42px;
        }

        &.--closed {
            display: none;
        }
    }
}