.filterSingleSelect__container {
    display: flex;
    flex-direction: column;

    label {
        cursor: pointer;
    }

    input {
        margin-right: 8px;
    }
}