.projects__no-projects-container {
    text-align: center;
}
.projects__no-projects-button {
    width: fit-content;
    margin: 32px auto;
}
.market-tests__list-item {
    width: 100%;
    background: #ffffff;
    border-radius: 16px;
    padding: 24px 32px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    padding-left: calc(25% + 24px);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
    transition: 0.15s;
}
.market-tests__list-item:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}
.market-tests__item-image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 25%;
    background-position: center;
    background-size: cover;
    background-color: #ddd;
}
.market-tests__list-item h4 {
    margin-top: 0;
    color: #565656;
}
.market-tests__item-status {
    margin: 0 8px;
    padding: 4px 16px;
    display: inline-block;
    border-radius: 8px;
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
}
.market-tests__item-status.draft {
    background-color: moccasin;
}
.market-tests__item-status.pending {
    background-color: peachpuff;
}
.market-tests__item-status.active {
    background-color: lightskyblue;
}
.market-tests__item-status.complete {
    background-color: greenyellow;
}
.market-tests__item-status.canceled {
    background-color: orange;
}