.unitRow {
    background: #ffffff !important;
}

.unitRow__cogwheel-button {
    position: relative;
    cursor: pointer;
    display: flex;
    padding: 4px;
    border-radius: 4px;
    transition: 0.15s;
}

.unitRow__cogwheel-button:hover {
    background: #eeeeee;
}

.unitRow__cogwheel-button > img {
    height: 16px;
    width: 16px;
}

.unitRow__drag-handle {
    width: 20px;
    height: 40px;
    cursor: move;
}

.unitRow__view-plan {
    max-width: 80vw;
    max-height: 95vh;
    border-radius: 8px;
}

.unitRow__delete-unit {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    cursor: pointer;
}

.unitRow__delete-unit > img {
    width: 14px;
    height: 16px;
}