@import "../../../scss/main.scss";

.drawingCanvas__tooltip-container { 
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 8px;

    * {
        color: $fg__on-accent;
    }

    .drawingCanvas__tooltip-title {
        @include typo(body, bold, 1);
    }
}