@import "../../../../../../../scss/main.scss";

.floorItems__floors-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 8px;
    @include bd-radius(medium);
    transition: 0.15s;

    .floors-items-text-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;

        .floors-items-text {
            @include typo(small, bold, 2);
        }

        .floorItems__checkmark-placeholder{
            width: 20px;
            height: 20px;
            background-color: $accent__subtle;
            @include shadow(20);
            border-radius: 100px;
        }
    }
    .floorItems__edit-delete-buttons {
        display: flex;
        gap: 12px;
    }

    &.--active {
        background-color: $accent__default;
        @include shadow(60);

        .floors-items-text-icon {
            .floors-items-text {
                color: $bg__surface;
            }

            svg {
                path {
                    fill: $bg__surface;
                }
            }
        }
    }
}